import { Box, Button, Input, InputLabel, Typography } from "@mui/material";
import React from "react";
import swal from "sweetalert";
import api from "../../../api";
import { buttonColor } from "../buttons/buttonProperties";

export function ImportReviewButton() {
  const handleSuccess = (response) => {
    const title = "Import Review";

    console.log(response.status);

    if (response.status === 200) {
      // only case where returned response is areaAndTopic

      const areaAndTopic = response.data;

      let msg =
        "Topic: " +
        areaAndTopic.topic.title +
        "\n Area: " +
        areaAndTopic.area.name;
      if (areaAndTopic.message != null) {
        msg +=
          "\n\nReview uploaded successful, please be aware of the following:\n\n" +
          areaAndTopic.message;
      }

      console.log("status 200");
      swal(title, msg, "success", {
        closeOnClickOutside: false,
      }).then((ok) => {
        if (ok) {
          api.setAreaForUser(
            "IMPORTED",
            areaAndTopic.area,
            areaAndTopic.area.id
          );
          api.setTopicForUser(
            "IMPORTED",
            areaAndTopic.topic,
            areaAndTopic.topic.id
          );
          setTimeout(() => {
            window.location.reload();
          }, 400);
        }
      });
    } else if (response.status === 201 || response.status === 208) {
      // response is a text: 201:created, 208: already reported
      console.log("response.data: " + response.data);
      swal(title, response.data, "warning", { closeOnClickOutside: false });
    }
  };

  const handleError = (e) => {
    const title = "Import Review";
    console.log("e: " + e.response.status);
    swal(title, JSON.stringify(e.response.data), "error");
  };

  const onImportReview = (e) => {
    const file = e.target.files[0];

    api
      .uploadReview(file)
      .then((response) => {
        handleSuccess(response);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  return (
    <React.Fragment>
      <InputLabel htmlFor="uploadReview">
        <Input
          style={{ display: "none" }}
          id="uploadReview"
          name="uploadReview"
          type="file"
          onChange={onImportReview}
          disabled={false}
        />
        <Box maxWidth={200}>
          <Button
            component="span"
            variant="contained"
            disableRipple
            color={buttonColor.uploadRew}
            size="medium"
            disabled={false}
            fullWidth
          >
            {
              <Typography variant="body2" display="block" align="center">
                Upload Review
              </Typography>
            }
          </Button>
        </Box>
        {/* <Typography variant="caption" display="block" align="center">
            {msg}
          </Typography> */}
      </InputLabel>
    </React.Fragment>
  );
}
