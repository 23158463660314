
export class RegistryType {
    name: string;
    dataSourceType: string;
    urlPrefix: string;

    public constructor(name: string, dataSourceType: string, urlPrefix: string) {
        this.name = name;
        this.dataSourceType = dataSourceType;
        this.urlPrefix = urlPrefix;
    }
}

export class RegistryTypes {
    static CLINICAL_TRIALS_GOV = new RegistryType("Clinical Trials Gov", "CLINICAL_TRIALS_GOV", "https://clinicaltrials.gov/study/");
    static REGISTER_ISRCTN = new RegistryType("ISRCTN", "REGISTER_ISRCTN", "https://www.isrctn.com/");
    static PUBMED = new RegistryType("Pubmed", "PUBMED", "https://pubmed.ncbi.nlm.nih.gov/");
}