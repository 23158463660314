import { TextField } from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { getFieldName } from "../../../model/ViewProperties";
import FieldLink from "./FieldLink";
import { styled } from '@mui/material/styles';


const PREFIX = 'RecordField';

const classes = {
  text: `${PREFIX}-text`,
  helperText: `${PREFIX}-helperText`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.text}`]: {
    shrink: true,
    color: theme.palette.text.secondary,
    textAlign: "center",
  },
  [`& .${classes.helperText}`]: {
    color: "orangeRed",
  }
}))


function RecordField({
  disabled,
  value,
  field,
  index,
  onChange,
  reset,
  helperText = null,
  helperTextColor = null,
}) {

  const initValue = value !== null ? value : "";
  const [currentValue, setCurrentValue] = useState(initValue);
  const [error, setError] = useState(false);

  const handleChange = (e) => {
    let error = e.target.value !== initValue ? true : false;
    setError(error);
    setCurrentValue(e.target.value);
    onChange(index, error ? 1 : 0, field, e.target.value);
  };

  const getNumberOfRows = (field) => {
    return 0;
  };

  return (
    <StyledBox display="flex" flexDirection="row" justifyContent="flex-start">
      <FieldLink field={field} value={value} />
      <TextField
        disabled={disabled}
        id={field}
        label={<strong> {getFieldName(field)}</strong>}
        style={{ margin: 8 }}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder={initValue === "" ? "Not available" : initValue}
        InputProps={{
          className: classes.text,
        }}
        variant={disabled ? "filled" : "outlined"}
        multiline
        rowsmin={getNumberOfRows(field)}
        value={currentValue}
        onChange={(e) => handleChange(e)}
        error={error && !reset}
        helperText={helperText}
        FormHelperTextProps={{
          style: { color: helperTextColor },
        }}
        size="small"
      />
    </StyledBox>
  );
}

export default RecordField;
