import { WorkflowStates } from "../model/WorkflowStates";

export const ROUTES = {
  SOURCE_SELECTION: "source-selection/*",
  INCOMING_PAPERS: "incoming-papers/*",
  ELIGIBILITY_ASSESSMENT: "eligibility-assessment/*",
  PAPER_ANALYSIS: "paper-analysis/*",
  EXTRACTION: "extraction/*",
  RELEASE: "release/*",
  MISCELLANEOUS: "miscellaneous/*",
  PAPER_IMPORT: "paper-import",
  REFERENCE_LOOKUP: "reference-lookup",
  AUTO_PAPER_SELECTION_REVIEW: "auto-paper-selection-review",
  PAPER_SELECTION: "paper-selection",
  PAPER_SELECTION_REVIEW: "paper-selection-review",
  PAPER_UPLOAD: "paper-upload",
  NOT_FOUND_PAPERS: "not-found-papers",
  DESIGN_PRE_ANNOTATION: "design-pre-annotation",
  ELIGIBILITY_ASSESSMENT_MAPPING: "registry-mapping",
  MANUAL_ASSESSMENT: "manual-assessment",
  PRE_ANNOTATION: "pre-annotation",
  ANNOTATION: "annotation",
  REVIEW: "review",
  AUTO_EXTRACTION: "auto",
  EXTRACTION_VALIDATION_VARIABLES: "validation-variables",
  EXTRACTION_VALIDATION_DATA: "validation-data",
  AUTO_RELEASE_VALIDATION: "auto-release-validation",
  RELEASE_VALIDATION: "release-validation",
  RELEASE_VALIDATION_REVIEW: "release-validation-review",
  RELEASED: "released",
  TO_BE_DISCUSSED: "to-be-discussed",
  LONG_TERM_TO_BE_DISCUSSED: "long-term-to-be-discussed",
  ALL_PAPERS: "all-papers",
  ADMINISTRATION: "administration/*",
};

export const ROUTES_PROJECT_MANAGEMENT = {
  PROJECT_MANAGEMENT: "project-management/*",
  PROJECT_CREATION: "creation",
  PROJECT_STATUS: "status",
  RESPONSIBILITIES: "responsibilities/*",
  DATA_MANAGEMENT: "data-management/*",
  ICTRP_UPDATE: "ictrp-update",
};

// used in PapersTableVirtual#handleGoto

export const stateName2Route = {
  [WorkflowStates.IMPORTED
    .stateName]: `${ROUTES.SOURCE_SELECTION}${ROUTES.PAPER_IMPORT}`,
  [WorkflowStates.AUTOMATED_REFERENCE_LOOKUP
    .stateName]: `${ROUTES.SOURCE_SELECTION}${ROUTES.REFERENCE_LOOKUP}`,
  [WorkflowStates.AUTO_PAPER_SELECTION_REVIEW
    .stateName]: `${ROUTES.INCOMING_PAPERS}${ROUTES.AUTO_PAPER_SELECTION_REVIEW}`,
  [WorkflowStates.SELECTION_ASSESSMENT
    .stateName]: `${ROUTES.INCOMING_PAPERS}${ROUTES.PAPER_SELECTION}`,
  [WorkflowStates.SELECTION_ASSESSMENT_REVIEW
    .stateName]: `${ROUTES.INCOMING_PAPERS}${ROUTES.PAPER_SELECTION_REVIEW}`,
  [WorkflowStates.INCOMING
    .stateName]: `${ROUTES.INCOMING_PAPERS}${ROUTES.PAPER_UPLOAD}`,
  [WorkflowStates.INCOMING_NOT_FOUND
    .stateName]: `${ROUTES.INCOMING_PAPERS}${ROUTES.NOT_FOUND_PAPERS}`,
  [WorkflowStates.ELIG_ASSESS_AUTO_PREANNOTATION_DESIGN
    .stateName]: `${ROUTES.ELIGIBILITY_ASSESSMENT}${ROUTES.DESIGN_PRE_ANNOTATION}`,
  [WorkflowStates.ELIGIBILITY_ASSESSMENT_MAPPING
      .stateName]: `${ROUTES.ELIGIBILITY_ASSESSMENT}${ROUTES.ELIGIBILITY_ASSESSMENT_MAPPING}`,
  [WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT
    .stateName]: `${ROUTES.ELIGIBILITY_ASSESSMENT}${ROUTES.MANUAL_ASSESSMENT}`,
  [WorkflowStates.ANALYSIS_PREANNOTATION
    .stateName]: `${ROUTES.PAPER_ANALYSIS}${ROUTES.PRE_ANNOTATION}`,
  [WorkflowStates.ANALYSIS
    .stateName]: `${ROUTES.PAPER_ANALYSIS}${ROUTES.ANNOTATION}`,
  [WorkflowStates.ANALYSIS_REVIEW
    .stateName]: `${ROUTES.PAPER_ANALYSIS}${ROUTES.REVIEW}`,
  [WorkflowStates.AUTO_EXTRACTION
    .stateName]: `${ROUTES.EXTRACTION}${ROUTES.AUTO_EXTRACTION}`,
  [WorkflowStates.EXTRACTION_VALIDATION_VARIABLES
    .stateName]: `${ROUTES.EXTRACTION}${ROUTES.EXTRACTION_VALIDATION_VARIABLES}`,
  [WorkflowStates.EXTRACTION_VALIDATION_DATA
    .stateName]: `${ROUTES.EXTRACTION}${ROUTES.EXTRACTION_VALIDATION_DATA}`,
  // Released: `${ROUTES.EXTRACTION}${ROUTES.RELEASED}`,
  [WorkflowStates.TO_BE_DISCUSSED
    .stateName]: `${ROUTES.MISCELLANEOUS}${ROUTES.TO_BE_DISCUSSED}`,
  [WorkflowStates.LONG_TERM_TO_BE_DISCUSSED
    .stateName]: `${ROUTES.MISCELLANEOUS}${ROUTES.LONG_TERM_TO_BE_DISCUSSED}`,
};

// used for AreaResponsibility

export const state2Route = {
  [WorkflowStates.SELECTION_ASSESSMENT
    .state]: `${ROUTES.INCOMING_PAPERS}${ROUTES.PAPER_SELECTION}`,
  [WorkflowStates.SELECTION_ASSESSMENT_REVIEW
    .state]: `${ROUTES.INCOMING_PAPERS}${ROUTES.PAPER_SELECTION_REVIEW}`,
  [WorkflowStates.ANALYSIS
    .state]: `${ROUTES.PAPER_ANALYSIS}${ROUTES.ANNOTATION}`,
  [WorkflowStates.ANALYSIS_REVIEW
    .state]: `${ROUTES.PAPER_ANALYSIS}${ROUTES.REVIEW}`,
};
