/**
 * Writer of the annotation
 */
 export const AnnotationWriterType = {
    ANNOTATOR : {
      type: "ANNOTATOR",
      color : "#FFFF00"
    },
    REVIEWER : {
      type: "REVIEWER",
      color : "#00FF00"
    },
    AUTO_DESIGN : {
      type: "AUTO_DESIGN",
      color : "#00FFFF"
    },
    AUTO_ANNOTATION_GENERAL : {
      type: "AUTO_ANNOTATION_GENERAL",
      color : "#FFB300"
    }
  }
  
  export function byAnnotationWriterType(inType) {
    for (let type in AnnotationWriterType) {
      if (AnnotationWriterType[type].type === inType) {
        return AnnotationWriterType[type];
      }
    }
    return null;
  }