import {
  EditHighlightOperation,
  IHighlight,
  UserConfirmationStateHighlight,
} from "../../../libs/react-pdf-highlighter";
import { AnnotationType } from "../../../model/annotation/AnnotationType";
import { BaseAnnotation } from "../subcomponents/BaseAnnotation";
import { ContentAnnotation } from "../subcomponents/ContentAnnotation";
import { IcdOrRegistryAnnotation } from "../subcomponents/IcdOrRegistryAnnotation";

export function createAnnotationComponentByType(
  textColor: string,
  highlight: IHighlight,
  removeAnnotation: Function,
  disableAnnotation: Function,
  removeSameButThis: Function,
  acceptAnnotationByAnnotator: Function,
  duplicateAnnotation: (editHighlightOperation: EditHighlightOperation) => void
): JSX.Element {
  if (
    highlight.annotationType === AnnotationType.CONTENT.type ||
    highlight.annotationType === AnnotationType.EMPTY.type
  ) {
    return ContentAnnotation(
      textColor,
      highlight,
      removeAnnotation,
      acceptAnnotationByAnnotator,
      duplicateAnnotation
    );
  } else if (
    highlight.annotationType === AnnotationType.ICD.type ||
    highlight.annotationType === AnnotationType.REGISTRY.type
  ) {
    return IcdOrRegistryAnnotation(
      highlight as UserConfirmationStateHighlight,
      disableAnnotation,
      removeSameButThis,
      acceptAnnotationByAnnotator
    );
  }
  return BaseAnnotation(textColor, highlight);
}
