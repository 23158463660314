import React from "react";
import { useLocation } from "react-router-dom";
import api from "../../api";
import annotationApi from "../../api/annotationApi";
import { RecordActions } from "../subcomponents/actionComponents/RecordActions";
import TopActions from "../subcomponents/actionComponents/TopActions";
import { NoAreasFound } from "../subcomponents/NoAreasFound";
import AreaTopicSelect from "../subcomponents/selectors/AreaTopicSelectNew";
import RecordTableList from "../subcomponents/tables/RecordTableList";
import { fetchAreasAndTopics, fetchRecords, fetchTopics } from "./fetchData";
// import { fetchRecords } from "./fetchData";
import ViewFormLayout from "./layouts/ViewFormLayout";
import FinalValidationRelease from "./validationViews/FinalValidationRelease";
// import { totalValidated } from "./validationViews/VariableValidation";
import { Box, Input, InputLabel, Typography } from "@mui/material";
import { ValidatePaperAnnotationsButton } from "../subcomponents/buttons/ValidatePaperAnnotationsButton";

const FinalValidationReleaseView = ({ state }) => {
  const [areas, setAreas] = React.useState([]);
  const [topics, setTopics] = React.useState([]);
  const [currentAreaId, setCurrentAreaId] = React.useState();
  const [currentTopicId, setCurrentTopicId] = React.useState();
  const [recordList, setRecordList] = React.useState([]);
  const [currentRecord, setCurrentRecord] = React.useState(null);
  const [resetTableIndex, setResetTableIndex] = React.useState(false);
  const [extractedAnnotations, setExtractedAnnotations] = React.useState([]);
  // const [validationOk, setValidationOk] = React.useState(0);
  const validationOk = 0;
  const [done, setDone] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    fetchAreasAndTopics(
      state,
      setAreas,
      setCurrentAreaId,
      setTopics,
      setCurrentTopicId,
      location,
      setDone
    );

    return () => {
      setAreas([]);
      setTopics([]);
    };
  }, [state, location]);

  React.useEffect(() => {
    if (currentTopicId) {
      fetchRecords(
        state,
        currentTopicId,
        setRecordList,
        setCurrentRecord,
        setResetTableIndex,
        "fromTopic"
      );
    }

    return () => {
      setRecordList([]);
    };
  }, [state, currentTopicId]);

  React.useEffect(() => {
    if (currentRecord) {
      annotationApi
        .fetchContentAnnotationsForRecord(currentRecord.id)
        .then((data) => {
          if (data.extractedAnnotations !== null) {
            setExtractedAnnotations(data.extractedAnnotations);
          }
        });
    }

    return () => {};
  }, [currentRecord]);

  // React.useEffect(() => {
  //   if (extractedAnnotations?.length > 0) {
  //     setValidationOk(
  //       extractedAnnotations.length - totalValidated(extractedAnnotations)
  //     );
  //   }

  //   return () => {
  //     setValidationOk(-1);
  //   };
  // }, [extractedAnnotations]);

  const handleRefreshPage = React.useCallback(() => {
    window.location.reload();
  }, []);

  const fetchDataReady =
    currentAreaId && currentTopicId && areas.length > 0 && topics.length > 0;

  if (done && !currentAreaId) {
    return <NoAreasFound />;
  }

  if (!fetchDataReady) {
    return <ViewFormLayout />;
  }

  const handleCurrentRecordIdx = (recordId) => {
    const idx = recordList.findIndex(({ id }) => id === recordId);
    setCurrentRecord(recordList[idx]);
  };

  const handleChangeArea = (idArea) => {
    setCurrentRecord(null);
    setCurrentTopicId(null);
    setTopics([]);
    setCurrentAreaId(idArea);
    fetchTopics(state, idArea, setTopics, setCurrentTopicId, null);
    api.setAreaForUser(state, null, idArea);
  };

  const handleChangeTopic = (idTopic) => {
    setCurrentTopicId(idTopic);
    api.setTopicForUser(state, null, idTopic);
  };
  const saveRecord = (record) => {
    updateRecord(record);
    console.log(record.commentFinalValidation);
    api.saveRecord(record);
  };

  const updateRecord = (record) => {
    const idx = recordList.findIndex(({ id }) => id === record.id);
    let listCopy = [...recordList];
    listCopy[idx] = record;
    setRecordList(listCopy);
    setCurrentRecord(record);
  };

  function PaperAnnotationsValidateButton({ currentRecord, disabled }) {
    return (
      <InputLabel htmlFor="validatePaperAnnotatios">
        <Input
          style={{ display: "none" }}
          id="validatePaperAnnotatios"
          name="validatePaperAnnotatios"
          value="" // hack to trigger onChange, even when updating same file
          disabled={disabled}
        />
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          <ValidatePaperAnnotationsButton
            currentRecord={currentRecord}
            useWhiteOutclinedButton={false}
          />
          <Box>
            <Typography variant="caption" display="block" color="textSecondary">
              {"validation of uploaded paper or in UI annotated"}
            </Typography>
          </Box>
        </Box>
      </InputLabel>
    );
  }

  const Selector = (
    <AreaTopicSelect
      areas={areas}
      topics={topics}
      initialAreaId={currentAreaId}
      initialTopicId={currentTopicId}
      handleChangeArea={handleChangeArea}
      handleChangeTopic={handleChangeTopic}
      // responsible={responsible}
    />
  );

  return (
    <React.Fragment>
      <ViewFormLayout
        select={Selector}
        topActions={
          currentRecord && <TopActions handleRefreshPage={handleRefreshPage} />
        }
        table={
          recordList.length > 0 && (
            <RecordTableList
              recordList={recordList}
              handleCurrentRecordIdx={handleCurrentRecordIdx}
              resetIndex={resetTableIndex}
              //   disableActions={disableActions}
            />
          )
        }
        recordActions={
          currentRecord && (
            <RecordActions
              state={state}
              currentRecord={currentRecord}
              updateRecord={updateRecord}
              disableRecordActions={validationOk !== 0}
              // disableRecordActions={false}
              disableBackwards={currentRecord.workflowState !== state}
            />
          )
        }
        validatePaperAnnotationsButton={
          currentRecord && (
            <PaperAnnotationsValidateButton
              currentRecord={currentRecord}
              useWhiteOutclinedButton={false}
            />
          )
        }
        form={
          currentRecord &&
          extractedAnnotations && (
            <FinalValidationRelease
              state={state}
              currentRecord={currentRecord}
              extractedAnnotations={extractedAnnotations}
              saveRecord={saveRecord}
              // handleDisabled={handleDisabled}
              // key={refreshRecordList} //force to update component
              updateRecord={updateRecord}
            />
          )
        }
      />
    </React.Fragment>
  );
};

export default FinalValidationReleaseView;
