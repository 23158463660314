import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  PaperProps,
  Radio,
  Select,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { GroupNames } from "../../../model/annotation/GroupNames";
import { AnnotationBaseContentType } from "../../../model/annotation/AnnotationBaseContentType";

import { createAnnotationText } from "../../../utils/annotation/utils";
import Draggable from "react-draggable";
import {
  EditHighlightOperation,
  IHighlight,
} from "../../../libs/react-pdf-highlighter";

interface State {
  count: string;
  variable: string;
  groupId: string;
  isCustom: boolean;
  customText: string;
  // annoVariable: AnnoVariable;
}

interface Props {
  countChanged: (count: string) => void;
  groupIdChanged: (groupId: string) => void;
  isCustomChanged: (isCustom: boolean) => void;
  variableChanged: (variable: string) => void;
  customTextChanged: (customText: string) => void;
  initialCustomText: string;
}

class AnnotationChangeContent extends React.Component<Props, State> {
  state: State = {
    count: "0",
    groupId: "A",
    variable: "",
    isCustom: false,
    customText: this.props.initialCustomText,
  };

  handleOnChangeRadio = (event: any, isCustom: boolean) => {
    this.props.isCustomChanged(isCustom);
    this.props.variableChanged(event.target.value);
    this.setState({
      variable: event.target.value,
      isCustom: isCustom,
    });
  };

  handleCountInputEvent = (event: any) => {
    event.preventDefault();
    const newValue = event.target.value;
    if (newValue !== null) {
      const count: string = typeof newValue === "string" ? newValue : "0";
      this.props.countChanged(count);
      this.setState({ count: count });
    }
  };

  isDisabled(variable: string, type: string): boolean {
    return variable !== type;
  }

  getCountSelectForm(selectId: string, count: string, disabled: boolean) {
    return (
      <FormControl fullWidth>
        <Select
          labelId="`$selelectId`-label"
          id={selectId}
          value={count}
          disabled={disabled}
          onChange={this.handleCountInputEvent}
        >
          <MenuItem key={1} value={"1"}>
            1
          </MenuItem>
          <MenuItem key={1} value={"2"}>
            2
          </MenuItem>
          <MenuItem key={1} value={"3"}>
            3
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  private getIdGridField() {
    return (
      <Grid item xs={1}>
        <Typography>ID</Typography>
      </Grid>
    );
  }

  render() {
    const { count, variable, groupId, customText } = this.state;

    const GRID_WIDTH_TEXT_FIELD = 6;
    const GRID_WIDTH_COUNT_FIELD = 2;
    const GRID_WIDTH_GROUPID_FIELD = 3;
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
          <Radio
            checked={variable === AnnotationBaseContentType.DESIGN.type}
            onChange={(e) => this.handleOnChangeRadio(e, false)}
            value={AnnotationBaseContentType.DESIGN.type}
            size="small"
          />
          Design
        </Grid>
        <Grid item xs={GRID_WIDTH_GROUPID_FIELD}></Grid>
        {this.getIdGridField()}
        <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
          {this.getCountSelectForm(
            "select-design-count",
            count,
            variable !== AnnotationBaseContentType.DESIGN.type
          )}
        </Grid>
        <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
          <Radio
            checked={variable === AnnotationBaseContentType.SUBJECTS.type}
            onChange={(e) => this.handleOnChangeRadio(e, false)}
            value={AnnotationBaseContentType.SUBJECTS.type}
            size="small"
          />
          Subjects
        </Grid>
        <Grid item xs={GRID_WIDTH_GROUPID_FIELD}></Grid>
        {this.getIdGridField()}
        <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
          {this.getCountSelectForm(
            "select-subjects-count",
            count,
            variable !== AnnotationBaseContentType.SUBJECTS.type
          )}
        </Grid>
        <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
          <Radio
            checked={variable === AnnotationBaseContentType.CONDITION.type}
            onChange={(e) => this.handleOnChangeRadio(e, false)}
            value={AnnotationBaseContentType.CONDITION.type}
            size="small"
          />
          Condition
        </Grid>
        <Grid item xs={GRID_WIDTH_GROUPID_FIELD}></Grid>
        {this.getIdGridField()}
        <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
          {this.getCountSelectForm(
            "select-condition-count",
            count,
            variable !== AnnotationBaseContentType.CONDITION.type
          )}
        </Grid>
        <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
          <Radio
            checked={variable === AnnotationBaseContentType.GROUP_NAME.type}
            onChange={(e) => this.handleOnChangeRadio(e, false)}
            value={AnnotationBaseContentType.GROUP_NAME.type}
            size="small"
          />
          Group
        </Grid>
        <Grid item xs={GRID_WIDTH_GROUPID_FIELD}>
          <select
            value={groupId}
            disabled={variable !== AnnotationBaseContentType.GROUP_NAME.type}
            onChange={(e) => {
              this.setState({ groupId: e.target.value });
              this.props.groupIdChanged(e.target.value);
            }}
          >
            {GroupNames.map((value) => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        </Grid>
        {this.getIdGridField()}
        <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
          {this.getCountSelectForm(
            "select-group-name-count",
            count,
            variable !== AnnotationBaseContentType.GROUP_NAME.type
          )}
        </Grid>
        <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
          <Radio
            checked={variable === AnnotationBaseContentType.GROUP_N.type}
            onChange={(e) => this.handleOnChangeRadio(e, false)}
            value={AnnotationBaseContentType.GROUP_N.type}
            size="small"
          />
          N
        </Grid>
        <Grid item xs={GRID_WIDTH_GROUPID_FIELD}>
          <select
            value={groupId}
            disabled={variable !== AnnotationBaseContentType.GROUP_N.type}
            onChange={(e) => {
              this.setState({ groupId: e.target.value });
              this.props.groupIdChanged(e.target.value);
            }}
          >
            {GroupNames.map((value) => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        </Grid>
        {this.getIdGridField()}
        <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
          {this.getCountSelectForm(
            "select-group-n-count",
            count,
            variable !== AnnotationBaseContentType.GROUP_N.type
          )}
        </Grid>
        <Grid item xs={4}>
          <Radio
            checked={variable === AnnotationBaseContentType.CUSTOM.type}
            onChange={(e) => this.handleOnChangeRadio(e, true)}
            value={AnnotationBaseContentType.CUSTOM.type}
            size="small"
          />
          Custom
        </Grid>
        <Grid item xs={8}>
          <input
            type="text"
            value={customText}
            onChange={(e) => {
              this.setState({ customText: e.target.value });
              this.props.customTextChanged(e.target.value);
            }}
            disabled={variable !== AnnotationBaseContentType.CUSTOM.type}
          />
        </Grid>
      </Grid>
    );
  }
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface EditAnnotationProps {
  duplicateOrEdit: (editHighlightOperation: EditHighlightOperation) => void;
  highlightIn: IHighlight;
  duplicate: boolean;
}

export const EditBaseAnnotationButton: React.FC<EditAnnotationProps> = ({
  duplicateOrEdit,
  highlightIn,
  duplicate,
}: EditAnnotationProps) => {
  const [open, setOpen] = React.useState(false);
  const [isCustom, setCustom] = React.useState(false);
  const [customText, setCustomText] = React.useState("");
  const [count, setCount] = React.useState("");
  const [variable, setVariable] = React.useState(
    AnnotationBaseContentType.CUSTOM.type
  );
  const [groupId, setGroupId] = React.useState("A");

  const handleClose = (isCancelled: boolean) => {
    if (!isCancelled) {
      const annotationData: string[] = createAnnotationText(
        isCustom,
        variable,
        groupId,
        count,
        customText
      );
      const editHighlightOperation: EditHighlightOperation = {
        comment: {
          text: annotationData[0],
          count: count,
        },
        highlightBase: highlightIn,
        chapter: annotationData[1],
        duplicate: duplicate,
      };
      duplicateOrEdit(editHighlightOperation);
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button
        variant="outlined"
        disableRipple
        onClick={handleClickOpen}
        color="secondary"
        size="small"
      >
        <Typography variant="body2" align="center">
          {duplicate ? "Clone" : "Edit"}
        </Typography>
      </Button>
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent}>
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <Typography>
            <Box fontWeight="fontWeightMedium" display="inline">
              {duplicate ? "Clone" : "Edit"}
            </Box>
            &nbsp;selected annotation&nbsp;
            <Box fontWeight="fontWeightMedium" display="inline">
              {highlightIn.comment.text}
            </Box>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please name the variable{duplicate ? " of the duplicate" : ""}
          </DialogContentText>
          <AnnotationChangeContent
            countChanged={setCount}
            customTextChanged={setCustomText}
            groupIdChanged={setGroupId}
            isCustomChanged={setCustom}
            variableChanged={setVariable}
            initialCustomText={highlightIn.comment.text}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleClose(false)}
            color="primary"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleClose(true)}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditBaseAnnotationButton;
