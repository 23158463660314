import { Box } from "@mui/material";
import React from "react";
import api from "../../../api";
import { ActionButtonStyled } from "../buttons/ActionButton";
import { alertConfirm } from "../notification/notificationDialogs";
import { ButtonType } from "../buttons/Buttons";
import swal from "sweetalert";

function DeleteActions({ state, currentRecord, disableActions }) {
  const onDeleteRecord = () => {
    let msg = "Do you really want to delete the selected record?";
    let unique = new Set(currentRecord.listTopic).size;
    if (unique > 1) {
      msg +=
        " This record is in more than one topic. It will be removed from all topics!";
    }
    alertConfirm("Delete record", msg, onDeleteRecordConfirmed);
  };

  const onDeleteRecordConfirmed = () => {
    api
      .deleteRecord(currentRecord.id)
      .then((response) => {
        if (response.status === 202) {
          swal("Error when deleting record", response.data, "warning", {
            closeOnClickOutside: false,
          });
        } else {
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log("delete record error");
      });
  };

  const disableButton = disableActions || state !== currentRecord.workflowState;

  var deleteRecordBtn = (<ActionButtonStyled
      text="Delete"
      captionText="Delete record"
      disabled={disableButton}
      handleClick={onDeleteRecord}
      btnType={ButtonType.DANGER}
    />)

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Box>{deleteRecordBtn}</Box>
    </Box>
  );
}

export default DeleteActions;
