import { useRef, useEffect } from "react";
import { styled } from '@mui/material/styles';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { WorkflowStates } from "../model/WorkflowStates";
import { Action } from "../api";


const StyledSelect = styled(Select)(({ theme }) => ({
  border: 0,
  outline: 0,
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      border: 0
    },
    '&::before': {
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      position: 'absolute',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87)'
    }
  },
}));

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  position: 'absolute',
  left: '-14px',
}))

export function createSelect(
  options,
  selectedIndex,
  handleSelection,
  name,
  disabled,
  classes
) {
  let itemList = [];

  if (options.length > 0) {
    itemList = options.map((item, idx) => {
      return (
        <MenuItem key={item.toString()} value={idx}>
          {item}
        </MenuItem>
      );
    });
  } else {
    itemList.push(
      <MenuItem key={""} value={0}>
        No {name} items
      </MenuItem>
    );
  }

  const SelectComponent = (
    <FormControl className={classes.formControl}>
      <StyledInputLabel id="demo-controlled-open-select-label">{name}</StyledInputLabel>
      <StyledSelect
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        value={selectedIndex}
        onChange={handleSelection}
        disabled={disabled}
      >
        {itemList}
      </StyledSelect>
    </FormControl>
  );

  return SelectComponent;
}

export function createSelectArea(areas, selectedAreaIdx, handleArea, text) {
  let areaItems = [];

  if (areas.length > 0) {
    areaItems = areas.map((item, idx) => {
      return (
        <MenuItem key={item.id.toString()} value={idx}>
          {item.name}
        </MenuItem>
      );
    });
  } else {
    areaItems.push(
      <MenuItem key={"0"} value={0}>
        No area items
      </MenuItem>
    );
  }

  const SelectArea = (
    <FormControl>
      <StyledInputLabel id="demo-controlled-open-select-label">{text}</StyledInputLabel>
      <StyledSelect
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        value={selectedAreaIdx}
        onChange={handleArea}
        style={{ paddingLeft: 10 }}
      >
        {areaItems}
      </StyledSelect>
    </FormControl>
  );

  return SelectArea;
}

export function createSelectTopic(
  topics,
  selectedTopicIdx,
  handleTopic,
  classes
) {
  let areaItems = [];

  if (topics.length > 0) {
    areaItems = topics.map((item, idx) => {
      return (
        <MenuItem key={item.id.toString()} value={idx}>
          {item.title}
        </MenuItem>
      );
    });
  }

  const SelectArea = (
    <FormControl className={classes.formControl}>
      <StyledInputLabel id="demo-controlled-open-select-label">Topic</StyledInputLabel>
      <StyledSelect
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        value={selectedTopicIdx}
        onChange={handleTopic}
      >
        {areaItems}
      </StyledSelect>
    </FormControl>
  );

  return SelectArea;
}

export const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return isMounted;
};

export function getPublicationParent(paper) {
  let pp = "";
  if (paper.publicationParentName !== null) {
    pp = " " + paper.publicationParentName;
    if (pp.fullText.search("{") > 0) {
      pp = pp.substring(0, pp.search("{"));
    } else {
      pp = pp.fullText;
    }
  }
  return pp;
}

export function getPPPrefix(pp) {
  let prefix = "";
  if (!pp.dummy) {
    if (pp.fullText.search("{") > 0) {
      prefix = pp.fullText.substring(0, pp.fullText.search("{"));
    } else {
      prefix = pp.fullText;
    }
  } else {
    prefix = pp.fullText;
  }

  return prefix;
}

export function getPaperNameBasedOnPP(pp, paper) {
  let prefix = getPPPrefix(pp);
  let paperName = getPaperName(paper);

  return prefix + " > " + paperName;
}

export function getPaperName(paper) {
  let paperName = "";
  if (paper.referenceText !== null) {
    if (paper.authorsText !== null) {
      paperName = paperName + paper.authorsText.split(" ")[0];
    } else {
      paperName = paperName + "_tempAuthor";
    }
  }
  if (paper.year !== 0) {
    paperName = paperName + paper.year;
  }
  if (paper.pubmedId !== null) {
    paperName = paperName + "_PMID" + paper.pubmedId;
  }

  return paperName;
}

export function getPaperNameState(paper, state) {
  let paperName = "";
  if (paper.referenceText !== null) {
    paperName = paperName + paper.authorsText.split(" ")[0];
  }
  if (paper.year !== 0) {
    paperName = paperName + paper.year;
  }
  if (paper.pubmedId !== null) {
    paperName = paperName + "_PMID" + paper.pubmedId;
  }

  if (
    [
      WorkflowStates.IMPORTED.state,
      WorkflowStates.SELECTION_ASSESSMENT_REVIEW.state,
      WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.state,
      WorkflowStates.ANALYSIS_REVIEW.state,
    ].includes(state)
  ) {
    return getPublicationParent(paper) + " > " + paperName;
  }

  return paperName;
}

export const ConfirmationDialog = (open, handleConfirmation) => {
  const contentText = "Do you want to move?";

  return (
    <Dialog
      open={open}
      onClose={handleConfirmation(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Use Google's location service?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmation(true)} color="primary">
          OK
        </Button>
        <Button onClick={handleConfirmation(false)} color="primary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const sortRecords_old = (records, fn) => {
  if (!fn) fn = (r) => r;
  records.sort((r1, r2) => {
    if (fn(r1) < fn(r2)) {
      return -1;
    } else if (fn(r1) > fn(r2)) {
      return 1;
    } else return 0;
  });
};

export const sortRecords = (records, by, lan = undefined) => {
  records.sort((a, b) => {
    return by(a).localeCompare(by(b), lan);
  });
};

export const sortRecordsState = (records, fn, state) => {
  if (!fn) fn = (r) => r;
  records.sort((r1, r2) => {
    if (fn(r1, state) < fn(r2, state)) {
      return -1;
    } else if (fn(r1, state) > fn(r2, state)) {
      return 1;
    } else return 0;
  });
};

export const getNextState = (currentState, action) => {
  const states = Object.keys(WorkflowStates);
  const values = Object.values(WorkflowStates);

  let idx = states.indexOf(currentState);
  let step = action === Action.FORWARD ? 1 : -1;

  idx += step;
  if (idx < 0 || idx > states.length - 1) return null;

  if (values[idx].auto) {
    if (action === Action.FORWARD) {
      // idx += 1;
    } else {
      idx -= 1;
      if (currentState === WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.state) {
        idx -= 1;
      }
    }
  }

  return values[idx].stateName;
};

export const calculateRandomId = (length) => {
  var result = "";
  var availableCharaters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = availableCharaters.length;
  for (var i = 0; i < length; i++) {
    result += availableCharaters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  return result;
};

function jsonCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

const exportedUtils = {
  useIsMounted,
  calculateRandomId,
  ConfirmationDialog,
  sortRecords,
  sortRecordsState,
  getPaperName,
  getPaperNameState,
  getPPPrefix,
  getPaperNameBasedOnPP,
  getNextState,
  jsonCopy,
};

export default exportedUtils;

// const ConfirmDialog = ({ title, children, open, setOpen, onConfirm }) => {
//   return (
//     <Dialog
//       open={open}
//       onClose={() => setOpen(false)}
//       aria-labelledby="confirm-dialog"
//     >
//       <DialogTitle id="confirm-dialog">{title}</DialogTitle>
//       <DialogContent>{children}</DialogContent>
//       <DialogActions>
//         <Button
//           variant="contained"
//           onClick={() => setOpen(false)}
//           color="secondary"
//         >
//           No
//         </Button>
//         <Button
//           variant="contained"
//           onClick={() => {
//             setOpen(false);
//             onConfirm();
//           }}
//           color="default"
//         >
//           Yes
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };
