export const WorkflowStates = {
  IMPORTED: {
    state: "IMPORTED",
    stateName: "Imported",
    view: "PAPER_IMPORT",
    auto: false,
  },
  AUTOMATED_REFERENCE_LOOKUP: {
    state: "AUTOMATED_REFERENCE_LOOKUP",
    stateName: "Automated reference lookup",
    view: "PAPER_REFERENCE_LOOKUP",
    auto: true,
  },
  AUTO_PAPER_SELECTION_REVIEW: {
    state: "AUTO_PAPER_SELECTION_REVIEW",
    stateName: "Auto paper selection review",
    view: "INCOMING_PAPER_AUTO_PAPER_SELECTION_REVIEW",
    auto: false,
  },
  SELECTION_ASSESSMENT: {
    state: "SELECTION_ASSESSMENT",
    stateName: "Selection",
    view: "INCOMING_PAPER_SELECTION_ASSESSMENT",
    auto: false,
  },
  SELECTION_ASSESSMENT_REVIEW: {
    state: "SELECTION_ASSESSMENT_REVIEW",
    stateName: "Selection review",
    view: "INCOMING_PAPER_SELECTION_ASSESSMENT_REVIEW",
    auto: false,
  },
  INCOMING: {
    state: "INCOMING",
    stateName: "Incoming",
    view: "INCOMING_PAPER_DOWNLOAD",
    auto: false,
  },
  INCOMING_NOT_FOUND: {
    state: "INCOMING_NOT_FOUND",
    stateName: "Not found papers",
    view: "INCOMING_PAPER_NOT_FOUND",
    auto: false,
  },

  ELIG_ASSESS_AUTO_PREANNOTATION_DESIGN: {
    state: "ELIG_ASSESS_AUTO_PREANNOTATION_DESIGN",
    stateName: "Automatic design preannotation",
    view: "ELIGIBILITY_ASSESSMENT_PREANNO_DESIGN_VIEW",
    auto: true,
  },

  ELIGIBILITY_ASSESSMENT_MAPPING: {
    state: "ELIGIBILITY_ASSESSMENT_MAPPING",
    stateName: "Eligibility Assessment registry entry - paper mapping",
    view: "ELIGIBILITY_ASSESSMENT_MAPPING_VIEW",
    auto: false,
  },

  ELIGIBILITY_MANUAL_ASSESSMENT: {
    state: "ELIGIBILITY_MANUAL_ASSESSMENT",
    stateName: "Eligibility manual assessment",
    view: "ELIGIBILITY_MANUAL_ASSESSMENT_VIEW",
    auto: false,
  },

  ANALYSIS_PREANNOTATION: {
    state: "ANALYSIS_PREANNOTATION",
    stateName: "Analysis Pre-Annotation",
    view: "PAPER_ANALYSIS_PREANNOTATION_VIEW",
    auto: true,
  },
  ANALYSIS: {
    state: "ANALYSIS",
    stateName: "Analysis annotation",
    view: "PAPER_ANALYSIS_ANNOTATION_VIEW",
    auto: false,
  },
  ANALYSIS_REVIEW: {
    state: "ANALYSIS_REVIEW",
    stateName: "Analysis review",
    view: "PAPER_ANALYSIS_REVIEW_VIEW",
    auto: false,
  },
  AUTO_EXTRACTION: {
    state: "AUTO_EXTRACTION",
    stateName: "Extraction (Auto)",
    view: "AUTO_EXTRACTION_VIEW",
    auto: false,
  },
  EXTRACTION_VALIDATION_VARIABLES: {
    state: "EXTRACTION_VALIDATION_VARIABLES",
    stateName: "Variable Validation",
    view: "EXTRACTION_VALIDATION_VARIABLES_VIEW",
    auto: false,
  },
  EXTRACTION_VALIDATION_DATA: {
    state: "EXTRACTION_VALIDATION_DATA",
    stateName: "Data Validation",
    view: "EXTRACTION_VALIDATION_DATA_VIEW",
    auto: true,
  },

  AUTO_RELEASE_VALIDATION: {
    state: "AUTO_RELEASE_VALIDATION",
    stateName: "Release Validation (auto)",
    view: "AUTO_RELEASE_VALIDATION_VIEW",
    auto: false,
  },

  RELEASE_VALIDATION: {
    state: "RELEASE_VALIDATION",
    stateName: "Release Final Validation",
    view: "RELEASE_VALIDATION_VIEW",
    auto: false,
  },

  RELEASE_VALIDATION_REVIEW: {
    state: "RELEASE_VALIDATION_REVIEW",
    stateName: "Release Final Validation Review",
    view: "RELEASE_VALIDATION_REVIEW_VIEW",
    auto: false,
  },

  RELEASE_CANDIDATE: {
    state: "RELEASE_CANDIDATE",
    stateName: "Release candidate",
    view: "EXTRACTION_RELEASE_PAPER_RELEASE_VIEW",
    auto: false,
  },
  RELEASED: {
    state: "RELEASED",
    stateName: "Released",
    view: "EXTRACTION_RELEASE_RELEASED_PAPERS_VIEW",
    auto: false,
  },
  ARCHIVED_EXCLUDED: {
    state: "ARCHIVED_EXCLUDED",
    stateName: "Archived, excluded",
  },
  TO_BE_DISCUSSED: {
    state: "TO_BE_DISCUSSED",
    stateName: "To be discussed",
    view: "TO_BE_DISCUSSED_VIEW",
    auto: false,
  },
  LONG_TERM_TO_BE_DISCUSSED: {
    state: "LONG_TERM_TO_BE_DISCUSSED",
    stateName: "Long Term To be discussed",
    view: "LONG_TERM_TO_BE_DISCUSSED_VIEW",
    auto: false,
  },

  // test_fetch: {
  //   state: "TEST_FETCH",
  //   stateName: "Test fetch areas",
  //   view: "TEST_FETCH_AREAS_VIEW",
  // },
  // test_fetch_data: {
  //   state: "TEST_FETCH_DATA",
  //   stateName: "Test fetch data",
  //   view: "TEST_FETCH_VIEW",
  // },
};

export function getViewInfo(stage) {
  if (WorkflowStates[stage] != null) {
    return WorkflowStates[stage];
  } else {
    return { state: "TODO", stateName: "TODO", view: "TODO" };
  }
}
