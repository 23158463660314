/*
 * contains functions about the visibility of components in the annotation UI
 */

import { WorkflowStates } from "../../WorkflowStates";

export function isAddingAnnotationsAllowed(workflowState: string): boolean {
  if (
    workflowState === WorkflowStates.ANALYSIS.state ||
    workflowState === WorkflowStates.ANALYSIS_REVIEW.state ||
    workflowState === WorkflowStates.TO_BE_DISCUSSED.state
  ) {
    return true;
  }
  return false;
}
