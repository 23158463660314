import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import { UserConfirmationState } from "../../../../model/annotation/UserConfirmationState";
import {
  getConfStateByWorkflowState,
  setConfStateByWorkflowState,
} from "../../../../utils/validation/confirmationByStateUtils";
import { PublicationReferenceComponent } from "./PublicationReferenceComponent";

export const PublicationReferences = ({
  publicationData,
  savePublicationReferences,
  currentWorkflowState,
}) => {
  // const onSaveItem = (content) => {
  //   // TODO: idx = -1 treatment

  //   let userConf = getConfStateByWorkflowState(
  //     publicationData.publicationReferences.userConfirmationByState,
  //     currentWorkflowState
  //   );

  //   console.log(userConf);
  //   console.log(content);

  //   setConfStateByWorkflowState(
  //     publicationData.publicationReferences.userConfirmationByState,
  //     currentWorkflowState,
  //     UserConfirmationState.CHANGED.name
  //   );

  //   // savePublicationReferences(groups);
  // };

  const handleChangeCheckBox = (e) => {
    let check;

    if (e.target.checked) {
      check = UserConfirmationState.ACCEPTED.name;
    } else {
      check = UserConfirmationState.DEFAULT.name;
    }

    setConfStateByWorkflowState(
      publicationData.publicationReferences.userConfirmationByState,
      currentWorkflowState,
      check
    );

    savePublicationReferences(publicationData.publicationReferences);
    // onValidate(userConfirmationByState, groupName);
  };

  if (!publicationData?.publicationReferences) return null;

  // console.log(publicationData.publicationReferences);

  return (
    <div>
      <Box display="flex" flexDirection="row">
        <Typography variant="h5">12. Publication references</Typography>
      </Box>
      <Box>
        <Box
          ml={30}
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          alignItems="center"
        >
          <Box>
            <Typography align="center">Validate</Typography>
          </Box>

          <Box>
            <Checkbox
              checked={
                getConfStateByWorkflowState(
                  publicationData.publicationReferences.userConfirmationByState,
                  currentWorkflowState
                ) === UserConfirmationState.ACCEPTED.name
              }
              onChange={handleChangeCheckBox}
              // disabled={}
            />
          </Box>
        </Box>

        <PublicationReferenceComponent
          content={publicationData.publicationReferences.pmid}
          name={"pmid"}
        />
        {publicationData.publicationReferences.doiCochraneReview &&
          publicationData.publicationReferences.doiCochraneReview.map((doi) => {
            return (
              <div key={doi}>
                <PublicationReferenceComponent content={doi} name={"doi"} />
              </div>
            );
          })}
        <PublicationReferenceComponent
          content={publicationData.publicationReferences.freePmcId}
          name={"freePmcId"}
        />

        {publicationData.publicationReferences.titleCochraneReview &&
          publicationData.publicationReferences.titleCochraneReview.map(
            (title) => {
              return (
                <div key={title}>
                  <PublicationReferenceComponent
                    content={title}
                    name={"title"}
                  />
                </div>
              );
            }
          )}
      </Box>
    </div>
  );
};

// const PublicationReference = ({ entry, currentWorkflowState }) => {
//   const { userConfirmationByState, ...fields } = entry;
//   return (
//     <div>
//       {Object.keys(fields).map((k) => {
//         return <div key={k}>{`${k}: ${fields[k]}`}</div>;
//       })}
//       <Box>{`userConfirmationByState: ${getConfStateByWorkflowState(
//         userConfirmationByState,
//         currentWorkflowState
//       )}`}</Box>
//     </div>
//   );
// };
