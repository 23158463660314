/*
 * Utility to access the API better
 */

import {
  createIcdOrRegistryAnnotation,
  createNewAnnotation,
  fetchContentAnnotationsForRecord,
  fetchIcdAnnotationsForRecord,
  fetchRegistryAnnotationsForRecord,
  updateAnnotation,
  updateIcdOrRegistryAnnotation,
  updateIcdOrRegistryAnnotationList,
} from "../../api/annotationApi";
import {
  IHighlight,
  IcdHighlight,
  RegistryHighlight,
  UserConfirmationStateHighlight,
  T_PDFJS_Viewer,
  NewHighlight,
} from "../../libs/react-pdf-highlighter";
import {
  Annotation,
  IcdAnnotation,
  RegistryEntryAnnotation,
} from "../../model/annotation/Annotation";
import { AnnotationType } from "../../model/annotation/AnnotationType";
import { AnnotationWriterType } from "../../model/annotation/AnnotationWriterType";
import { UserConfirmationState } from "../../model/annotation/UserConfirmationState";
import {
  convertIcdAnnotationsToPdfHighlightings,
  convertRegistryAnnotationsToPdfHighlightings,
  convertExractedRsPaperAnnotationsToPdfHighlightings,
  convertAnnotatedRsPaperAnnotationsToPdfHighlightings,
  convertPdfHighlightingToRsAnnotation,
} from "./annotationApiConverter";

/**
 * @param selectExtractedAnnotations true if the extractedAnnotations should be loaded
 * @returns a list of the loaded annotations
 */
export async function loadAllAnnotations(
  publicationId: string,
  pdfViewer: T_PDFJS_Viewer | null,
  selectExtractedAnnotations: boolean
) {
  const allAnnotations: Array<IHighlight> = new Array<IHighlight>();
  if (selectExtractedAnnotations) {
    await fetchRecords(
      publicationId,
      pdfViewer,
      fetchContentAnnotationsForRecord,
      convertExractedRsPaperAnnotationsToPdfHighlightings,
      allAnnotations
    );
  } else {
    await fetchRecords(
      publicationId,
      pdfViewer,
      fetchContentAnnotationsForRecord,
      convertAnnotatedRsPaperAnnotationsToPdfHighlightings,
      allAnnotations
    );
  }
  await fetchRecords(
    publicationId,
    pdfViewer,
    fetchRegistryAnnotationsForRecord,
    convertRegistryAnnotationsToPdfHighlightings,
    allAnnotations
  );
  await fetchRecords(
    publicationId,
    pdfViewer,
    fetchIcdAnnotationsForRecord,
    convertIcdAnnotationsToPdfHighlightings,
    allAnnotations
  );

  return allAnnotations;
}

export async function createAnnotation(
  highlight: NewHighlight,
  paperId: string,
  annotationWriterType: any,
  index: number
) {
  const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
    highlight,
    index,
    annotationWriterType
  );
  if (highlight.annotationType === AnnotationType.ICD.type) {
    const icdHighlight: IcdHighlight = highlight as IcdHighlight;
    const icdAnnotaion: IcdAnnotation = {
      ...icdHighlight,
      annotation: rsAnnotation,
      userConfirmationState: UserConfirmationState.DEFAULT.name,
    };
    await createIcdOrRegistryAnnotation(
      icdAnnotaion,
      paperId,
      AnnotationType.ICD.url
    );
    return icdHighlight;
  } else if (highlight.annotationType === AnnotationType.REGISTRY.type) {
    const registryHighlight: RegistryHighlight = highlight as RegistryHighlight;
    const registryAnnotation: RegistryEntryAnnotation = {
      ...registryHighlight,
      annotation: rsAnnotation,
      userConfirmationState: UserConfirmationState.DEFAULT.name,
    };
    await createIcdOrRegistryAnnotation(
      registryAnnotation,
      paperId,
      AnnotationType.REISTRY.url
    );
    return registryHighlight;
  } else if (highlight.annotationType === AnnotationType.EMPTY.type) {
    await createNewAnnotation(rsAnnotation, paperId, AnnotationType.EMPTY.url);
    return highlight;
  } else {
    await createNewAnnotation(
      rsAnnotation,
      paperId,
      AnnotationType.CONTENT.url
    );
    return highlight;
  }
}

export async function acceptAnnotation(highlight: IHighlight, paperId: string) {
  highlight.annotationWriterType = AnnotationWriterType.ANNOTATOR;
  const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
    highlight,
    highlight.index,
    highlight.annotationWriterType
  );
  if (highlight.annotationType === AnnotationType.ICD.type) {
    const icdHighlight: IcdHighlight = highlight as IcdHighlight;
    icdHighlight.userConfirmationState = UserConfirmationState.ACCEPTED.name;
    const icdAnnotaion: IcdAnnotation = {
      ...icdHighlight,
      annotation: rsAnnotation,
      userConfirmationState: UserConfirmationState.ACCEPTED.name,
    };
    await updateIcdOrRegistryAnnotation(
      icdAnnotaion,
      paperId,
      AnnotationType.ICD.url
    );
  } else if (highlight.annotationType === AnnotationType.REGISTRY.type) {
    const registryHighlight: RegistryHighlight = highlight as RegistryHighlight;
    registryHighlight.userConfirmationState =
      UserConfirmationState.ACCEPTED.name;
    const registryAnnotation: RegistryEntryAnnotation = {
      ...registryHighlight,
      annotation: rsAnnotation,
      userConfirmationState: UserConfirmationState.ACCEPTED.name,
    };
    await updateIcdOrRegistryAnnotation(
      registryAnnotation,
      paperId,
      AnnotationType.REGISTRY.url
    );
  } else if (highlight.annotationType === AnnotationType.EMPTY.type) {
    await updateAnnotation(rsAnnotation, paperId, AnnotationType.EMPTY.url);
  } else {
    await updateAnnotation(rsAnnotation, paperId, AnnotationType.CONTENT.url);
  }
}

export async function removeSameAnnotationExceptGiven(
  givenHighlight: IHighlight,
  highlights: Array<IHighlight>,
  paperId: string
) {
  const icdListToUpdate: Array<IcdAnnotation> = new Array<IcdAnnotation>();
  const registryListToUpdate: Array<RegistryEntryAnnotation> =
    new Array<RegistryEntryAnnotation>();
  highlights.forEach((highlight) => {
    if (highlight.annotationType === AnnotationType.ICD.type) {
      const icdHighlight: IcdHighlight = highlight as IcdHighlight;
      if (
        givenHighlight.annotationType === highlight.annotationType &&
        givenHighlight.randomId !== highlight.randomId &&
        givenHighlight.content.text === highlight.content.text &&
        (givenHighlight as IcdHighlight).icdAnnotationType ===
          icdHighlight.icdAnnotationType
      ) {
        icdHighlight.userConfirmationState = UserConfirmationState.DELETED.name;
      }

      const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
        highlight,
        highlight.index,
        highlight.annotationWriterType
      );
      const icdAnnotation: IcdAnnotation = {
        ...icdHighlight,
        annotation: rsAnnotation,
        userConfirmationState: icdHighlight.userConfirmationState,
      };
      icdListToUpdate.push(icdAnnotation);
    } else if (highlight.annotationType === AnnotationType.REGISTRY.type) {
      const registryHighlight: RegistryHighlight =
        highlight as RegistryHighlight;
      if (
        givenHighlight.annotationType === highlight.annotationType &&
        givenHighlight.randomId !== highlight.randomId &&
        givenHighlight.content.text === highlight.content.text
      ) {
        registryHighlight.userConfirmationState =
          UserConfirmationState.DELETED.name;
      }

      const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
        highlight,
        highlight.index,
        highlight.annotationWriterType
      );
      const registryAnnotation: RegistryEntryAnnotation = {
        ...registryHighlight,
        annotation: rsAnnotation,
        userConfirmationState: registryHighlight.userConfirmationState,
      };

      registryListToUpdate.push(registryAnnotation);
    }
  });
  if (icdListToUpdate.length > 0) {
    updateIcdOrRegistryAnnotationList(
      icdListToUpdate,
      paperId,
      AnnotationType.ICD.url
    );
  }
  if (registryListToUpdate.length > 0) {
    updateIcdOrRegistryAnnotationList(
      registryListToUpdate,
      paperId,
      AnnotationType.REGISTRY.url
    );
  }
}

export async function restoreDisabledAnnotations(
  highlights: Array<IHighlight>,
  paperId: string
) {
  const icdListToUpdate: Array<IcdAnnotation> = new Array<IcdAnnotation>();
  const registryListToUpdate: Array<RegistryEntryAnnotation> =
    new Array<RegistryEntryAnnotation>();
  highlights.forEach((highlight) => {
    if (highlight.annotationType === AnnotationType.ICD.type) {
      const icdHighlight: IcdHighlight = highlight as IcdHighlight;
      icdHighlight.userConfirmationState = UserConfirmationState.DEFAULT.name;
      const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
        highlight,
        highlight.index,
        highlight.annotationWriterType
      );
      const icdAnnotation: IcdAnnotation = {
        ...icdHighlight,
        annotation: rsAnnotation,
        userConfirmationState: UserConfirmationState.DEFAULT.name,
      };
      icdListToUpdate.push(icdAnnotation);
    } else if (highlight.annotationType === AnnotationType.REGISTRY.type) {
      const registryHighlight: RegistryHighlight =
        highlight as RegistryHighlight;
      registryHighlight.userConfirmationState =
        UserConfirmationState.DEFAULT.name;
      const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
        highlight,
        highlight.index,
        highlight.annotationWriterType
      );
      const registryAnnotation: RegistryEntryAnnotation = {
        ...registryHighlight,
        annotation: rsAnnotation,
        userConfirmationState: UserConfirmationState.DEFAULT.name,
      };

      registryListToUpdate.push(registryAnnotation);
    }
  });

  if (icdListToUpdate.length > 0) {
    updateIcdOrRegistryAnnotationList(
      icdListToUpdate,
      paperId,
      AnnotationType.ICD.url
    );
  }
  if (registryListToUpdate.length > 0) {
    updateIcdOrRegistryAnnotationList(
      registryListToUpdate,
      paperId,
      AnnotationType.REGISTRY.url
    );
  }
}

export async function markIcd10orRegistryAnnotationAsDeleted(
  highlight: UserConfirmationStateHighlight,
  paperId: string
) {
  highlight.userConfirmationState = UserConfirmationState.DELETED.name;
  const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
    highlight,
    highlight.index,
    highlight.annotationWriterType
  );
  if (highlight.annotationType === AnnotationType.ICD.type) {
    const icdHighlight: IcdHighlight = highlight as IcdHighlight;
    const icdAnnotaion: IcdAnnotation = {
      ...icdHighlight,
      annotation: rsAnnotation,
      userConfirmationState: UserConfirmationState.DELETED.name,
    };
    await updateIcdOrRegistryAnnotation(
      icdAnnotaion,
      paperId,
      AnnotationType.ICD.url
    );
  } else if (highlight.annotationType === AnnotationType.REGISTRY.type) {
    const registryHighlight: RegistryHighlight = highlight as RegistryHighlight;
    const registryAnnotation: RegistryEntryAnnotation = {
      ...registryHighlight,
      annotation: rsAnnotation,
      userConfirmationState: UserConfirmationState.DELETED.name,
    };
    await updateIcdOrRegistryAnnotation(
      registryAnnotation,
      paperId,
      AnnotationType.REGISTRY.url
    );
  }
}

async function fetchRecords(
  publicationId: string,
  pdfViewer: T_PDFJS_Viewer | null,
  fetchApiCall: Function,
  convertCall: Function,
  resultData: Array<IHighlight>
) {
  await fetchApiCall(publicationId).then((data: any) => {
    if (data !== null && data !== "" && pdfViewer !== null) {
      const highlights: Array<IHighlight> = convertCall(data, pdfViewer);
      highlights.forEach((highlight) => resultData.push(highlight));
    }
  });
}
