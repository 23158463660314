import {
    RegistryType,
    RegistryTypes
} from "../../model/registry/RegistryTypes";

const getRegistryType = (dataSourceType: string): RegistryType | null => {
    if (dataSourceType === RegistryTypes.CLINICAL_TRIALS_GOV.dataSourceType) {
        return RegistryTypes.CLINICAL_TRIALS_GOV;
    } else if (dataSourceType === RegistryTypes.REGISTER_ISRCTN.dataSourceType) {
        return RegistryTypes.REGISTER_ISRCTN;
    } else if (dataSourceType === RegistryTypes.PUBMED.dataSourceType) {
        return RegistryTypes.PUBMED;
    }
    return null;
}

export const createLinkToRegistry = (dataSourceType: string, id: string): string | null => {
    const registryType: RegistryType | null = getRegistryType(dataSourceType);
    if (registryType !== null) {
        return registryType.urlPrefix + id;
    }
    return null;
}

export const createRegistryName = (dataSourceType: string): string => {
    const registryType: RegistryType | null = getRegistryType(dataSourceType);
    if (registryType !== null) {
        return registryType.name;
    }
    return dataSourceType;
}
