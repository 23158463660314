import utils from "../../../utils/utils";
import { WorkflowStates } from "../../../model/WorkflowStates";

export const header = [
  {
    width: 400,
    label: "Paper\u00A0name",
    dataKey: "paperName",
  },
  {
    width: 280,
    label: "State",
    dataKey: "stateName",
  },

  {
    width: 330,
    label: "Area",
    dataKey: "area",
  },
  {
    width: 700,
    label: "Topic",
    dataKey: "topic",
  },

  {
    width: 600,
    label: "Annotator",
    dataKey: "annotator",
  },
  {
    width: 600,
    label: "Reviewer",
    dataKey: "reviewer",
  },

  {
    width: 120,
    label: "Goto",
    dataKey: "goto",
  },

  {
    width: 200,
    label: "Move",
    dataKey: "move",
  },
];

export function createRow(record, i) {
  const duplicatedTopic = record.topicId.split("_SEP_").length > 1;
  return {
    id: i,
    paperName: utils.getPaperName(record),
    stateName: WorkflowStates[record.state].stateName,
    annotator: record.annotatorComment,
    reviewer: record.reviewerComment,
    idPaper: record.id,
    topic: { topic: record.topic, duplicatedTopic: duplicatedTopic },
    area: record.area,
    goto: {
      areaId: record.areaId.split("_SEP_")[0],
      topicId: record.topicId.split("_SEP_")[0],
      stateName: WorkflowStates[record.state].stateName,
    },
    move: { stateName: WorkflowStates[record.state].stateName, id: record.id },
  };
}
