import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import utils from "../../../../utils/utils";
import { buttonColor } from "../../../subcomponents/buttons/buttonProperties";
import ValidationWarningBox from "./ValidationWarningBox";

export const PublicationContentComponent = ({
  content,
  onSaveItem,
  contentType = "",
  disableTextField = false,
  handleDisableCheckbox,
  textFieldWidth = 400,
}) => {
  const [editedContent, setEditedContent] = React.useState();
  const [lastContent, setLastContent] = React.useState();
  // const [disableTextField, setDisableTextField] = React.useState(false);

  React.useEffect(() => {
    if (content !== null) {
      setEditedContent(utils.jsonCopy(content));
      setLastContent(utils.jsonCopy(content));
    }
    return () => {
      setEditedContent();
      setLastContent();
    };
  }, [content]);

  const handleSaveClick = () => {
    let newEditedContent = editedContent;
    setEditedContent((editedContent) => {
      return { ...editedContent, ...newEditedContent };
    });

    setLastContent((lastContent) => {
      return { ...lastContent, ...newEditedContent };
    });

    onSaveItem(editedContent, contentType);
  };

  const handleChange = (e) => {
    let newAcro = editedContent;
    newAcro.content = e.target.value;
    setEditedContent((editedContent) => {
      return { ...editedContent, ...newAcro };
    });
    handleDisableCheckbox(newAcro.content !== newAcro.contentOriginal);
  };

  if (!editedContent) return null;

  const textFieldError =
    editedContent.content !== lastContent.content &&
    editedContent.content !== editedContent.contentOriginal;

  // console.log(editedContent.content);

  return (
    // <ListItem>
    <Box display="flex" flexDirection="row" alignItems="center">
      {validationWarningBox(
        content.variableName,
        content.validationWarnings,
        2
      )}
      {content.variableName && (
        <Box>
          <Typography
            style={{
              marginRight: 20,
              minWidth: 100,
            }}
          >
            {content.variableName}
          </Typography>
        </Box>
      )}
      <Box
        flexGrow={1}
        ml={content.variableName ? 2 : 0}
        width={textFieldWidth}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <TextField
          id="annotation"
          variant="outlined"
          size="small"
          fullWidth
          multiline
          margin="dense"
          value={editedContent.content}
          onChange={handleChange}
          error={textFieldError}
          disabled={disableTextField}
        />
      </Box>
      <Box ml={2}>
        <Button
          variant="contained"
          disableRipple
          onClick={handleSaveClick}
          color={buttonColor.save}
          disabled={editedContent.content === lastContent.content}
          size="small"
        >
          <Typography variant="body2" align="center">
            Save
          </Typography>
        </Button>
      </Box>
    </Box>
    // </ListItem>
  );
};

const validationWarningBox = (chapter, warnings, accordionMarginLeft) => {
  return (
    <ValidationWarningBox
      chapter={chapter}
      warnings={warnings}
      accordionMarginLeft={accordionMarginLeft}
    />
  );
};
