export const UserConfirmationState: any = {
  DEFAULT: {
    name: "DEFAULT",
  },
  ACCEPTED: {
    name: "ACCEPTED",
  },
  CHANGED: {
    name: "CHANGED",
  },
  DELETED: {
    name: "DELETED",
  },
};

export function byAnnotationType(inType: string) {
  for (let type in UserConfirmationState) {
    if (UserConfirmationState[type].type === inType) {
      return UserConfirmationState[type];
    }
  }
  return null;
}
