import swal from "sweetalert";

export function alertConfirm(title, text, confirmedAction, param) {
  swal({
    title: title,
    text: text,
    icon: "warning",
    buttons: true,
    dangerMode: false,
    closeOnClickOutside: false,
  }).then((value) => {
    if (value && confirmedAction) {
      confirmedAction(param);
    }
  });
}

export function alert(title, text) {
  swal({
    title: title,
    text: text,
    icon: "warning",
    buttons: "OK",
    dangerMode: false,
    closeOnClickOutside: false,
  });
}

export function alertSuccess(title, text) {
  swal({
    title: title,
    text: text,
    icon: "success",
    buttons: "OK",
    dangerMode: false,
    closeOnClickOutside: false,
  });
}

export function alertInfo(title, text) {
  swal({
    title: title,
    text: text,
    icon: "info",
    buttons: "OK",
    dangerMode: false,
    closeOnClickOutside: false,
  });
}

export function alertSave(title, text, saveAction, param) {
  const btns = {
    buttons: {
      cancel: "Ignore",
      catch: {
        text: "Save",
        value: "save",
      },
      // defeat: true,
    },
  };

  swal({
    title: title,
    text: text,
    icon: "warning",
    buttons: btns,
    dangerMode: false,
    closeOnClickOutside: false,
  }).then((value) => {
    if (value === "save") {
      saveAction(param);
    }
  });
}

const exportedAlertFunctions = { alertConfirm };
export default exportedAlertFunctions;

// function PaperComponent(props) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
//   paper: {
//     width: "80%",
//     maxHeight: 435,
//   },
// }));

// export default function NotificationDialog({ openDialog, title, msg }) {
//   const [open, setOpen] = React.useState(openDialog);

//   // const handleClickOpen = () => {
//   //   setOpen(true);
//   // };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Dialog
//         disableBackdropClick
//         disableEscapeKeyDown
//         maxWidth="xs"
//         open={open}
//         onClose={handleClose}
//         // aria-labelledby="alert-dialog-title"
//         // aria-describedby="alert-dialog-description"
//         aria-labelledby="draggable-dialog-title"
//         PaperComponent={PaperComponent}
//       >
//         <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
//           {title}
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             {msg}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// }
