import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import publicationDataApi from "../../../api/publicationDataApi";
import { ActionButton } from "../../subcomponents/buttons/ActionButton";
import { AnnotationUiOpenButton } from "../../subcomponents/buttons/AnnotationUiOpenButton";
import CollapsablePdfViewer from "../../subcomponents/pdf/CollapsablePdfViewer";
import RecordField from "../../subcomponents/record/RecordField";
import { CollapsableField } from "./subcomponents/CollapsableField";
import { GroupsComponent } from "./subcomponents/GroupsComponent";
import IcdListComponent from "./subcomponents/IcdListComponent";
import { OutcomeSectionsComponent } from "./subcomponents/OutcomeSectionsComponent";
import { PublicationReferences } from "./subcomponents/ReferenceComponents";
import { RegistryEntriesComponent } from "./subcomponents/RegistryEntriesComponent";
import { Variables } from "./subcomponents/VariableComponents";

function FinalValidationRelease({
  state,
  currentRecord,
  extractedAnnotations,
  saveRecord,
  updateRecord,
}) {
  const [publicationData, setPublicationData] = React.useState();
  const keys = React.useRef();

  React.useEffect(() => {
    if (currentRecord !== null) {
      publicationDataApi
        .getPublicationData(currentRecord.id)
        .then((response) => {
          keys.current = Object.keys(response.data);
          setPublicationData(response.data);
        });
      return () => {
        setPublicationData();
      };
    }
  }, [currentRecord]);

  const saveVariable = (data) => {
    publicationDataApi.updateVariableContent(
      data.variableName,
      currentRecord.id,
      data
    );
    updatePublicationDataFromVariable(data);
  };

  const updatePublicationDataFromVariable = (data) => {
    let newPublicationData = publicationData;
    newPublicationData[data.variableName] = data;

    setPublicationData((publicationData) => {
      return { ...publicationData, ...newPublicationData };
    });
  };

  const saveGroups = (data) => {
    publicationDataApi.updateGroupContent(currentRecord.id, data);

    updatePublicationDataFromGroups(data);
  };

  const updatePublicationDataFromGroups = (data) => {
    let newPublicationData = publicationData;
    newPublicationData.groups = data;

    setPublicationData((publicationData) => {
      return { ...publicationData, ...newPublicationData };
    });
  };

  const savePublicationReferences = (data) => {
    publicationDataApi.updateReferencesContent(currentRecord.id, data);
    updatePublicationData(data, "publicationReferences");
    // console.log(data);
  };

  const saveRegistries = (newRegistries) => {
    publicationDataApi.updateRegistriesContent(currentRecord.id, newRegistries);
    updatePublicationData(newRegistries, "publicationRegistryEntries");
    // console.log(newRegistries);
  };

  const updateIcdContent = (newIcdEntries, type) => {
    if (type === "ICD10") {
      publicationDataApi.updateIcd10Values(currentRecord.id, newIcdEntries);
      updatePublicationData(newIcdEntries, "icd10Values");
    } else {
      publicationDataApi.updateIcd11Values(currentRecord.id, newIcdEntries);
      updatePublicationData(newIcdEntries, "icd11Values");
    }

    console.log(newIcdEntries);
  };

  const updatePublicationData = (data, field) => {
    let newPublicationData = publicationData;
    newPublicationData[field] = data;

    setPublicationData((publicationData) => {
      return { ...publicationData, ...newPublicationData };
    });
  };

  const saveOutcomeContent = (outcomeContent, type) => {
    publicationDataApi.updateOutcomeContent(
      type,
      currentRecord.id,
      outcomeContent
    );

    const newPublicationData = publicationData;
    if (type === "control") {
      newPublicationData.controlVariables = outcomeContent;
    } else if (type === "adverse") {
      newPublicationData.adverseEvents = outcomeContent;
    } else if (type === "primary") {
      newPublicationData.primaryOutcome = outcomeContent;
    } else {
      newPublicationData.secondaryOutcome = outcomeContent;
    }

    setPublicationData((publicationData) => {
      return { ...publicationData, ...newPublicationData };
    });
  };

  const CommentField = (caption, value, idx, key) => {
    return (
      <RecordField
        index={idx}
        key={key}
        value={value != null ? value : ""}
        field={caption}
        onChange={() => {}}
        reset={() => {}}
        disabled={true}
        helperText=""
      />
    );
  };

  if (!publicationData) return null;

  // console.log(publicationData.publicationReferences.userConfirmationByState);
  // console.log(publicationData.groups);
  // console.log(publicationData.groups[0].userConfirmationByState);
  // console.log(publicationData.groups[1].userConfirmationByState);
  // console.log(publicationData.publicationRegistryEntries);

  const variablesChapter2 = ["subjects", "condition", "therapy", "procedure"];
  const variablesChapter1 = ["design"];

  return (
    <div>
      <Paper
        elevation={0}
        style={{
          maxHeight: 600,
          overflow: "auto",
        }}
      >
        {currentRecord && (
          <Box mb={2} display="flex" justifyContent="flex-end">
            <AnnotationUiOpenButton
              state={state}
              currentRecord={currentRecord}
              // updateRecord={updateRecord}
            />
          </Box>
        )}
        {currentRecord && (
          <ValidationComment
            currentRecord={currentRecord}
            saveRecord={saveRecord}
          />
        )}
        {CommentField(
          "General Comment",
          currentRecord.comment,
          1,
          1 + currentRecord.id
        )}
        {CommentField(
          "Annotator Comment",
          currentRecord.annotatorComment,
          2,
          2 + currentRecord.id
        )}
        {CommentField(
          "Reviewer Comment",
          currentRecord.reviewComment,
          3,
          3 + currentRecord.id
        )}
        {currentRecord && (
          <CollapsableField currentRecord={currentRecord} field={"title"} />
        )}
        {currentRecord && (
          <CollapsableField
            currentRecord={currentRecord}
            field={"abstractText"}
          />
        )}
        {state && currentRecord && (
          <CollapsablePdfViewer state={state} currentRecord={currentRecord} />
        )}

        <Box mt={2} display="flex" flexDirection="column">
          <Box mb={2}>
            <Variables
              publicationData={publicationData}
              saveVariable={saveVariable}
              currentWorkflowState={currentRecord.workflowState}
              variables={variablesChapter1}
              headerName={"1. Design"}
            />
          </Box>
          <Box mb={2}>
            <Variables
              publicationData={publicationData}
              saveVariable={saveVariable}
              currentWorkflowState={currentRecord.workflowState}
              variables={variablesChapter2}
              headerName={"2. Base"}
            />
          </Box>
          <Box mb={2}>
            <GroupsComponent
              publicationData={publicationData}
              saveGroups={saveGroups}
              currentWorkflowState={currentRecord.workflowState}
            />
          </Box>
          <Box mb={8}></Box>
          {createOutcomeComponents(
            publicationData,
            saveOutcomeContent,
            currentRecord.workflowState
          )}
          <Box mb={8}></Box>
          {createControlVariablesAndAdverseEventsComponents(
            publicationData,
            saveOutcomeContent,
            currentRecord.workflowState
          )}
          <Box mb={8}></Box>
          {publicationData.publicationRegistryEntries && (
            <RegistryEntriesComponent
              publicationRegistryEntries={
                publicationData.publicationRegistryEntries
              }
              currentWorkflowState={currentRecord.workflowState}
              saveRegistries={saveRegistries}
            />
          )}
          <Box mb={8}></Box>
          <Box display="flex" flexDirection="row" mt={3}>
            <Typography variant="h5">11: ICD</Typography>
          </Box>

          <IcdListComponent
            icdEntries={publicationData.icd10Values}
            currentWorkflowState={currentRecord.workflowState}
            componentTitle="11.1 ICD 10"
            type="ICD10"
            updateIcdContentInPublicationData={updateIcdContent}
          />
          <IcdListComponent
            icdEntries={publicationData.icd11Values}
            currentWorkflowState={currentRecord.workflowState}
            componentTitle="11.2 ICD 11"
            type="ICD11"
            updateIcdContentInPublicationData={updateIcdContent}
          />
          <Box mb={8}></Box>
          <Box>
            <PublicationReferences
              publicationData={publicationData}
              savePublicationReferences={savePublicationReferences}
              currentWorkflowState={currentRecord.workflowState}
            />
          </Box>
        </Box>
      </Paper>
    </div>
  );
}

const ValidationComment = ({ currentRecord, saveRecord }) => {
  const [editedContent, setEditedContent] = React.useState();
  const [lastContent, setLastContent] = React.useState();
  // const [disableTextField, setDisableTextField] = React.useState(false);

  React.useEffect(() => {
    if (currentRecord !== null) {
      setEditedContent(currentRecord.commentFinalValidation);
      setLastContent(currentRecord.commentFinalValidation);
    }
    return () => {
      setEditedContent();
      setLastContent();
    };
  }, [currentRecord]);

  const handleSaveClick = () => {
    let modifiedRecord = currentRecord;
    modifiedRecord.commentFinalValidation = editedContent;
    setLastContent(editedContent);
    saveRecord(modifiedRecord);
  };

  const handleChange = (index, error, field, value) => {
    setEditedContent(value);
  };

  const saveButton = (<ActionButton
    text="Save"
    captionText=""
    disabled={editedContent === lastContent}
    handleClick={handleSaveClick}
    color="secondary"
  />);

  // console.log(editedContent);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box flexGrow={1}>
        <RecordField
          key={currentRecord.id}
          value={currentRecord.commentFinalValidation}
          field={"commentFinalValidation"}
          onChange={handleChange}
          disabled={false}
          reset={editedContent === lastContent}
        />
      </Box>
      {saveButton}
    </Box>
  );
};

const createOutcomeComponents = (
  publicationData,
  saveOutcomeContent,
  currentWorkflowState
) => {
  return (
    <Box>
      <Box mb={1}>
        <Typography variant="h5" component="div">
          4. Outcomes
        </Typography>
      </Box>
      {publicationData.primaryOutcome === null ? (
        <Box>
          <Typography>No primary outcomes annotated</Typography>
        </Box>
      ) : (
        <OutcomeSectionsComponent
          outcomeContent={publicationData.primaryOutcome}
          currentWorkflowState={currentWorkflowState}
          type="primary"
          updateOutomeContentInPublicationData={saveOutcomeContent}
        />
      )}
      <Box mb={6}></Box>
      {publicationData.secondaryOutcome === null ? (
        <Box>
          <Typography>No secondary outcomes annotated</Typography>
        </Box>
      ) : (
        <OutcomeSectionsComponent
          outcomeContent={publicationData.secondaryOutcome}
          currentWorkflowState={currentWorkflowState}
          type="secondary"
          updateOutomeContentInPublicationData={saveOutcomeContent}
        />
      )}
    </Box>
  );
};

const createControlVariablesAndAdverseEventsComponents = (
  publicationData,
  saveOutcomeContent,
  currentWorkflowState
) => {
  return (
    <Box>
      <Box mb={1}>
        <Typography variant="h5" component="div">
          6. Control variables
        </Typography>
      </Box>
      {publicationData.controlVariables === null ? (
        <Box>
          <Typography>No control variables annotated</Typography>
        </Box>
      ) : (
        <OutcomeSectionsComponent
          outcomeContent={publicationData.controlVariables}
          currentWorkflowState={currentWorkflowState}
          type="control"
          updateOutomeContentInPublicationData={saveOutcomeContent}
          showChapter={false}
        />
      )}
      <Box mb={8}></Box>
      <Box mb={1}>
        <Typography variant="h5" component="div">
          7. Adverse events
        </Typography>
      </Box>
      {publicationData.adverseEvents === null ? (
        <Box>
          <Typography>No adverse events annotated</Typography>
        </Box>
      ) : (
        <OutcomeSectionsComponent
          outcomeContent={publicationData.adverseEvents}
          currentWorkflowState={currentWorkflowState}
          type="adverse"
          updateOutomeContentInPublicationData={saveOutcomeContent}
          showChapter={false}
        />
      )}
    </Box>
  );
};

export default FinalValidationRelease;
