import { useSnackbar } from "notistack";
import React from "react";
import api from "../../../api";
import { alertConfirm } from "../notification/notificationDialogs";
import { successSnackbarOptions } from "../notification/notifications";
import { ActionButton } from "./ActionButton";
import { buttonColor } from "./buttonProperties";

export default function ExcludeAll({
  currentTopicId,
  state,
  disableActions,
  handleRefreshPage,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const onConfirmed = () => {
    api.excludeAll(currentTopicId, state).then((data) => {
      const total = data;
      if (total === 0) {
        alertConfirm(
          "Exclude all",
          "Nothing to move: no excluded records found in this topic and state."
        );
      } else {
        enqueueSnackbar(
          total + " records move to Archived.",
          successSnackbarOptions
        );
        handleRefreshPage();
      }
    });
  };

  const handleClick = () => {
    alertConfirm(
      "Exclude all",
      "Do you want to move all excluded papers to ARCHIVED_EXCLUDED?",
      onConfirmed
    );
  };

  const excludeButton = 
  (<ActionButton
    text="Move all excluded to Archived"
    captionText={null}
    disabled={disableActions}
    handleClick={handleClick}
    color={buttonColor.excludeAll}
  />);
  return <div>{excludeButton}</div>;
}
