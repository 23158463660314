import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import LaunchIcon from "@mui/icons-material/Launch";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { AutoSizer, Column, Table } from "react-virtualized";
import { WorkflowStates } from "../../../model/WorkflowStates";
// import api from "../../../api";
// import { alert, alertInfo } from "../notification/notificationDialogs";

const styles = (theme) => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    "& .ReactVirtualized__Table__headerRow": {
      flip: false,
      paddingRight: theme.direction === "rtl" ? "0 !important" : undefined,
    },
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
});

export class MuiVirtualizedTable extends React.PureComponent {
  static defaultProps = {
    headerHeight: 40,
    rowHeight: 80,
  };

  getRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  getRowStyle = ({ index }) => {
    const style =
      (index + 1) % 2 ? { background: "#f0f5f9" } : { background: "white" };

    return style;
  };

  cellStyle = {
    border: "none",
    resize: "none",
    background: "inherit",
    fontSize: "12px",
    fontFamily: "Roboto",
    boxSizing: "border-box",
    width: "100%",
  };

  cellRenderer = ({ cellData, columnIndex }) => {
    const { columns, classes, rowHeight, onRowClick } = this.props;
    let cellContent = cellData;
    var variant = "body";

    if (["Annotator", "Reviewer"].includes(columns[columnIndex]["label"])) {
      variant = "footer";
      cellContent = cellData && (
        <textarea style={this.cellStyle} rows="4" disabled value={cellData} />
      );
    }

    if ("Goto".includes(columns[columnIndex]["label"])) {
      cellContent = (
        <IconButton
          size="small"
          disableRipple
          onClick={() => this.props.goto(cellData)}
          disabled={cellData.stateName === "Archived, excluded"}
          color="primary"
        >
          <LaunchIcon />
        </IconButton>
      );
    }

    if ("Move".includes(columns[columnIndex]["label"])) {
      cellContent = (
        <MoveButton
          cellData={cellData}
          handleMove={(data, selection) =>
            this.props.handleMove(data, selection)
          }
        />
      );
    }

    let cellStyle = { height: rowHeight, fontSize: "13px" };

    if ("Topic".includes(columns[columnIndex]["label"])) {
      if (cellData.duplicatedTopic)
        cellStyle = {
          height: rowHeight,
          fontSize: "13px",
          color: "#dc7633",
        };

      cellContent = cellData.topic;
    }

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant={variant}
        style={cellStyle}
        align="left"
      >
        {cellContent}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{ height: headerHeight }}
        align="left"
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } =
      this.props;
    // console.log(this.props);
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.getRowClassName}
            // rowStyle={{ background: "#fbfcfc" }}
            rowStyle={this.getRowStyle}
            onRowClick={() => null}
          >
            {columns.map(({ dataKey, ...other }, indexCol) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.headerRenderer({
                      ...headerProps,
                      columnIndex: indexCol,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={(cellProps) =>
                    this.cellRenderer({ ...cellProps })
                  }
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const MoveButton = ({ cellData, handleMove }) => {
  const [open, setOpen] = React.useState(false);
  const [selection, setSelection] = React.useState(moveSelections[0]);

  const handleChange = (event) => {
    setSelection(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAddClick = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Button
        size="small"
        disableRipple
        onClick={onAddClick}
        disabled={cellData.stateName !== "Archived, excluded"}
        color="primary"
        variant="contained"
        style={{ width: "40px", height: "20px" }}
      >
        Move
        {/* <ChevronRightIcon /> */}
      </Button>

      <Dialog
        maxWidth="sm"
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          <Typography variant="body1">Move paper to</Typography>
        </DialogTitle>
        <DialogContent>
          <React.Fragment>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Box>
                <Select
                  style={{ minWidth: 240 }}
                  value={selection}
                  label="Move to"
                  onChange={handleChange}
                >
                  {moveSelections.map((item, idx) => {
                    return (
                      <MenuItem key={item.toString()} value={item}>
                        {WorkflowStates[item].stateName}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>

              <Box
                mt={5}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                <Box mr={1}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpen(false);
                      handleMove(cellData, selection);
                    }}
                  >
                    OK
                  </Button>
                </Box>
              </Box>
            </Box>
          </React.Fragment>
        </DialogContent>
      </Dialog>
      <br />
      <br />
    </React.Fragment>
  );
};

const moveSelections = [
  "AUTO_PAPER_SELECTION_REVIEW",
  "SELECTION_ASSESSMENT",
  "SELECTION_ASSESSMENT_REVIEW",
  "INCOMING",
];

MuiVirtualizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
};

export const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);
