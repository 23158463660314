import { Box, Button, Chip, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import {
  getRecordFieldsToShow,
  ViewProperties,
} from "../../../model/ViewProperties";
import { WorkflowStates } from "../../../model/WorkflowStates";
import { areStringsNearlyEqualByLs } from "../../../utils/levenshtein";
import { buttonColor } from "../buttons/buttonProperties";
import FieldTab from "./FieldTab";
import RecordField from "./RecordField";
import { AnnotationUiOpenButton } from "../buttons/AnnotationUiOpenButton";
import { AnnotationConvertToUiButton } from "../buttons/AnnotationConvertToUiButton";

const checkSave = (array) => {
  return array.reduce((a, b) => a + b, 0) === 0;
};

function jsonCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

function RecordForm({
  state,
  currentRecord,
  saveRecord,
  handleDisabled,
  refresh,
  updateRecord,
}) {
  const fieldsToShow = getRecordFieldsToShow(state);
  const [disabledArray, setDisabledArray] = useState(() =>
    new Array(fieldsToShow.length).fill(0)
  );
  const [editedRecord, setEditedRecord] = useState();
  const { palette } = useTheme();

  const { showAnnotate } = ViewProperties[state];

  useEffect(() => {
    if (currentRecord != null) {
      setEditedRecord(jsonCopy(currentRecord));
      handleDisabled(true);
    }
  }, [currentRecord, handleDisabled]);

  let fieldsToValidate = ["year", "title", "authorsText"];

  let numberOfWarnings = validate(fieldsToValidate, state, currentRecord);

  const onChange = (idx, error, field, value) => {
    let copy = [...disabledArray];
    copy[idx] = error;
    setDisabledArray(copy);

    handleDisabled(checkSave(copy));

    let newRecord = editedRecord;
    newRecord[field] = value;
    setEditedRecord((editedRecord) => {
      return { ...editedRecord, ...newRecord };
    });
  };

  const onSaveClick = () => {
    saveRecord(editedRecord);
    setDisabledArray(new Array(fieldsToShow.length).fill(0));
    handleDisabled(true);
  };

  const disabled = checkSave(disabledArray);

  const recordField = (field, disabled, hideIfEmpty, idx) => {
    let [helperText, color] = validateHelperText(
      state,
      field,
      currentRecord,
      palette
    );

    if (!hideIfEmpty || currentRecord[field]) {
      if (state !== currentRecord.workflowState) disabled = true;
      if ((field === "abstractText") | (field === "title")) {
        let name = "Abstract";
        let design = "designsAbstract";
        if (field === "title") {
          name = "Title";
          design = "designsTitle";
        }
        return (
          <FieldTab
            currentRecord={currentRecord}
            idx={idx}
            key={idx + currentRecord.id}
            field={field}
            onChange={onChange}
            disabled={disabled}
            reset={isEquals(currentRecord, editedRecord)}
            fieldToSearch={design}
            name={name}
            helperText={helperText}
            helperTextColor={color}
          />
        );
      } else {
        return (
          <RecordField
            index={idx}
            key={idx + currentRecord.id}
            value={currentRecord[field] ? currentRecord[field].toString() : ""}
            field={field}
            onChange={onChange}
            reset={isEquals(currentRecord, editedRecord)}
            disabled={disabled}
            helperText={helperText}
            helperTextColor={color}
          />
        );
      }
    } else return null;
  };

  // let chipDuplicates; // = currentRecord.duplicate || (currentRecord.listDuplicate && currentRecord.listDuplicate.length > 0) ?

  return (
    <div>
      {/* <br /> */}
      <Box mb={1} display="flex" justifyContent="flex-end">
        <Box flexGrow={1} display="flex">
          {warnings(numberOfWarnings)}
          {chipDuplicate(currentRecord)}
          {topics(currentRecord)}
        </Box>
        <Box mr={2}>
          {showAnnotate && (
            <AnnotationConvertToUiButton
              state={state}
              currentRecord={currentRecord}
              updateRecord={updateRecord}
            />
          )}
        </Box>
        <Box mr={2}>
          {showAnnotate && (
            <AnnotationUiOpenButton
              state={state}
              currentRecord={currentRecord}
              updateRecord={updateRecord}
            />
          )}
        </Box>
        <Box display="flex">
          <Button
            variant="contained"
            disableRipple
            onClick={() => {
              onSaveClick();
            }}
            color={buttonColor.save}
            disabled={disabled}
            size="small"
          >
            <Typography variant="body2" align="center">
              Save
            </Typography>
          </Button>
        </Box>
      </Box>
      {currentRecord &&
        editedRecord &&
        fieldsToShow.map(([field, disabled, hideIfEmpty], idx) => {
          return recordField(field, disabled, hideIfEmpty, idx);
        })}
    </div>
  );
}

const chipDuplicate = (currentRecord) => {
  if (
    currentRecord.duplicate === null &&
    (currentRecord.listDuplicate === null ||
      currentRecord.listDuplicate.length === 0)
  )
    return null;
  let msg;
  if (currentRecord.duplicate) {
    msg = "duplicate";
  } else if (currentRecord.listDuplicate.length === 1) {
    msg = `has ${currentRecord.listDuplicate.length} duplicate`;
  } else {
    msg = `has ${currentRecord.listDuplicate.length} duplicates`;
  }

  return (
    <Box ml={1}>
      <Chip
        color={buttonColor.primary}
        size="small"
        label={msg}
        variant="outlined"
      />
    </Box>
  );
};

let warnings = (numberOfWarnings) => {
  if (numberOfWarnings === 0) return null;

  let msg = numberOfWarnings + " warnings found";

  return (
    <Box ml={1}>
      <Chip
        style={{ color: "orangeRed" }}
        size="small"
        label={msg}
        variant="outlined"
      />
    </Box>
  );
};

let topics = (currentRecord) => {
  let unique = new Set(currentRecord.listTopic).size;
  if (unique === 1) return null;

  let msg = `in ${unique} topics`;
  return (
    <Box ml={1}>
      <Chip
        color={buttonColor.primary}
        size="small"
        label={msg}
        variant="outlined"
      />
    </Box>
  );
};
// temporal solution
const validate = (fields, state, record) => {
  if (!record) return onabort;
  let numberOfWarnings = 0;

  // TODO: move to RecordActionsProperties
  if (
    ![
      WorkflowStates.AUTO_PAPER_SELECTION_REVIEW.state,
      WorkflowStates.SELECTION_ASSESSMENT.state,
      WorkflowStates.SELECTION_ASSESSMENT_REVIEW.state,
    ].includes(state)
  )
    return numberOfWarnings;

  for (const field of fields) {
    if (
      field === "year" &&
      record.yearPubmed &&
      record[field] !== record.yearPubmed
    ) {
      numberOfWarnings += 1;
    }
    if (
      field === "title" &&
      record.titlePubmed &&
      !areStringsNearlyEqualByLs(
        record[field].toLowerCase(),
        record.titlePubmed.toLowerCase(),
        0.98
      )
    ) {
      numberOfWarnings += 1;
    }

    if (field === "authorsText" && record.authors) {
      let authorsTextNormalized = normalizeByText(record[field]);
      let authorsPubmedNormalized = normalizeByTokenArray(record.authors);
      if (
        !authorsTextNormalized.every((authorText) =>
          authorsPubmedNormalized.some((authorPubmed) =>
            authorPubmed.includes(authorText)
          )
        )
      ) {
        numberOfWarnings += 1;
      }
    }
  }

  return numberOfWarnings;
};

const validateHelperText = (state, field, record, palette) => {
  // TODO: move to RecordActionsProperties
  if (
    ![
      WorkflowStates.AUTO_PAPER_SELECTION_REVIEW.state,
      WorkflowStates.SELECTION_ASSESSMENT.state,
      WorkflowStates.SELECTION_ASSESSMENT_REVIEW.state,
      WorkflowStates.ELIGIBILITY_ASSESSMENT_MAPPING.state,
      WorkflowStates.EXTRACTION_VALIDATION_DATA.state,
    ].includes(state)
  ) {
    return [null, null];
  }

  let color = "orangeRed";

  if (
    field === "year" &&
    record.yearPubmed &&
    record[field] !== record.yearPubmed
  ) {
    return ["Pubmed: " + record.yearPubmed, color];
  }

  if (
    field === "title" &&
    record.titlePubmed &&
    !areStringsNearlyEqualByLs(
      record[field].toLowerCase(),
      record.titlePubmed.toLowerCase(),
      0.98
    )
  ) {
    return ["Pubmed: " + record.titlePubmed, color];
  }

  if (
    field === "title" &&
    record.extractedTitleFromFile &&
    !areStringsNearlyEqualByLs(
      record[field].toLowerCase(),
      record.extractedTitleFromFile.toLowerCase(),
      0.98
    )
  ) {
    return ["Extracted title: " + record.extractedTitleFromFile, color];
  }

  color = palette.secondary.main;

  if (field === "authorsText" && record.authors) {
    let authorsTextNormalized = normalizeByText(record[field]);
    let authorsPubmedNormalized = normalizeByTokenArray(record.authors);
    if (
      !authorsTextNormalized.every((authorText) =>
        authorsPubmedNormalized.some((authorPubmed) =>
          authorPubmed.includes(authorText)
        )
      )
    ) {
      color = "orangeRed";
    }

    return ["Pubmed: " + record.authors.join(", "), color];
  }

  if (field === "referenceText" && record.referenceText) {
    if (record.dataSource && record.dataSource === "TRIALSTREAMER") {
      return [record.referenceText, color];
    }
    // journal + year; + volume (VI) + issue (IP) + pages --> we don't have issue neither source (SO) in Paper
    return [
      "Pubmed: " +
        record.journal +
        " " +
        record.yearPubmed +
        "; " +
        record.volume +
        " " +
        record.pages,
      color,
    ];
  }

  return [null, null];
};

const normalizeByTokenArray = (tokenArray) => {
  let terms = tokenArray
    .map((e) => {
      return e
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[Øø]/g, "o")
        .replace(/[^\w\s]/g, ""); // this is for different types of special characters like "-"
    })
    .filter((e) => e.length > 3);

  return terms;
};

const normalizeByText = (text) => {
  return normalizeByTokenArray(text.replace("et al.", "").split(" "));
};

function isEquals(rec1, rec2) {
  return JSON.stringify(rec1) === JSON.stringify(rec2);
}

export default RecordForm;
