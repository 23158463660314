import {
  Box,
  Button,
  Input,
  InputLabel,
  TextField,
  // TextField,
  Typography,
} from "@mui/material";
import React from "react";
import api from "../../api";
import { buttonColor } from "../subcomponents/buttons/buttonProperties";
import Spinner from "../subcomponents/notification/Spinner";
import ViewFormLayout from "./layouts/ViewFormLayout";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { UsersContext } from "../Context";
import { createSelectArea } from "../../utils/utils";
// import { CreateArea } from "../subcomponents/actionComponents/CreateArea";

export default function PubmedSearch() {
  const [areas, setAreas] = React.useState([]);
  const [selectedAreaIdx, setSelectedAreaIdx] = React.useState(0);
  const [currentAreaName, setCurrentAreaName] = React.useState("");
  const [currentTopicName, setCurrentTopicName] = React.useState("");
  const [currentPubmedSearchExpression, setCurrentPubmedSearchExpression] =
    React.useState("");
  const [currentDescription, setCurrentDescription] = React.useState("");
  const [pubmedSearchReviews, setPubmedSearchReviews] = React.useState();
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const { currentUser } = React.useContext(UsersContext);

  React.useEffect(() => {
    api.fetchAllAreas().then((data) => {
      data.sort((a1, a2) => a1.name.localeCompare(a2.name));
      setAreas(data);
      setCurrentAreaName(data[0].name);
    });

    api.fetchAllPubmedSearchReviews().then((data) => {
      setPubmedSearchReviews(data);
    });

    return () => {
      setAreas([]);
      setSelectedAreaIdx(0);
      setCurrentAreaName("");
      setCurrentTopicName("");
      setCurrentPubmedSearchExpression("");
      setCurrentDescription("");
      setPubmedSearchReviews();
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuccess = (response) => {
    console.log("status: " + response.status);
    const title = "Import Pubmed Search Review";

    if (response.status === 200) {
      const pubmedSearchData = response.data;

      let msg =
        "Topic: " +
        pubmedSearchData.topicName +
        "\n Area: " +
        pubmedSearchData.areaName +
        "\n Total records processed: " +
        pubmedSearchData.totalPubmedSearchRecords +
        "\n Total RCTs found: " +
        pubmedSearchData.totalRCTsFound;

      if (pubmedSearchData.message != null) {
        msg +=
          "\n\nPubmed search review uploaded successful, please be aware of the following:\n\n" +
          pubmedSearchData.message;
      }
      swal(title, msg, "success", {
        closeOnClickOutside: false,
      }).then((ok) => {
        if (ok) {
          setCurrentAreaName("");
          setCurrentTopicName("");
          setCurrentPubmedSearchExpression("");
          setCurrentDescription("");
          window.location.reload();
        }
      });
    } else {
      // response is a text: 201:created, 208: already reported, 204: no content
      let msg = title;
      if (response.data.message != null) {
        msg = response.data.message;
      }
      swal(msg, response.data, "warning", { closeOnClickOutside: false });
    }
    setIsProcessing(false);
  };

  const handleError = (e) => {
    console.log(e.response);
    setIsProcessing(false);
    if (e.response.data !== null) {
      swal(e.response.statusText, e.response.data, "warning", {
        closeOnClickOutside: false,
      }).then((ok) => {
        if (ok) {
        }
      });
    }
  };

  const onImportPubmedFile = (e) => {
    console.log("importing pubmed file");
    const file = e.target.files[0];
    setIsProcessing(true);
    api
      .uploadPubmedFile(
        file,
        currentAreaName,
        currentTopicName,
        currentPubmedSearchExpression,
        currentDescription
      )
      .then((response) => {
        handleSuccess(response);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  const checkReady = () => {
    // TODO: set more strict rules.
    if (currentTopicName && currentPubmedSearchExpression && currentDescription)
      return true;
    return false;
  };

  const handleChange = (field) => (e) => {
    if (field === "Topic Name") {
      setCurrentTopicName(e.target.value);
    } else if (field === "Pubmed Search Expression") {
      setCurrentPubmedSearchExpression(e.target.value);
    } else if (field === "Description") {
      setCurrentDescription(e.target.value);
    }
  };

  const importPubmedFileButton = UploadButton(
    onImportPubmedFile,
    !checkReady()
  );

  const spinner = isProcessing ? (
    <Spinner
      mx={0}
      my={10}
      text={"Processing... This can take some seconds..."}
      disableShrink
    />
  ) : null;

  // TODO: check for special characters in topic name
  const topic = createTextField("Topic Name", handleChange, currentTopicName);
  const pubmedSearch = createTextField(
    "Pubmed Search Expression",
    handleChange,
    currentPubmedSearchExpression
  );

  const description = createTextField(
    "Description",
    handleChange,
    currentDescription,
    true
  );

  const infoTopicName = createInfo(
    "Introduce topic name. Do not use special characters (_, /, -, etc).",
    {
      marginBottom: 10,
    }
  );
  const infoPubmedFile = createInfo("Upload a pubmed text file (.txt format)", {
    marginBottom: 10,
    marginTop: 30,
  });
  const infoPubmedSearch = createInfo(
    "Introduce the pubmed expression used to generate the pubmed search text file.",
    {
      marginBottom: 10,
      marginTop: 30,
    }
  );

  const infoDescription = createInfo(
    "Introduce a description of the pubmed search.",
    {
      marginBottom: 10,
      marginTop: 30,
    }
  );

  const infoReviews = pubmedSearchReviews
    ? createInfoReviews(pubmedSearchReviews)
    : null;

  const removeTopic =
    currentUser === "alberto" || currentUser === "martin" ? (
      <RemoveTopicComponent
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    ) : null;

  const handleSelectionChange = (e) => {
    setSelectedAreaIdx(e.target.value);
    setCurrentAreaName(areas[e.target.value].name);
  };

  const selectArea = createSelectArea(
    areas,
    selectedAreaIdx,
    handleSelectionChange,
    "Select Area"
  );

  return (
    <ViewFormLayout
      form={
        <>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {selectArea}
            <br />
            {infoTopicName}
            {topic}
            {infoPubmedSearch}
            {pubmedSearch}
            {infoDescription}
            {description}
            {infoPubmedFile}
            {importPubmedFileButton}
            {spinner}
          </Box>
          <br />
          {removeTopic}
          <br />
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            {infoReviews}
          </Box>
        </>
      }
    />
  );
}

const createInfoReviews = (pubmedSearchReviews) => {
  if (pubmedSearchReviews.length < 1) return null;
  let infoReviews = pubmedSearchReviews.map((r) => {
    return <InfoReview key={r.topicName} review={r} />;
  });

  return (
    <>
      <br />
      <strong>
        pubmed search reviews uploaded (temporal display for testing)
      </strong>
      <br />
      {infoReviews}
    </>
  );
};

const InfoReview = ({ review }) => {
  return (
    <div>
      topicName: {review.topicName}
      <br />
      areaName: {review.areaName}
      <ul>
        <li key={review.totalPubmedSearchRecords + 10000}>
          totalPubmedSearchRecords: {review.totalPubmedSearchRecords}
        </li>
        <li key={review.totalRCTsFound}>
          totalRCTsFound: {review.totalRCTsFound}
        </li>
        <li key={review.description}>description: {review.description}</li>
        <li key={review.creationDate}>creationDate: {review.creationDate}</li>
        {review.importMessage && (
          <li key={review.importMessage}>
            importMessage: {review.importMessage}
          </li>
        )}
        {review.pubmedSearchExpression && (
          <li key={review.pubmedSearchExpression}>
            pubmed expression: {review.pubmedSearchExpression}
          </li>
        )}
      </ul>
    </div>
  );
};

const createTextField = (name, handleChange, currentValue, multiline) => {
  return (
    <TextField
      label={name}
      style={{ marginBottom: 12 }}
      margin="dense"
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      fullWidth
      onChange={(e) => handleChange(name)(e)}
      value={currentValue}
      multiline={multiline}
    />
  );
};

const UploadButton = (onImport, disabled) => {
  return (
    <InputLabel htmlFor="upload">
      <Input
        style={{ display: "none" }}
        id="upload"
        name="upload"
        type="file"
        value="" // hack to trigger onChange, even when uploading same file
        onChange={onImport}
        disabled={disabled}
      />

      <Button
        component="span"
        variant="contained"
        disableRipple
        color={buttonColor.upload}
        size="medium"
        disabled={disabled}
      >
        {
          <Typography variant="body2" display="block" align="center">
            Import Pubmed File
          </Typography>
        }
      </Button>
    </InputLabel>
  );
};

const createInfo = (text, style) => {
  return (
    <Typography
      variant="subtitle1"
      display="block"
      align="center"
      color="primary"
      style={style}
    >
      {text}
    </Typography>
  );
};

const RemoveTopicComponent = ({ open, handleClickOpen, handleClose }) => {
  const [topicName, setTopicName] = React.useState("");

  const handleChange = (e) => {
    setTopicName(e.target.value);
  };
  const handleCloseButton = (e) => {
    setTopicName("");
    handleClose();
  };
  const handleRemove = (e) => {
    api
      .deleteTopicFromPubmedSearch(topicName)
      .then((response) => {
        let format = "success";

        if (response.status !== 200) {
          format = "warning";
        }

        swal(response.data, response.data, format, {
          closeOnClickOutside: false,
        }).then((ok) => {
          setTopicName("");
          handleClose();
          window.location.reload();
        });
      })
      .catch((e) => {
        console.log("error: " + JSON.stringify(e.response));
        swal(
          "Error when removing topic. Inconsistent data. Send this message to SWT administrators.",
          e.response.data,
          "warning",
          {
            closeOnClickOutside: false,
          }
        ).then((ok) => {});
      });
  };

  return (
    <div>
      <Button
        disableRipple
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Remove topic
      </Button>
      <Dialog
        open={open}
        onClose={handleCloseButton}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Remove topic completely
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Remove pubmed search topic and all its papers, but those that belong
            to others topics.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="topicName"
            // type="email"
            fullWidth
            onChange={(e) => handleChange(e)}
            value={topicName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRemove} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
