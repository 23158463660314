export const AnnotationType: any = {
  CONTENT: {
    type: "CONTENT",
    url: "content",
    name: "content",
  },
  EXTRACTED_CONTENT: {
    type: "EXTRACTED_CONTENT",
    url: "extracted_content",
    name: "extracted_content",
  },
  /**
   * We have a separate type therefore which eases the conversion to the backend
   */
  EMPTY: {
    type: "EMPTY",
    url: "empty",
    name: "empty",
  },
  EXTRACTED_EMPTY: {
    type: "EXTRACTED_EMPTY",
    url: "extracted_empty",
    name: "extracted_empty",
  },
  ICD: {
    type: "ICD",
    url: "icd",
    name: "ICD",
  },
  REGISTRY: {
    type: "REGISTRY",
    url: "registry",
    name: "Registry-Entries",
  },
};

export function byAnnotationType(inType: string) {
  for (let type in AnnotationType) {
    if (AnnotationType[type].type === inType) {
      return AnnotationType[type];
    }
  }
  return null;
}
