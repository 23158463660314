export const TOP_NAV_NAMES = {
  SOURCE_SELECTION: "Source selection",
  INCOMING_PAPERS: "Incoming Papers",
  ELIGIBILITY_ASSESSMENT: "Eligibility Assessment",
  PAPER_ANALYSIS: "Paper Analysis",
  EXTRACTION: "Extraction",
  RELEASE: "Release",
  MISCELLANEOUS: "Miscellaneous",
};

export const BUTTON_NAV_NAMES = {
  PAPER_IMPORT: "Paper Import",
  REFERENCE_LOOKUP: "Reference Lookup (auto)",
  AUTO_PAPER_SELECTION_REVIEW: "Auto Paper Selection Review",
  PAPER_SELECTION: "Selection",
  PAPER_SELECTION_REVIEW: "Selection Review",
  PAPER_UPLOAD: "Paper Upload",
  NOT_FOUND_PAPERS: "Not Found Papers",
  DESIGN_PRE_ANNOTATION: "Design Pre-annotation (auto)",
  ELIGIBILITY_ASSESSMENT_MAPPING: "Registry-Mapping",
  MANUAL_ASSESSMENT: "Manual Assessment",
  PRE_ANNOTATION: "Pre-annotation (auto)",
  ANNOTATION: "Annotation",
  REVIEW: "Review",
  AUTO_EXTRACTION: "Extraction (Auto)",
  EXTRACTION_VALIDATION_VARIABLES: "Variable Validation",
  EXTRACTION_VALIDATION_DATA: "Data Validation",
  AUTO_RELEASE_VALIDATION: "Validation (auto)",
  RELEASE_VALIDATION: "Final Validation",
  RELEASE_VALIDATION_REVIEW: "Final Validation Review",
  // RELEASE_PROCESSING: "Release Candidate Processing (auto)",
  // RELEASE: "Paper Release",
  RELEASED: "Released",
  TO_BE_DISCUSSED: "To be discussed",
  LONG_TERM_TO_BE_DISCUSSED: "Long term to be discussed",
  ALL_PAPERS: "All Papers",
  AREAS_ADMIN: "Area Responsibility",
};
