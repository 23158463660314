import api from "../../api";
import utils from "../../utils/utils";

export const fetchPPs = (
  state,
  currentTopicId,
  setPublicationParentList,
  setRecordList,
  setCurrentRecord,
  setResetTableIndex,
  setIsPapersLoading
) => {
  setIsPapersLoading(true);
  api.fetchPP(state, currentTopicId).then((ppList) => {
    utils.sortRecords(ppList, utils.getPPPrefix);
    let recList = [];
    ppList.forEach((pp) => {
      pp.listPapers.forEach((paper) => {
        recList.push(paper);
      });
    });
    setPublicationParentList(ppList);
    setRecordList(recList);
    setCurrentRecord(recList[0]);
    setResetTableIndex((r) => !r);
    setIsPapersLoading(false);
  });
};

export const fetchRecords = (
  state,
  currentTopicId,
  setRecordList,
  setCurrentRecord,
  setResetTableIndex,
  from = "fromTopic"
) => {
  api
    .fetchRecords(state, currentTopicId, from)
    .then((recList) => {
      if (true) {
        // utils.sortRecordsState(recList, utils.getPaperNameState, state);
        utils.sortRecords(recList, utils.getPaperName);
      }
      setRecordList(recList);
      setCurrentRecord(recList[0]);
      setResetTableIndex((r) => !r);
    })
    .catch((e) => console.log("processing error: " + e));
};

export const getIdAndIdx = (list, searchId) => {
  let idx = list.findIndex(({ id }) => id === searchId);
  let id;

  if (idx !== -1) {
    id = searchId;
  } else {
    id = list[0].id;
    idx = 0;
  }

  return { id: id, idx: idx };
};

export const fetchAreasAndTopics = (
  state,
  setAreas,
  setCurrentAreaId,
  setTopics,
  setCurrentTopicId,
  location,
  setDone
) => {
  api
    .fetchAreas(state)
    .then((data) => {
      if (data.listAreas.length > 0) {
        let sortedAreas = data.listAreas;
        utils.sortRecords(sortedAreas, (area) => area.name);
        setAreas(sortedAreas);
        let parts = location.pathname.split("/");
        let idAndIdx;
        // parts has areaId and topicId: "AllPapers" and "My locked papers" views.
        // or only areaId (Area Responsibility)
        // Notice that first part will be "". So when only areaId --> parts.length = 4
        // and when areaId and topicId --> parts.length = 5
        if (parts.length > 3) {
          idAndIdx = getIdAndIdx(data.listAreas, parts[3]);
          api.setAreaForUser(state, null, idAndIdx.id);
        }
        // parts has neither areaId nor topicId (normal browsing)
        else {
          idAndIdx = getIdAndIdx(data.listAreas, data.selectedId);
        }

        setCurrentAreaId(idAndIdx.id);

        api
          .fetchTopics(state, idAndIdx.id)
          .then((data) => {
            let sortedTopics = data.listTopics;
            utils.sortRecords(sortedTopics, (topic) => topic.title);
            setTopics(sortedTopics);

            if (parts.length > 4) {
              idAndIdx = getIdAndIdx(data.listTopics, parts[4]);
              api.setTopicForUser(state, null, idAndIdx.id);
            } else {
              idAndIdx = getIdAndIdx(data.listTopics, data.selectedId);
            }

            setCurrentTopicId(idAndIdx.id);
          })
          .catch((e) => console.log("fetching topic error", e));
      }

      setDone(true);
    })
    .catch((e) => console.log("fetching areas error", e));
};

export const fetchTopics = (
  state,
  idArea,
  setTopics,
  setCurrentTopicId,
  reloadPage
) => {
  api
    .fetchTopics(state, idArea)
    .then((data) => {
      let sortedTopics = data.listTopics;
      if (sortedTopics.length === 0) {
        //special case when dealing with duplicates, and another topic gets empty list because in another topic
        //the last record was moved. Then we refresh the page.
        reloadPage();
      } else {
        utils.sortRecords(sortedTopics, (topic) => topic.title);
        setTopics(sortedTopics);
        let idAndIdx = getIdAndIdx(data.listTopics, data.selectedId);
        setCurrentTopicId(idAndIdx.id);
      }
    })
    .catch((e) => console.log("fetching topic error when selecting area", e));
};
