import React from "react";
import { useLocation } from "react-router-dom";
import api from "../../api";
import annotationApi from "../../api/annotationApi";
import { WorkflowStates } from "../../model/WorkflowStates";
import { RecordActions } from "../subcomponents/actionComponents/RecordActions";
import { NoAreasFound } from "../subcomponents/NoAreasFound";
import AreaTopicSelect from "../subcomponents/selectors/AreaTopicSelectNew";
import RecordTableList from "../subcomponents/tables/RecordTableList";
import { fetchAreasAndTopics, fetchRecords, fetchTopics } from "./fetchData";
// import { fetchRecords } from "./fetchData";
import ViewFormLayout from "./layouts/ViewFormLayout";
import {
  totalValidated,
  VariableValidation,
} from "./validationViews/VariableValidation";

const VariableValidationView = ({ state }) => {
  const [areas, setAreas] = React.useState([]);
  const [topics, setTopics] = React.useState([]);
  const [currentAreaId, setCurrentAreaId] = React.useState();
  const [currentTopicId, setCurrentTopicId] = React.useState();
  const [recordList, setRecordList] = React.useState([]);
  const [currentRecord, setCurrentRecord] = React.useState(null);
  const [resetTableIndex, setResetTableIndex] = React.useState(false);
  const [extractedAnnotations, setExtractedAnnotations] = React.useState([]);
  const [validationOk, setValidationOk] = React.useState(-1);
  const [done, setDone] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    fetchAreasAndTopics(
      state,
      setAreas,
      setCurrentAreaId,
      setTopics,
      setCurrentTopicId,
      location,
      setDone
    );

    return () => {
      setAreas([]);
      setTopics([]);
    };
  }, [state, location]);

  React.useEffect(() => {
    if (currentTopicId) {
      fetchRecords(
        state,
        currentTopicId,
        setRecordList,
        setCurrentRecord,
        setResetTableIndex,
        "fromTopic"
      );
    }

    return () => {
      setRecordList([]);
    };
  }, [state, currentTopicId]);

  React.useEffect(() => {
    if (currentRecord) {
      annotationApi
        .fetchContentAnnotationsForRecord(currentRecord.id)
        .then((data) => {
          if (data.extractedAnnotations !== null) {
            setExtractedAnnotations(data.extractedAnnotations);
          }
        });
    }

    return () => {};
  }, [currentRecord]);

  React.useEffect(() => {
    if (extractedAnnotations?.length > 0) {
      setValidationOk(
        extractedAnnotations.length - totalValidated(extractedAnnotations)
      );
    }

    return () => {
      setValidationOk(-1);
    };
  }, [extractedAnnotations]);

  const fetchDataReady =
    currentAreaId && currentTopicId && areas.length > 0 && topics.length > 0;

  if (done && !currentAreaId) {
    return <NoAreasFound />;
  }

  if (!fetchDataReady) {
    return <ViewFormLayout />;
  }

  const handleCurrentRecordIdx = (recordId) => {
    const idx = recordList.findIndex(({ id }) => id === recordId);
    setCurrentRecord(recordList[idx]);
  };

  const updateRecord = (record) => {
    const idx = recordList.findIndex(({ id }) => id === record.id);
    let listCopy = [...recordList];
    listCopy[idx] = record;
    setRecordList(listCopy);
    setCurrentRecord(record);
  };

  const handleValidationOk = (e) => {
    if (e) {
      setValidationOk((v) => v - 1);
    } else if (!e) {
      setValidationOk((v) => v + 1);
    }
  };

  const handleChangeArea = (idArea) => {
    setCurrentRecord(null);
    setCurrentTopicId(null);
    setTopics([]);
    setCurrentAreaId(idArea);
    fetchTopics(state, idArea, setTopics, setCurrentTopicId, null);
    api.setAreaForUser(state, null, idArea);
  };

  const handleChangeTopic = (idTopic) => {
    setCurrentTopicId(idTopic);
    api.setTopicForUser(state, null, idTopic);
  };

  const Selector = (
    <AreaTopicSelect
      areas={areas}
      topics={topics}
      initialAreaId={currentAreaId}
      initialTopicId={currentTopicId}
      handleChangeArea={handleChangeArea}
      handleChangeTopic={handleChangeTopic}
      // responsible={responsible}
    />
  );

  return (
    <React.Fragment>
      <ViewFormLayout
        select={Selector}
        table={
          recordList.length > 0 && (
            <RecordTableList
              recordList={recordList}
              handleCurrentRecordIdx={handleCurrentRecordIdx}
              resetIndex={resetTableIndex}
              //   disableActions={disableActions}
            />
          )
        }
        recordActions={
          currentRecord && (
            <RecordActions
              state={state}
              currentRecord={currentRecord}
              updateRecord={updateRecord}
              disableRecordActions={validationOk !== 0}
              // disableRecordActions={false}
              disableBackwards={
                currentRecord.workflowState !==
                WorkflowStates.EXTRACTION_VALIDATION_VARIABLES.state
              }
            />
          )
        }
        form={
          currentRecord &&
          extractedAnnotations && (
            <VariableValidation
              state={state}
              extractedAnnotations={extractedAnnotations}
              currentRecord={currentRecord}
              handleValidationOk={handleValidationOk}
              validationOk={validationOk}
            />
          )
        }
      />
    </React.Fragment>
  );
};

export default VariableValidationView;
