import { Button } from "@mui/material";
import { SortBy } from "../utils/highlightUtils";
import React from "react";

export function SortBar(
  setSortBy: React.Dispatch<React.SetStateAction<number>>
) {
  return (
    <div
      style={{
        borderBottom: "1px solid rgb(119, 119, 119)",
        borderTop: "1px solid rgb(119, 119, 119)",
        padding: "0.5rem",
      }}
    >
      Sort by:
      <Button
        onClick={() => {
          setSortBy(SortBy.PAGE);
        }}
      >
        Page
      </Button>
      <Button
        onClick={() => {
          setSortBy(SortBy.CHAPTER);
        }}
      >
        Chapter
      </Button>
    </div>
  );
}
