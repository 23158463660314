import axios from "axios";
import { WorkflowStates } from "./model/WorkflowStates";

// TODO: refactor and return response instead response.data

axios.defaults.timeout = 5000; // Set default timeout to 5 seconds.

const CONFIG_TIMEOUT_10_SECONDS = {
  timeout: 10000,
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location = window.location.origin + "/login";
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error.response.status === 401) {
      window.location = window.location.origin + "/login";
    }
    return Promise.reject(error);
  }
);

export const LOCATION = "/api/";

export const Action = {
  BACKWARD: "backward",
  FORWARD: "forward",
  TO_STATE: "toState",
};

export async function fetchRecords(state, id, from) {
  let url;

  if (from === "fromArea") {
    url = LOCATION + `papersForWorkflowStateByArea/${state}/${id}`;
  } else {
    url = LOCATION + `papersForWorkflowStateByTopic/${state}/${id}`;
  }

  const response = await axios.get(url);
  return response.data;
}


export async function fetchRecordsAll() {
  let url = LOCATION + `workflowpapers`;

  const response = await axios.get(url, fetchRecords, CONFIG_TIMEOUT_10_SECONDS);
  return response.data;
}

export async function fetchRecordsIncluded() {
  let url = LOCATION + `workflowpapersIncludedWithAreaAndTopic`;

  const response = await axios.get(url, CONFIG_TIMEOUT_10_SECONDS);
  return response.data;
}

export async function fetchRecordsExcluded() {
  let url = LOCATION + `workflowpapersExcludedWithAreaAndTopic`;

  const response = await axios.get(url, CONFIG_TIMEOUT_10_SECONDS);
  return response.data;
}

export async function fetchExclusionStates() {
  const url = LOCATION + "workflowPaperExclusionStates";

  let data = null;
  await axios.get(url)
    .then((r) => {
      data = r.data;
    })
    .catch((e) => {
      console.log("Error getting exclusion states: " + e);
    });
  return data;
}

export async function fetchAreas(state) {
  const view = WorkflowStates[state].view;

  const url = LOCATION + `areasWithWorkflowState/${state}/${view}`;
  const response = await axios.get(url);
  return response.data;
}

export async function fetchTopics(state, id) {
  const view = WorkflowStates[state].view;

  const url = LOCATION + `topicsWithWorkflowStateByArea/${state}/${view}/${id}`;
  const response = await axios.get(url);
  return response.data;
}

export function setTopicForUser(state, topic, id) {
  const view = WorkflowStates[state].view;
  const url = LOCATION + `papersSetSelectedTopicForState/${view}/${id}`;

  axios.put(url);
}


export async function changePassword(body) {
  let url = LOCATION + `current-user-password`;

  const response = await axios.put(url, body);
  return response;
}

export function setAreaForUser(state, area, id) {
  const view = WorkflowStates[state].view;
  const url = LOCATION + `papersSetSelectedAreaForState/${view}/${id}`;

  axios.put(url);
}

export function getPaperUrl() {
  return "/paper";
}

export async function moveRecord(record, action) {
  let url;
  if (action === Action.FORWARD) {
    url = LOCATION + "paperChangeWorkflowState/" + record.id;
  } else if (action === Action.BACKWARD) {
    url = LOCATION + "processPaperToPreviousStep/" + record.id;
  } else {
    window.alarm("Not available action");
  }

  const response = await axios.put(url, record, { timeout: 5000 });
  return response.data;
}

export async function moveToState(id, fromState, toState) {
  let url =
    LOCATION +
    "paperMoveToWorkflowState/" +
    id +
    "/" +
    fromState +
    "/" +
    toState;

  const response = await axios.put(url);
  return response;
}

export async function processing(record) {
  console.log("process");
  let url = LOCATION + "processPaperToNextStep/" + record.id;

  const response = await axios.put(url, record);
  return response.data;
}

export function uploadPdf(record, file) {
  const formData = new FormData();
  formData.append("file", file);

  let url;

  if (
    record.workflowState === WorkflowStates.INCOMING.state ||
    record.workflowState === WorkflowStates.INCOMING_NOT_FOUND.state
  ) {
    url = LOCATION + "uploadPaperEmpty/" + record.id;
  } else {
    url = LOCATION + "uploadPaperAnnotated/" + record.id;
  }

  console.log("url: " + url);

  const config = {
    method: "post",
    url: url,
    data: formData,
    processData: false, //prevent jQuery from automatically transforming the data into a query string
    contentType: false,
    cache: false,
    timeout: 600000,
  };

  return axios(config).then((response) => {
    console.log("uploadPdf status: " + response.status);
    return response.data;
  });
}

/**
 * This is only allowed in paper analysis and analysis review
 */
export function updatePdfFileWithoutStateChange(record, file) {
  const formData = new FormData();
  formData.append("file", file);

  let url = LOCATION + "updatePaperAnnotated/" + record.id;

  const config = {
    method: "post",
    url: url,
    data: formData,
    processData: false, //prevent jQuery from automatically transforming the data into a query string
    contentType: false,
    cache: false,
    timeout: 600000,
  };

  return axios(config).then((response) => {
    console.log("PDF update status: " + response.status);
    return response.data;
  });
}

export async function generatePdf(record) {
  const url = LOCATION + "generatePdf/" + record.id;
  const response = await axios.put(url, record);
  return response.data;
}

export async function downloadPaper(url) {
  return axios({
    url: url,
    method: "GET",
    responseType: "blob", // important
  });
  // const response = await axios.get(url, {responseType: 'blob'});
  // return response.data;
}

export async function convertPaperToAnnotatinUi(record) {
  const url = LOCATION + "paper/convert-to-ui/" + record.id;
  const config = {
    method: "GET",
    url: url,
    timeout: 60000
  };

  const response = await axios(url, config);
  return response;
}

export async function uploadReview(file) {
  const formData = new FormData();
  formData.append("file", file);

  let url = LOCATION + "uploadNewReview";

  console.log("url: " + url);

  const config = {
    method: "POST",
    enctype: "multipart/form-data",
    url: url,
    data: formData,
    processData: false, //prevent jQuery from automatically transforming the data into a query string
    contentType: false,
    cache: false,
    timeout: 600000,
  };

  const response = await axios(config);
  // console.log("upload Review status: " + response.status);
  return response;
}

export async function createAreaAndTopic(areaTopicPaper) {
  let url = LOCATION + "createAreaTopicPaper";
  const config = {
    url: url,
    timeout: 6000,
  };

  const response = await axios.post(url, areaTopicPaper, config);
  return response;
}

export async function createArea(area) {
  let url = LOCATION + "create-area";
  const config = {
    url: url,
    timeout: 6000,
  };

  const response = await axios.post(url, area, config);
  return response;
}

export async function uploadPubmedFile(
  file,
  currentAreaName,
  currentTopicName,
  currentPubmedSearchExpression,
  description
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("areaName", currentAreaName);
  formData.append("topicName", currentTopicName);
  formData.append("pubmedSearchExpression", currentPubmedSearchExpression);
  formData.append("description", description);
  let url = LOCATION + "uploadPubmedFile";

  const config = {
    method: "post",
    enctype: "multipart/form-data",
    url: url,
    data: formData,
    processData: false, //prevent jQuery from automatically transforming the data into a query string
    contentType: false,
    cache: false,
    timeout: 600000,
  };

  const response = await axios(config);
  // console.log(response.data);
  return response;
  // });
}

export async function fetchAllPubmedSearchReviews() {
  let url = LOCATION + "getPubmedSearchReviews";
  const response = await axios.get(url);
  return response.data;
}

export function saveRecord(record) {
  var status = "null record";

  if (record != null) {
    let url = LOCATION + "papersIncomingChangeById/" + record.id;

    axios
      .put(url, record)
      .then((r) => {
        status = r.status;
      })
      .catch((e) => console.log("save error: " + e));
  }

  return status;
}

export async function createNewRecord(areaId, topicId) {
  let url = LOCATION + "createNewPaper/" + areaId + "/" + topicId;

  const response = await axios.post(url, null);
  return response.data;
}

export async function createDuplicate(areaId, topicId, paperId) {
  let url =
    LOCATION + "createDuplicate/" + areaId + "/" + topicId + "/" + paperId;

  const response = await axios.post(url, null);
  return response;
}

export async function deleteRecord(recordId) {
  let url = LOCATION + "deletePaperCompletely/" + recordId;

  const response = await axios.delete(url, null);
  return response;
}

export async function deleteTopicFromPubmedSearch(topicName) {
  let url =
    LOCATION + "removeTopicWithCochraneAreaFromPubmedSearchSafe/" + topicName;

  const response = await axios.delete(url, null);
  return response;
}

export async function fetchPP(state, id) {
  let url =
    LOCATION + `publicationParentsForWorkflowStateByTopic/${state}/${id}`;

  const response = await axios.get(url);
  return response.data;
}

export async function getUser() {
  let url = LOCATION + "current-user";
  const response = await axios.get(url);
  return response.data;
}

// TODO: finish
export async function excludeAll(currentTopicId, state) {
  let url =
    LOCATION +
    `topicChangeWorkflowStateExcludedByState/${currentTopicId}/${state}`;
  const response = await axios.put(url);
  return response.data;
}

export async function getAreaAndTopic(paperId) {
  let url = LOCATION + `areaAndTopic/${paperId}`;
  const response = await axios.get(url);
  return response.data;
}

export async function fetchAllAreas() {
  let url = LOCATION + "areas";
  const response = await axios.get(url);
  return response.data;
}

export async function getUsers() {
  let url = LOCATION + "user-names";
  const response = await axios.get(url);
  return response.data;
}

export async function setResponsibilityForArea(
  areaId,
  assignee,
  responsibility
) {
  let url =
    LOCATION +
    `setResponsibilityForArea/${areaId}/${assignee}/${responsibility}`;
  const response = await axios.put(url);
  return response;
}

export async function getUiProperties(name) {
  let url = LOCATION + `ui-properties/${name}`;

  const response = await axios.get(url);
  return response;
}

export async function saveUiProperties(uiProperties) {
  let url = LOCATION + `ui-properties/save/`;

  const response = await axios.put(url, uiProperties);
  return response;
}

export async function validatePaperAnnotations(id) {
  let url = LOCATION + `paper/validate/${id}`;

  const config = {
    url: url,
    timeout: 30000,
  };

  const response = await axios.get(url, config);
  return response;
}

const exportedApiFunctions = {
  convertPaperToAnnotatinUi,
  createArea,
  createAreaAndTopic,
  downloadPaper,
  fetchExclusionStates,
  fetchRecords,
  fetchRecordsAll,
  fetchRecordsIncluded,
  fetchRecordsExcluded,
  fetchAreas,
  fetchTopics,
  setTopicForUser,
  setAreaForUser,
  getPaperUrl,
  moveRecord,
  moveToState,
  processing,
  updatePdfFileWithoutStateChange,
  uploadPdf,
  uploadReview,
  uploadPubmedFile,
  fetchAllPubmedSearchReviews,
  generatePdf,
  saveRecord,
  createNewRecord,
  createDuplicate,
  deleteRecord,
  deleteTopicFromPubmedSearch,
  fetchPP,
  getUser,
  excludeAll,
  getAreaAndTopic,
  fetchAllAreas,
  getUsers,
  setResponsibilityForArea,
  getUiProperties,
  saveUiProperties,
  validatePaperAnnotations,
  Action,
  changePassword
};

export default exportedApiFunctions;
