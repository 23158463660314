import axios from "axios";
import { LOCATION } from "../api";
import {
  GroupContent,
  IcdContentList,
  OutcomeContent,
  PublicationReferences,
  PublicationRegistryEntries,
  SingleConfirmableContent,
} from "../model/publicationdata/PublicationData";

export async function getPublicationData(paperId: string) {
  let url = LOCATION + `publications/data/${paperId}`;

  const response = await axios.get(url);
  return response;
}

export async function getRegistryPublicationData() {
  let url = LOCATION + `publications/data/registry-entries/all/0/100`;

  const response = await axios.get(url);
  return response;
}

export async function getRegistryPublicationDataWithResults() {
  let url =
    LOCATION + `publications/data/registry-entries/all-with-results/0/100`;

  const response = await axios.get(url);
  return response;
}

export async function searchRegistryPublicationData(searchString: string) {
  let url =
    LOCATION + `publications/search/registry-entries/simple/${searchString}`;

  const response = await axios.get(url);
  return response;
}

export async function searchRegistryPublicationDataCount(searchString: string) {
  let url =
    LOCATION +
    `publications/search/registry-entries/simple/count/${searchString}`;

  const response = await axios.get(url);
  return response;
}

export async function searchRegistrySummary(searchString: string) {
  let url =
    LOCATION + `publications/search/registry-entries/summary/${searchString}`;

  const response = await axios.get(url);
  return response;
}

export async function updateVariableContent(
  variableName: string,
  paperId: string,
  content: SingleConfirmableContent
) {
  let url =
    LOCATION + `publications/data/simple-content/${variableName}/${paperId}`;

  const response = await axios.put(url, content);
  return response;
}

export async function updateIcd10Values(
  paperId: string,
  content: IcdContentList
) {
  let url = LOCATION + `publications/data/icd10values/${paperId}`;

  const response = await axios.put(url, content);
  return response;
}

export async function updateIcd11Values(
  paperId: string,
  content: IcdContentList
) {
  let url = LOCATION + `publications/data/icd11values/${paperId}`;

  const response = await axios.put(url, content);
  return response;
}

export async function updateGroupContent(
  paperId: string,
  content: GroupContent
) {
  let url = LOCATION + `publications/data/groups/${paperId}`;

  const response = await axios.put(url, content);
  return response;
}

export async function updateReferencesContent(
  paperId: string,
  content: PublicationReferences
) {
  let url = LOCATION + `publications/data/publication-references/${paperId}`;

  const response = await axios.put(url, content);
  return response;
}

export async function updateRegistriesContent(
  paperId: string,
  content: PublicationRegistryEntries
) {
  let url = LOCATION + `publications/data/registry-entries/${paperId}`;

  const response = await axios.put(url, content);
  return response;
}

export async function updateOutcomeContent(
  outcomeContentType: string,
  paperId: string,
  content: OutcomeContent
) {
  let url =
    LOCATION + `publications/data/outcome/${outcomeContentType}/${paperId}`;

  const response = await axios.put(url, content);
  return response;
}

const exportedPublicationDataApiFunctions = {
  getPublicationData,
  getRegistryPublicationData,
  getRegistryPublicationDataWithResults,
  searchRegistryPublicationData,
  searchRegistryPublicationDataCount,
  searchRegistrySummary,
  updateVariableContent,
  updateGroupContent,
  updateIcd10Values,
  updateIcd11Values,
  updateReferencesContent,
  updateRegistriesContent,
  updateOutcomeContent,
};
export default exportedPublicationDataApiFunctions;
