export const AnnotationOutcomeContentType: any = {
  MEASURE: {
    type: "MEASURE",
    annotationText: "measure$",
    chapter: "4",
    couldBeMultipleEntities: true,
    hasRange: false,
  },
  MEASURE_TYPE: {
    type: "MEASURE_TYPE",
    annotationText: "measure.type$",
    chapter: "4",
    couldBeMultipleEntities: true,
    hasRange: false,
  },
  OUTCOME_NAME: {
    type: "OUTCOME_NAME",
    annotationText: "outcome",
    chapter: "4",
    couldBeMultipleEntities: true,
    hasRange: false,
  },
  PRIMARY: {
    type: "PRIMARY",
    annotationText: "prim",
    chapter: "4",
    couldBeMultipleEntities: false,
    hasRange: false,
  },
  SECONDARY: {
    type: "SECONDARY",
    annotationText: "sec",
    chapter: "4",
    couldBeMultipleEntities: false,
    hasRange: false,
  },
  TERTIARY: {
    type: "TERTIARY",
    annotationText: "ter",
    chapter: "4",
    couldBeMultipleEntities: false,
    hasRange: false,
  },
  CONTROL: {
    type: "CONTROL",
    annotationText: "control",
    chapter: "6",
    couldBeMultipleEntities: false,
    hasRange: false,
  },
  ADVERSE: {
    type: "ADVERSE",
    annotationText: "adverse",
    chapter: "7",
    couldBeMultipleEntities: false,
    hasRange: false,
  },
  SPECIFICATION: {
    type: "SPECIFICATION",
    annotationText: "spec",
    chapter: "4",
    couldBeMultipleEntities: true,
    hasRange: false,
  },
  TIME: {
    type: "TIME",
    annotationText: "time",
    chapter: "4",
    couldBeMultipleEntities: true,
    hasRange: false,
  },
  RESULT: {
    type: "RESULT",
    annotationText: "result",
    chapter: "4",
    couldBeMultipleEntities: true,
    hasRange: false,
  },
  ERROR: {
    type: "ERROR",
    annotationText: "",
    chapter: "4",
    couldBeMultipleEntities: false,
    hasRange: false,
  },
  SD: {
    type: "SD",
    annotationText: "sd",
    chapter: "4",
    couldBeMultipleEntities: false,
    hasRange: true,
  },
  IQR: {
    type: "IQR",
    annotationText: "IQR",
    chapter: "4",
    couldBeMultipleEntities: false,
    hasRange: true,
  },
  CI95: {
    type: "CI95",
    annotationText: "95CI",
    chapter: "4",
    couldBeMultipleEntities: false,
    hasRange: true,
  },
  PVALUE: {
    type: "PVALUE",
    annotationText: "pvalue",
    chapter: "4",
    couldBeMultipleEntities: false,
    hasRange: false,
  },
};

export function byOutcomeContentType(inType: string): any {
  for (let type in AnnotationOutcomeContentType) {
    if (AnnotationOutcomeContentType[type].type === inType) {
      return AnnotationOutcomeContentType[type];
    }
  }
  return null;
}
