import React from "react";
import { URLFields } from "../../../URLs";
import LaunchIcon from "@mui/icons-material/Launch";
import IconButton from "@mui/material/IconButton";

export const FieldLink = ({ field, value }) => {
  if (!["doi", "url", "pubmedId"].includes(field)) return null;
  const handleOnClick = () => {
    let url;
    if (["doi", "pubmedId"].includes(field)) {
      url = URLFields[field];
      url += `/${value}`;
    } else if (field === "url") {
      url = value;
      if (!url.includes("http")) {
        url = "http://" + url;
      }
    }

    window.open(url);
  };
  return (
    field &&
    value && (
      <IconButton
        size="small"
        disableRipple
        onClick={handleOnClick}
        disabled={value === ""}
        color="primary"
      >
        <LaunchIcon style={{ fontSize: 18 }} />
      </IconButton>
    )
  );
};

export default FieldLink;
