import axios from "axios";
import { LOCATION } from "../api";
import {
  Annotation,
  BasicRsAnnotation,
  ExtractedAnnotation,
} from "../model/annotation/Annotation";

const LOCATION_ANNOTATION = LOCATION + "paper/annotations";

export async function createNewAnnotation(
  annotation: Annotation,
  paperId: string,
  subUrl: string
) {
  var status = 200;

  if (annotation != null) {
    let url = LOCATION_ANNOTATION + "/" + subUrl + "/single/" + paperId;

    axios
      .post(url, annotation)
      .then((r) => {
        status = r.status;
      })
      .catch((e) => console.log("save error: " + e));
  }

  return status;
}

export async function createIcdOrRegistryAnnotation(
  annotation: BasicRsAnnotation,
  paperId: string,
  subUrl: string
) {
  var status = 200;

  if (annotation != null) {
    let url = LOCATION_ANNOTATION + "/" + subUrl + "/single/" + paperId;

    axios
      .post(url, annotation)
      .then((r) => {
        status = r.status;
      })
      .catch((e) => console.log("Update error: " + e));
  }

  return status;
}

export async function updateIcdOrRegistryAnnotation(
  annotation: BasicRsAnnotation,
  paperId: string,
  subUrl: string
) {
  var status = 200;

  if (annotation != null) {
    let url = LOCATION_ANNOTATION + "/" + subUrl + "/single/" + paperId;

    axios
      .put(url, annotation)
      .then((r) => {
        status = r.status;
      })
      .catch((e) => console.log("Update error: " + e));
  }

  return status;
}

export async function updateAnnotation(
  annotation: Annotation,
  paperId: string,
  subUrl: string
) {
  var status = 200;

  if (annotation != null) {
    let url = LOCATION_ANNOTATION + "/" + subUrl + "/single/" + paperId;

    axios
      .put(url, annotation)
      .then((r) => {
        status = r.status;
      })
      .catch((e) => console.log("Update error: " + e));
  }

  return status;
}

export async function updateExtractedAnnotationToPaper(
  annotation: ExtractedAnnotation,
  paperId: string
) {
  let url = LOCATION_ANNOTATION + "/extracted/single/" + paperId;

  const response = await axios.put(url, annotation);
  return response;
}

export async function getExtractedAnnotationImagesForPaper(
  parentPath: string,
  filename: string
) {
  return (
    LOCATION + `paper/annotations/extracted/image/${parentPath}/${filename}`
  );
}

export async function updateIcdOrRegistryAnnotationList(
  annotation: Array<BasicRsAnnotation>,
  paperId: string,
  subUrl: string
) {
  var status = 200;

  if (annotation != null) {
    let url = LOCATION_ANNOTATION + "/" + subUrl + "/multiple/" + paperId;

    axios
      .put(url, annotation)
      .then((r) => {
        status = r.status;
      })
      .catch((e) => console.log("Update error: " + e));
  }

  return status;
}

export async function deleteAnnotation(
  annotation: Annotation,
  paperId: string,
  subUrl: string
) {
  var status = 200;

  if (annotation != null) {
    let url =
      LOCATION_ANNOTATION + "/" +
      subUrl +
      "/single/" +
      paperId +
      "/" +
      annotation.index;

    axios
      .delete(url)
      .then((r) => {
        status = r.status;
      })
      .catch((e) => {
        console.log("save error: " + e);
      });
  }

  return status;
}

export async function deleteAllAnnotationsForPaper(paperId: string) {
  var status = 200;

  if (paperId != null) {
    let url = LOCATION_ANNOTATION + "/annotations/" + paperId;

    axios
      .delete(url)
      .then((r) => {
        status = r.status;
      })
      .catch((e) => {
        console.log("save error: " + e);
      });
  }

  return status;
}

export async function fetchContentAnnotationsForRecord(paperId: string) {
  let url = LOCATION_ANNOTATION + "/content/" + paperId;
  const config = {
    url: url,
    timeout: 5000,
  };

  const response = await axios.get(url, config);
  return response.data;
}

export async function fetchIcdAnnotationsForRecord(paperId: string) {
  let url = LOCATION_ANNOTATION + "/icd/" + paperId;

  const response = await axios.get(url);
  return response.data;
}

export async function fetchRegistryAnnotationsForRecord(paperId: string) {
  let url = LOCATION_ANNOTATION + "/registry/" + paperId;

  const response = await axios.get(url);
  return response.data;
}

const exportedAnnotationApiFunctions = {
  deleteAllAnnotationsForPaper,
  createIcdOrRegistryAnnotation,
  createNewAnnotation,
  deleteAnnotation,
  fetchContentAnnotationsForRecord,
  fetchIcdAnnotationsForRecord,
  fetchRegistryAnnotationsForRecord,
  getExtractedAnnotationImagesForPaper,
  updateAnnotation,
  updateExtractedAnnotationToPaper,
};
export default exportedAnnotationApiFunctions;
