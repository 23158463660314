import { Collapse, ListItem, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import React from "react";
import PdfViewer from "./PdfViewer";

function CollapsablePdfViewer({ state, currentRecord }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <ListItem disableRipple disableGutters button onClick={handleClick}>
        <ListItemText primary={"Full Text"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <PdfViewer
          state={state}
          currentRecord={currentRecord}
          //   key={updatePdfViewer}
        />
      </Collapse>
    </div>
  );
}

export default CollapsablePdfViewer;
