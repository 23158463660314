import React from "react";
import api from "../../../api";
import { WorkflowStates } from "../../../model/WorkflowStates";

function PdfViewer({ state, currentRecord }) {
  const base_url = api.getPaperUrl();
  const file = getFile(state, currentRecord);

  if (!file) {
    return null;
  }

  const url =
    base_url + "/" + file + "/" + currentRecord.id + "#pagemode=none&view=FitH";

  // iframe seems to interfere with react-router and corrupts somehow history
  // const urlDownload = base_url + "/" + file + "/" + currentRecord.id;

  return (
    <div>
      <object
        data={url}
        type="application/pdf"
        style={{ width: "100%", height: "800px", paddingLeft: 10 }}
      >
        {/* <iframe
            title="pdf"
            src={url}
            style={{ width: "100%", height: "100%", border: "0" }}
          >
            <p>
              Your browser does not support PDFs.
              <a href={urlDownload}>Download the PDF</a>.
            </p>
          </iframe> */}
      </object>
    </div>
  );
}

function getFile(state, currentRecord) {
  var file;

  if (state !== WorkflowStates.TO_BE_DISCUSSED.state) {
    file =
      currentRecord.filenameUploadedAnnotated !== null &&
      state !== WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.state
        ? "annotatedFileView"
        : "emptyFileView";
  } else {
    if (
      currentRecord.filenameUploadedAnnotated != null &&
      currentRecord.previousWorkflowState !==
        WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.state
    ) {
      file = "annotatedFileView";
      console.log("anno");
    } else if (currentRecord.filenameUploaded != null) {
      file = "emptyFileView";
      console.log("empty");
    } else {
      console.log("no pdf");
      return null;
    }
  }

  return file;
}

export default PdfViewer;
