import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../api";
import exportApi from "../../api/exportApi";
import { RecordActionsProperties } from "../../model/ViewProperties";
import { WorkflowStates } from "../../model/WorkflowStates";
import { getPaperName } from "../../utils/utils";
import { ActionButton } from "../subcomponents/buttons/ActionButton";
import { successSnackbarOptions } from "../subcomponents/notification/notifications";
import AreaSelect from "../subcomponents/selectors/AreaSelect";
import TableWithActions from "../subcomponents/tables/TableWithActions";
import ViewTableLayout from "./layouts/ViewTableLayout";

// for testing new views: basic view with table and forward and backward functionality

function ViewDummyTable({ state }) {
  const [currentAreaId, setCurrentAreaId] = useState("empty");
  const [recordList, setRecordList] = useState([]);
  const { showForward } = RecordActionsProperties[state];
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (currentAreaId !== "empty") {
      api
        .fetchRecords(state, currentAreaId, "fromArea")
        .then((recList) => {
          setRecordList(recList);
        })
        .catch((e) => console.log("processing error: " + e));
    }

    return () => {
      setRecordList([]);
      setCurrentAreaId("empty");
    };
  }, [state, currentAreaId]);

  const handleCurrentAreaId = useCallback((areaId) => {
    setCurrentAreaId(areaId);
  }, []);

  const updateRecord = (record) => {
    const idx = recordList.findIndex(({ id }) => id === record.id);
    let listCopy = [...recordList];
    listCopy[idx] = record;
    setRecordList(listCopy);
    console.log("updated");
  };

  const handleForwardBackward = (idx, action) => {
    if (action === api.Action.FORWARD) {
      api
        .moveRecord(recordList[idx], action)
        .then((data) => {
          updateRecord(data);
          enqueueSnackbar(
            "Record successfully forward moved",
            successSnackbarOptions
          );
        })
        .catch((e) => console.log("move forward error: " + e));
    } else if (action === api.Action.BACKWARD) {
      api
        .moveRecord(recordList[idx], action)
        .then((data) => {
          updateRecord(data);
          enqueueSnackbar(
            "Record successfully backward moved",
            successSnackbarOptions
          );
        })
        .catch((e) => console.log("move backward error: " + e));
    } else {
      console.log("No valid action");
    }
  };

  const rows = createRows(recordList);

  if (state === WorkflowStates.RELEASED.state) {
    var exportButton = (<ActionButton
      text="Export all in database"
      captionText=""
      disabled={false}
      handleClick={() => {
        exportApi
          .exportAll()
          .then((response) => {
            // ugly solution but downloading and setting the state of a paper
            // does not work better
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              response.headers["content-disposition"].split("filename=")[1]
            );
            document.body.appendChild(link);
            link.click();
            // updateRecord(data);
          })
          .catch((e) => console.log("download error: " + e));
      }}
      color="primary"
    />);
  }

  return (
    <ViewTableLayout
      exportButton={exportButton && <Box m={4}>{exportButton}</Box>}
      select={
        <AreaSelect
          state={state}
          handleCurrentAreaId={handleCurrentAreaId}
          //   refresh
        />
      }
      table={
        <TableWithActions
          rows={rows}
          handleForwardBackward={handleForwardBackward}
          state={state}
          showForward={showForward}
        />
      }
    />
  );
}

function createRow(record) {
  return {
    name: getPaperName(record),
    pmid: record.pubmedId,
    state: record.workflowState,
  };
}

function createRows(recordList) {
  let rows = [];
  if (recordList.length > 0) {
    rows = recordList.map((item, idx) => {
      return createRow(item);
    });
  }
  return rows;
}

export default ViewDummyTable;
