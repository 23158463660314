import { Box, Tab, Tabs, Toolbar } from "@mui/material";
import React from "react";
import { WorkflowStates } from "../../../model/WorkflowStates";
import { HighlightedField } from "./HighlightedField";
import RecordField from "./RecordField";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default function FieldTab({
  currentRecord,
  idx,
  field,
  onChange,
  disabled,
  reset,
  fieldToSearch,
  name,
  helperText,
  helperTextColor,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let disableEditTab = [
    WorkflowStates.INCOMING.state,
    WorkflowStates.INCOMING_NOT_FOUND.state,
  ].includes(currentRecord.workflowState);

  return (
    <div>
      <Toolbar position="static" variant="dense" disableGutters>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
        >
          <Tab disableRipple label={name} {...a11yProps(0)} />
          <Tab
            disabled={disableEditTab}
            disableRipple
            label="Edit"
            {...a11yProps(1)}
          />
        </Tabs>
      </Toolbar>
      <TabPanel value={value} index={0}>
        <>
          <HighlightedField
            text={currentRecord[field]}
            detectionResults={
              currentRecord.detectionResults
                ? currentRecord.detectionResults[fieldToSearch]
                : []
            }
            field={field}
          ></HighlightedField>
          <HelperTextComponent
            helperText={helperText}
            helperTextColor={helperTextColor}
          />
        </>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RecordField
          index={idx}
          key={idx + currentRecord.id}
          value={currentRecord[field] ? currentRecord[field].toString() : ""}
          field={field}
          onChange={onChange}
          reset={reset}
          disabled={disabled}
          helperText={helperText}
        />
      </TabPanel>
    </div>
  );
}

const HelperTextComponent = ({ helperText, helperTextColor }) => {
  if (!helperText) return null;
  return <div style={{ color: helperTextColor }}>{helperText}</div>;
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
