import { Box, Checkbox, Chip, Typography } from "@mui/material";
import React from "react";
import { UserConfirmationState } from "../../../../model/annotation/UserConfirmationState";
import {
  getConfStateByWorkflowState,
  setConfStateByWorkflowState,
} from "../../../../utils/validation/confirmationByStateUtils";
import { overWriteCheckboxDisable } from "../../../../utils/validation/ValidationWorkflowStateUtils";
import { BasicContentComponent } from "./BasicContentComponent";
import { PublicationContentComponent } from "./PublicationContentComponent";
import ValidationWarningBox from "./ValidationWarningBox";

export const GroupsComponent = ({
  publicationData,
  saveGroups,
  currentWorkflowState,
}) => {
  const onSaveItem = (groupContent, contentType) => {
    // switch to groupName now that we have it
    let idx = publicationData.groups.findIndex(
      (x) =>
        x.groupNameContent.variableName === groupContent.variableName ||
        x.sampleSizeContent.variableName === groupContent.variableName
    );
    // TODO: idx = -1 treatment

    let groups = publicationData.groups;
    groups[idx][contentType] = groupContent;

    let userConf = getGlobalUserConfigurationByState(groups[idx]);

    setConfStateByWorkflowState(
      groups[idx].userConfirmationByState,
      currentWorkflowState,
      userConf
    );

    saveGroups(groups);
  };

  const onValidate = (userConfirmationByState, groupName) => {
    let idx = publicationData.groups.findIndex(
      (x) => x.groupName === groupName
    );
    // TODO: idx = -1 treatment
    let groups = publicationData.groups;
    groups[idx].userConfirmationByState = userConfirmationByState;
    saveGroups(groups);
  };

  // console.log(publicationData.groups);

  return (
    <div>
      <Box display="flex" flexDirection="row">
        <Typography variant="h5">3. Groups</Typography>
      </Box>
      {publicationData.groups.map((g, idx) => {
        return (
          <React.Fragment key={idx}>
            {/* this should have another key, idx is not good practice  */}
            <Box display="flex">
              <GroupComponent
                group={g}
                onSaveItem={onSaveItem}
                onValidate={onValidate}
                currentWorkflowState={currentWorkflowState}
              />
            </Box>
          </React.Fragment>
        );
      })}
    </div>
  );
};

const getGlobalUserConfigurationByState = (group) => {
  let userConf = UserConfirmationState.DEFAULT.name;

  if (
    (group.groupNameContent !== null &&
      group.groupNameContent.content !==
        group.groupNameContent.contentOriginal) ||
    (group.sampleSizeContent !== null &&
      group.sampleSizeContent.content !==
        group.sampleSizeContent.contentOriginal)
  ) {
    userConf = UserConfirmationState.CHANGED.name;
  }

  return userConf;
};

const GroupComponent = ({
  group,
  onSaveItem,
  onValidate,
  currentWorkflowState,
}) => {
  const {
    controlGroup,
    groupName,
    groupNameContent,
    sampleSizeContent,
    userConfirmationByState,
    validationWarnings,
  } = group;

  const [disableCheckbox, setDisableCheckbox] = React.useState(false);

  const handleChangeCheckBox = (e) => {
    let check;

    if (e.target.checked) {
      check = UserConfirmationState.ACCEPTED.name;
    } else {
      check = UserConfirmationState.DEFAULT.name;
    }

    setConfStateByWorkflowState(
      userConfirmationByState,
      currentWorkflowState,
      check
    );
    onValidate(userConfirmationByState, groupName);
  };

  const handleDisableCheckbox = (disable) => {
    setDisableCheckbox(disable);
  };

  const overwriteCheckboxDisable =
    overWriteCheckboxDisable(currentWorkflowState);

  const disableTextField =
    getConfStateByWorkflowState(
      userConfirmationByState,
      currentWorkflowState
    ) === UserConfirmationState.ACCEPTED.name;

  return (
    <div>
      <Box display="flex" flexDirection="column">
        {groupNameContent?.variableName && (
          <Box
            ml={10}
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <Box>
              <Chip
                size="small"
                label={groupNameContent.variableName}
                variant="outlined"
              />
            </Box>
            <Box ml={4}>
              <Typography align="center">Validate</Typography>
            </Box>
            <Box>
              <Checkbox
                checked={
                  getConfStateByWorkflowState(
                    userConfirmationByState,
                    currentWorkflowState
                  ) === UserConfirmationState.ACCEPTED.name
                }
                onChange={handleChangeCheckBox}
                disabled={
                  (disableCheckbox ||
                    getGlobalUserConfigurationByState(group) ===
                      UserConfirmationState.CHANGED.name) &&
                  !overwriteCheckboxDisable
                }
              />
            </Box>
          </Box>
        )}
        {groupNameContent && (
          <PublicationContentComponent
            content={groupNameContent}
            onSaveItem={onSaveItem}
            contentType={"groupNameContent"}
            disableTextField={disableTextField}
            handleDisableCheckbox={handleDisableCheckbox}
          />
        )}
        {sampleSizeContent && (
          <PublicationContentComponent
            content={sampleSizeContent}
            onSaveItem={onSaveItem}
            contentType={"sampleSizeContent"}
            disableTextField={disableTextField}
            handleDisableCheckbox={handleDisableCheckbox}
          />
        )}
        <BasicContentComponent
          content={controlGroup.toString()}
          name={"Control Group"}
          marginRight={20}
          minWidth={100}
          textWidth={70}
        />

        <Box>{ValidationWarningBox("2", validationWarnings, 2)}</Box>
      </Box>
    </div>
  );
};
