import React, { Component } from "react";

import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";

import { GroupNames } from "../../../model/annotation/GroupNames";
import { AnnotationBaseContentType } from "../../../model/annotation/AnnotationBaseContentType";

import { createAnnotationText } from "../../../utils/annotation/utils";
import { AnnotationType } from "../../../model/annotation/AnnotationType";
import { OutcomeAnnotation } from "../../../model/annotation/outcome/OutcomeAnnotation";

interface Props {
  onConfirm: (
    comment: { text: string; count: string },
    chapter: string,
    annotationType: string,
    outcomeAnnotation: OutcomeAnnotation | null
  ) => void;
}

interface State {
  count: string;
  variable: string;
  groupId: string;
  isCustom: boolean;
  customText: string;
  // annoVariable: AnnoVariable;
}

export class BaseAnnotationTip extends Component<Props, State> {
  state: State = {
    count: "0",
    groupId: "A",
    variable: "",
    isCustom: false,
    customText: "",
  };

  handleOnChangeRadio = (event: any, isCustom: boolean) => {
    this.setState({
      variable: event.target.value,
      isCustom: isCustom,
    });
  };

  render() {
    const { isCustom, count, groupId, variable, customText } = this.state;

    const GRID_WIDTH_TEXT_FIELD = 6;
    const GRID_WIDTH_COUNT_LABEL_FIELD = 1;
    const GRID_WIDTH_COUNT_FIELD = 2;
    const GRID_WIDTH_GROUPID_FIELD = 3;
    return (
      <form
        style={{ width: "350px" }}
        className="Tip__card"
        onSubmit={(event) => {
          event.preventDefault();
          const annotationData: string[] = createAnnotationText(
            isCustom,
            variable,
            groupId,
            count,
            customText
          );
          this.props.onConfirm(
            {
              text: annotationData[0],
              count: count,
            },
            annotationData[1],
            AnnotationType.CONTENT.type,
            null
          );
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
            <Radio
              checked={variable === AnnotationBaseContentType.DESIGN.type}
              onChange={(e) => this.handleOnChangeRadio(e, false)}
              value={AnnotationBaseContentType.DESIGN.type}
              size="small"
            />
            Design
          </Grid>
          <Grid item xs={GRID_WIDTH_GROUPID_FIELD}></Grid>
          <Grid item xs={GRID_WIDTH_COUNT_LABEL_FIELD}>
            <Typography>ID</Typography>
          </Grid>
          <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
            <select
              value={count.toString()}
              disabled={variable !== AnnotationBaseContentType.DESIGN.type}
              onChange={(e) => this.setState({ count: e.target.value })}
            >
              <option key={1} value={1}>
                1
              </option>
              <option key={2} value={2}>
                2
              </option>
              <option key={3} value={3}>
                3
              </option>
            </select>
          </Grid>
          <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
            <Radio
              checked={variable === AnnotationBaseContentType.SUBJECTS.type}
              onChange={(e) => this.handleOnChangeRadio(e, false)}
              value={AnnotationBaseContentType.SUBJECTS.type}
              size="small"
            />
            Subjects
          </Grid>
          <Grid item xs={GRID_WIDTH_GROUPID_FIELD}></Grid>
          <Grid item xs={GRID_WIDTH_COUNT_LABEL_FIELD}>
            <Typography>ID</Typography>
          </Grid>
          <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
            <select
              value={count.toString()}
              disabled={variable !== AnnotationBaseContentType.SUBJECTS.type}
              onChange={(e) => this.setState({ count: e.target.value })}
            >
              <option key={1} value={1}>
                1
              </option>
              <option key={2} value={2}>
                2
              </option>
              <option key={3} value={3}>
                3
              </option>
            </select>
          </Grid>
          <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
            <Radio
              checked={variable === AnnotationBaseContentType.CONDITION.type}
              onChange={(e) => this.handleOnChangeRadio(e, false)}
              value={AnnotationBaseContentType.CONDITION.type}
              size="small"
            />
            Condition
          </Grid>
          <Grid item xs={GRID_WIDTH_GROUPID_FIELD}></Grid>
          <Grid item xs={GRID_WIDTH_COUNT_LABEL_FIELD}>
            <Typography>ID</Typography>
          </Grid>
          <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
            <select
              value={count.toString()}
              disabled={variable !== AnnotationBaseContentType.CONDITION.type}
              onChange={(e) => this.setState({ count: e.target.value })}
            >
              <option key={1} value={1}>
                1
              </option>
              <option key={2} value={2}>
                2
              </option>
              <option key={3} value={3}>
                3
              </option>
            </select>
          </Grid>
          <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
            <Radio
              checked={variable === AnnotationBaseContentType.GROUP_NAME.type}
              onChange={(e) => this.handleOnChangeRadio(e, false)}
              value={AnnotationBaseContentType.GROUP_NAME.type}
              size="small"
            />
            Group
          </Grid>
          <Grid item xs={GRID_WIDTH_GROUPID_FIELD}>
            <select
              value={groupId}
              disabled={variable !== AnnotationBaseContentType.GROUP_NAME.type}
              onChange={(e) => this.setState({ groupId: e.target.value })}
            >
              {GroupNames.map((value) => {
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xs={GRID_WIDTH_COUNT_LABEL_FIELD}>
            <Typography>ID</Typography>
          </Grid>
          <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
            <select
              value={count.toString()}
              disabled={variable !== AnnotationBaseContentType.GROUP_NAME.type}
              onChange={(e) => this.setState({ count: e.target.value })}
            >
              <option key={1} value={1}>
                1
              </option>
              <option key={2} value={2}>
                2
              </option>
              <option key={3} value={3}>
                3
              </option>
            </select>
          </Grid>
          <Grid item xs={GRID_WIDTH_TEXT_FIELD}>
            <Radio
              checked={variable === AnnotationBaseContentType.GROUP_N.type}
              onChange={(e) => this.handleOnChangeRadio(e, false)}
              value={AnnotationBaseContentType.GROUP_N.type}
              size="small"
            />
            N
          </Grid>
          <Grid item xs={GRID_WIDTH_GROUPID_FIELD}>
            <select
              value={groupId}
              disabled={variable !== AnnotationBaseContentType.GROUP_N.type}
              onChange={(e) => this.setState({ groupId: e.target.value })}
            >
              {GroupNames.map((value) => {
                return (
                  <option key={value} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item xs={GRID_WIDTH_COUNT_LABEL_FIELD}>
            <Typography>ID</Typography>
          </Grid>
          <Grid item xs={GRID_WIDTH_COUNT_FIELD}>
            <select
              value={count.toString()}
              disabled={variable !== AnnotationBaseContentType.GROUP_N.type}
              onChange={(e) => this.setState({ count: e.target.value })}
            >
              <option key={1} value={1}>
                1
              </option>
              <option key={2} value={2}>
                2
              </option>
              <option key={3} value={3}>
                3
              </option>
            </select>
          </Grid>
          <Grid item xs={4}>
            <Radio
              checked={variable === AnnotationBaseContentType.CUSTOM.type}
              onChange={(e) => this.handleOnChangeRadio(e, true)}
              value={AnnotationBaseContentType.CUSTOM.type}
              size="small"
            />
            Custom
          </Grid>
          <Grid item xs={8}>
            <input
              type="text"
              value={customText}
              onChange={(e) => this.setState({ customText: e.target.value })}
              disabled={variable !== AnnotationBaseContentType.CUSTOM.type}
            />
          </Grid>
        </Grid>
        <p></p>
        <div>
          <input type="submit" value="Save" />
        </div>
      </form>
    );
  }
}
