import {
  IcdHighlight,
  IHighlight,
  NewHighlight,
  RegistryHighlight,
  Scaled,
  ScaledPosition,
  T_PDFJS_Viewer,
  Viewport,
} from "../../libs/react-pdf-highlighter";
import {
  Annotation,
  AnnotationRectangleProperties,
  PaperAnnotations,
  PublicationIcdAnnotations,
  RegistryEntriesForPublicationAnnotations,
} from "../../model/annotation/Annotation";
import { byAnnotationWriterType } from "../../model/annotation/AnnotationWriterType";
import { pdfPageCoordinates } from "./uiUtils";

/**
 * Converts API annotations to UI annotations which is necessary as the view has other coordinates
 */

/**
 * Converts a single annotation from REST to highlight
 *
 * @param highlight highlight to convert
 * @returns annotation for REST
 */
export function convertPdfHighlightingToRsAnnotation(
  highlight: NewHighlight,
  index: Number,
  annotationWriterType: any
) {
  const convertedHighlight: NewHighlight =
    convertPdfViewerCoordinatesToPdfCoordinates(highlight);
  const annotation: Annotation = {
    boundingRectangle: convertedHighlight.position.boundingRect,
    rectangles: convertedHighlight.position.rects,
    pageNumber: convertedHighlight.position.pageNumber,
    annotationText: convertedHighlight.comment.text,
    annotatedContent: convertedHighlight.content.text,
    index: index,
    rotation: convertedHighlight.position.rotation,
    annotationWriterType: annotationWriterType.type,
    pageOffsetX: convertedHighlight.position.currentOffsetX,
    pageOffsetY: convertedHighlight.position.currentOffsetY,
    pageWidth: highlight.position.pdfPageCoordinates.width,
    pageHeight: highlight.position.pdfPageCoordinates.height,
    type: highlight.annotationType,
    ...convertedHighlight,
  };

  return annotation;
}

/**
 * Converts the Paper annotation from the REST service to a highlight array
 *
 * IDs are generated here so they are unique in the UI
 *
 * @param annotations paper annotations from REST
 * @param viewer PDF viewer to get the coordinates in the viewn PDF file
 * @returns the highlight array
 */
export function convertExractedRsPaperAnnotationsToPdfHighlightings(
  annotations: PaperAnnotations,
  viewer: T_PDFJS_Viewer
): Array<IHighlight> {
  if (annotations.extractedAnnotations != null) {
    return annotations.extractedAnnotations.map((extracted) => {
      const highlight: IHighlight = convertRsAnnotationToIHighlight(
        extracted.annotation,
        viewer
      );
      return {
        userConfirmationState: extracted.userConfirmationState,
        ...highlight,
      };
    });
  }

  return new Array<IHighlight>(0);
}

export function convertAnnotatedRsPaperAnnotationsToPdfHighlightings(
  annotations: PaperAnnotations,
  viewer: T_PDFJS_Viewer
): Array<IHighlight> {
  let convertedHighlights: Array<IHighlight> = new Array<IHighlight>();
  if (
    annotations.annotations != null ||
    annotations.emptyAnnotations !== null
  ) {
    convertedHighlights.push(
      ...annotations.annotations.map((annotation) => {
        return convertRsAnnotationToIHighlight(annotation, viewer);
      })
    );
  }
  if (annotations.emptyAnnotations !== null) {
    convertedHighlights.push(
      ...annotations.emptyAnnotations.map((annotation) => {
        return convertRsAnnotationToIHighlight(annotation, viewer);
      })
    );
  }

  return convertedHighlights;
}

export function convertRegistryAnnotationsToPdfHighlightings(
  registryAnnotations: RegistryEntriesForPublicationAnnotations,
  viewer: T_PDFJS_Viewer
): Array<RegistryHighlight> {
  const highlights = registryAnnotations.registryEntries
    .filter((annotation) => {
      // filter away Pubmed values
      return annotation.annotation !== null;
    })
    .map((annotation) => {
      const highlight: IHighlight = convertRsAnnotationToIHighlight(
        annotation.annotation,
        viewer
      );
      return {
        registryType: annotation.registryType,
        importDataSource: annotation.importDataSource,
        userConfirmationState: annotation.userConfirmationState,
        registryId: annotation.registryId,
        ...highlight,
      };
    });

  return highlights;
}

export function convertIcdAnnotationsToPdfHighlightings(
  publicationIcdAnnotations: PublicationIcdAnnotations,
  viewer: T_PDFJS_Viewer
): Array<IcdHighlight> {
  const highlights = publicationIcdAnnotations.annotations
    .filter((annotation) => {
      return annotation !== null;
    })
    .map((annotation) => {
      const highlight: IHighlight = convertRsAnnotationToIHighlight(
        annotation.annotation,
        viewer
      );
      return {
        icdAnnotationType: annotation.icdAnnotationType,
        icdEntry: annotation.icdEntry,
        expression: annotation.expression,
        userConfirmationState: annotation.userConfirmationState,
        ...highlight,
      };
    });

  return highlights;
}

function convertRectangleToPdfCoordinates(
  rectangle: Scaled,
  scaledPosition: ScaledPosition
): Scaled {
  if (
    scaledPosition.currentOffsetX !== 0.0 ||
    scaledPosition.currentOffsetY !== 0.0
  ) {
    console.log("Hello");
  }

  if (scaledPosition.currentRotation === 90 || scaledPosition.rotation) {
    const x1 = rectangle.y1 / scaledPosition.currentViewportScale;
    const x2 = rectangle.y2 / scaledPosition.currentViewportScale;
    const y1 = rectangle.x1 / scaledPosition.currentViewportScale;
    const y2 = rectangle.x2 / scaledPosition.currentViewportScale;
    return {
      x1: x1,
      x2: x2,
      // inverted due to inverted scale
      y1: y1,
      y2: y2,
      // The viewer needs this somehow :-|
      width: x2 - x1,
      height: y2 - y1,

      pageNumber: rectangle.pageNumber,
    };
  }

  const x1 = rectangle.x1 / scaledPosition.currentViewportScale;
  const x2 = rectangle.x2 / scaledPosition.currentViewportScale;
  // inverted due to inverted scale
  const y1 =
    scaledPosition.pdfPageCoordinates.height -
    rectangle.y2 / scaledPosition.currentViewportScale;
  const y2 =
    scaledPosition.pdfPageCoordinates.height -
    rectangle.y1 / scaledPosition.currentViewportScale;
  return {
    x1: x1,
    x2: x2,
    // inverted due to inverted scale
    y1: y1,
    y2: y2,
    // here we store the rectangle width, the other one will be restored in the other way round function
    width: x2 - x1,
    height: y2 - y1,
    pageNumber: rectangle.pageNumber,
  };
}

function convertPdfViewerCoordinatesToPdfCoordinates(
  highlight: NewHighlight
): NewHighlight {
  let convertedBoundingRect: Scaled;
  let convertedRects: Array<Scaled>;
  convertedBoundingRect = convertRectangleToPdfCoordinates(
    highlight.position.boundingRect,
    highlight.position
  );
  convertedRects = highlight.position.rects.map((rect) => {
    return convertRectangleToPdfCoordinates(rect, highlight.position);
  });
  return {
    position: {
      boundingRect: convertedBoundingRect,
      rects: convertedRects,
      rotation: highlight.position.rotation,
      pageNumber: highlight.position.pageNumber,
      usePdfCoordinates: highlight.position.usePdfCoordinates,
      currentViewportScale: highlight.position.currentViewportScale,
      currentOffsetX: highlight.position.currentOffsetX,
      currentOffsetY: highlight.position.currentOffsetY,
      currentRotation: highlight.position.currentRotation,
      pdfPageCoordinates: highlight.position.pdfPageCoordinates,
    },
    comment: highlight.comment,
    content: highlight.content,
    chapter: highlight.chapter,
    randomId: highlight.randomId,
    duplicateOfWithRandomId: highlight.duplicateOfWithRandomId,
    annotationType: highlight.annotationType,
  };
}

function convertPdfCoordinatesToScaled(
  rectangle: AnnotationRectangleProperties,
  annotation: Annotation,
  viewer: T_PDFJS_Viewer
): Scaled {
  const pageNumber = rectangle.pageNumber != null ? rectangle.pageNumber : 0;
  const viewport = viewer.getPageView(pageNumber - 1).viewport as Viewport;
  if (viewport.offsetX !== 0.0 || viewport.offsetY !== 0.0) {
    console.log("Hello");
  }
  if (annotation.rotation === 90) {
    return {
      x1: (rectangle.y1 - annotation.pageOffsetY) * viewport.scale,
      x2: (rectangle.y2 - annotation.pageOffsetY) * viewport.scale,
      // inverted due to inverted scale
      y1: (rectangle.x1 - annotation.pageOffsetX) * viewport.scale,
      y2: (rectangle.x2 - annotation.pageOffsetX) * viewport.scale,
      // The viewer needs this somehow :-|
      width: annotation.pageHeight * viewport.scale,
      height: annotation.pageWidth * viewport.scale,

      pageNumber: pageNumber,
    };
  }

  return {
    x1: (rectangle.x1 - annotation.pageOffsetX) * viewport.scale,
    x2: (rectangle.x2 - annotation.pageOffsetX) * viewport.scale,
    // inverted due to inverted scale
    y1:
      (annotation.pageHeight - rectangle.y2 + annotation.pageOffsetY) *
      viewport.scale,
    y2:
      (annotation.pageHeight - rectangle.y1 + annotation.pageOffsetY) *
      viewport.scale,
    // The viewer needs this somehow :-|
    width: annotation.pageWidth * viewport.scale,
    height: annotation.pageHeight * viewport.scale,

    pageNumber: pageNumber,
  };
}

function convertPdfCoordinatesToPdfViewerCoordinates(
  annotation: Annotation,
  viewer: T_PDFJS_Viewer
): ScaledPosition {
  if (
    annotation === undefined ||
    annotation === null ||
    annotation.pageNumber === null ||
    annotation.pageNumber === undefined
  ) {
    console.log("hello");
  }
  const viewport = viewer.getPageView(annotation.pageNumber - 1)
    .viewport as Viewport;
  const convertedBoundingRect: Scaled = convertPdfCoordinatesToScaled(
    annotation.boundingRectangle,
    annotation,
    viewer
  );
  const convertedRects: Array<Scaled> = annotation.rectangles.map((rect) => {
    return convertPdfCoordinatesToScaled(rect, annotation, viewer);
  });

  return {
    boundingRect: convertedBoundingRect,
    rects: convertedRects,
    pageNumber: annotation.pageNumber,
    usePdfCoordinates: false,
    currentViewportScale: viewport.scale,
    currentOffsetX: viewport.offsetX,
    currentOffsetY: viewport.offsetY,
    currentRotation: viewport.rotation,
    rotation: annotation.rotation,
    pdfPageCoordinates: pdfPageCoordinates(viewport, annotation.pageNumber),
  };
}

function convertRsAnnotationToIHighlight(
  annotation: Annotation,
  viewer: T_PDFJS_Viewer
): IHighlight {
  return {
    position: convertPdfCoordinatesToPdfViewerCoordinates(annotation, viewer),
    comment: {
      count: annotation.chapter,
      text: annotation.annotationText,
    },
    content: {
      text: annotation.annotatedContent,
    },
    chapter: annotation.chapter,
    index: annotation.index,
    annotationWriterType: byAnnotationWriterType(
      annotation.annotationWriterType
    ),
    annotationType: annotation.type,
    randomId: annotation.randomId,
    duplicateOfWithRandomId: annotation.duplicateOfWithRandomId,
  };
}
