import { Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import React from "react";
import AreaTopicCreation from "./AreaTopicCreation";
import PubmedSearch from "./PubmedSearch";
import ViewForm from "./ViewForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RootDiv = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: theme.palette.background.paper,
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: 40,
  paddingBottom: theme.spacing(3),
}));

export default function ImportView() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <RootDiv>
      <br />
      <StyledToolbar
        position="static"
        variant="dense"
        disableGutters
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab disableRipple label="Review" {...a11yProps(0)} />
          <Tab disableRipple label="Pubmed" {...a11yProps(1)} />
          <Tab disableRipple label="Manual creation" {...a11yProps(1)} />
        </Tabs>
      </StyledToolbar>
      <TabPanel value={value} index={0}>
        <ViewForm state={"IMPORTED"} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PubmedSearch />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AreaTopicCreation />
      </TabPanel>
    </RootDiv>
  );
}
