import { Box, Checkbox, Typography } from "@mui/material";
import React from "react";
import FieldLink from "../../../subcomponents/record/FieldLink";
import { UserConfirmationState } from "../../../../model/annotation/UserConfirmationState";
import {
  getConfStateByWorkflowState,
  setConfStateByWorkflowState,
} from "../../../../utils/validation/confirmationByStateUtils";
import {
  createLinkToRegistry,
} from "../../../../utils/validation/RegistryUtils";
import { PublicationContentComponent } from "./PublicationContentComponent";

const isAtLeastOneVariableDifferent = (entries) => {
  let atLeastOne = false;
  entries.every((entry) => {
    if (entry !== null && entry.content !== entry.contentOriginal) {
      atLeastOne = true;
      return false;
    }
    return true;
  });

  return atLeastOne;
};

export const RegistryEntriesComponent = ({
  publicationRegistryEntries,
  currentWorkflowState,
  saveRegistries,
}) => {
  const [disableCheckbox, setDisableCheckbox] = React.useState(false);

  const handleDisableCheckbox = (disable) => {
    console.log("handleDisableCheckbox");
    setDisableCheckbox((disable) => disable);
  };

  const handleChangeCheckBox = (e) => {
    let check;

    if (e.target.checked) {
      check = UserConfirmationState.ACCEPTED.name;
    } else {
      check = UserConfirmationState.DEFAULT.name;
    }

    let newPublicationRegistryEntries = publicationRegistryEntries;

    setConfStateByWorkflowState(
      newPublicationRegistryEntries.userConfirmationByState,
      currentWorkflowState,
      check
    );

    saveRegistries(newPublicationRegistryEntries);
  };

  // const onValidate = (userConfirmationByState, groupName) => {
  //   setConfStateByWorkflowState(
  //     newPublicationRegistryEntries.userConfirmationByState,
  //     currentWorkflowState,
  //     userConf
  //   );
  // };

  const onSaveItem = (newContent, idx) => {
    newContent["dataSourceType"] =
      publicationRegistryEntries.registryEntries[idx].dataSourceType;
    let newPublicationRegistryEntries = publicationRegistryEntries;
    newPublicationRegistryEntries.registryEntries[idx] = newContent;

    // console.log(publicationRegistryEntries.registryEntries[idx]);

    let userConf;
    if (
      isAtLeastOneVariableDifferent(
        newPublicationRegistryEntries.registryEntries
      )
    ) {
      userConf = UserConfirmationState.CHANGED.name;
    } else {
      userConf = UserConfirmationState.DEFAULT.name;
    }

    setConfStateByWorkflowState(
      newPublicationRegistryEntries.userConfirmationByState,
      currentWorkflowState,
      userConf
    );

    saveRegistries(newPublicationRegistryEntries);
  };

  return (
    <div>
      <Box mb={2} display="flex" flexDirection="row">
        <Typography variant="h5">10. Registry Entries</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="start"
        flexDirection="row"
        alignItems="center"
        ml={2}
      >
        <Box>
          <Typography align="center">Validate</Typography>
        </Box>
        <Box>
          <Checkbox
            checked={
              getConfStateByWorkflowState(
                publicationRegistryEntries.userConfirmationByState,
                currentWorkflowState
              ) === UserConfirmationState.ACCEPTED.name
            }
            onChange={handleChangeCheckBox}
            disabled={
              disableCheckbox ||
              isAtLeastOneVariableDifferent(
                publicationRegistryEntries.registryEntries
              )
            }
          />
        </Box>
      </Box>
      {publicationRegistryEntries &&
        publicationRegistryEntries.registryEntries.map((entry, idx) => {
          const { dataSourceType, ...publicationContent } = entry;
          const registryId = entry.registerContent != null ? entry.registerContent : entry.content;
          const registryEntryLink = createLinkToRegistry(entry.dataSourceType, registryId);
          return (
            <React.Fragment key={idx}>
              <Box
                display="flex"
                justifyContent="start"
                flexDirection="row"
                alignItems="center"
              >
                <Box display="flex" ml={2}>
                  {publicationContent && (
                    <PublicationContentComponent
                      content={publicationContent}
                      onSaveItem={onSaveItem}
                      contentType={idx.toString()}
                      disableTextField={
                        getConfStateByWorkflowState(
                          publicationRegistryEntries.userConfirmationByState,
                          currentWorkflowState
                        ) === UserConfirmationState.ACCEPTED.name
                      }
                      handleDisableCheckbox={handleDisableCheckbox}
                      textFieldWidth={200}
                    />
                  )}
                </Box>
                {registryEntryLink !== null ?
                  <Box ml={2}>
                    <FieldLink field={"url"} value={registryEntryLink} />
                  </Box>
                  : null}
              </Box>

            </React.Fragment>
          );
        })}
    </div>
  );
};

// this does not have userConfirmationState
// const RegistryEntryComponent = ({ entry }) => {
//   const { dataSourceType, ...publicationContent } = entry;
//   return (
//     <div>
//       {publicationContent && (
//         <PublicationContentComponent content={publicationContent} />
//       )}
//       <BasicContentComponent
//         content={dataSourceType}
//         name={"Data Source"}
//         marginRight={20}
//         minWidth={100}
//         textWidth={240}
//       />
//       {/* {dataSourceType && <Box>{`dataSourceType: ${dataSourceType}`}</Box>} */}
//     </div>
//   );
// };
