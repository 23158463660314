import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import type {
  IHighlight,
  EditHighlightOperation,
} from "../../libs/react-pdf-highlighter";
import { sortHighlights } from "./utils/highlightUtils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";

import {
  getAllowedFiltersByState,
  getBasicAndOutcomeFilters,
  isIcdOrRegistryFilterActive,
} from "./utils/filterUtils";
import { SortBar } from "./subcomponents/SortBar";
import { createAnnotationComponentByType } from "./utils/componentBuilderUtils";
import { AnnotationType } from "../../model/annotation/AnnotationType";
interface Props {
  highlights: Array<IHighlight>;
  filteredHighlights: Array<IHighlight>;
  deleteAnnotations: () => void;
  scrollToByHash: (highlight: IHighlight) => void;
  disableAnnotation: (highlight: IHighlight) => void;
  removeAnnotation: (highlight: IHighlight) => void;
  removeSameButThis: (highlight: IHighlight) => void;
  restoreDisabledAnnotations: () => void;
  acceptAnnotationByAnnotator: (highlight: IHighlight) => void;
  currentRecord: any;
  duplicateAnnotation: (editHighlightOperation: EditHighlightOperation) => void;
  initialFilterState: string;
  handleFilterChanged: (filterState: string) => void;
  workflowState: string;
}

export function Sidebar({
  highlights,
  filteredHighlights,
  deleteAnnotations,
  scrollToByHash,
  removeAnnotation,
  disableAnnotation,
  removeSameButThis,
  acceptAnnotationByAnnotator,
  duplicateAnnotation,
  currentRecord,
  initialFilterState,
  handleFilterChanged,
  restoreDisabledAnnotations,
  workflowState,
}: Props) {
  const [sortBy, setSortBy] = useState(0);
  const sortedHighlights: Array<IHighlight> = sortHighlights(
    filteredHighlights,
    sortBy
  );
  const duplicateIndices = getDuplicateVariableIndices(sortedHighlights);
  const isIcdOrRegistryFilterSelectinActive: boolean =
    isIcdOrRegistryFilterActive(initialFilterState);

  return (
    <div className="sidebar" style={{ width: "20vw" }}>
      <div
        className="description"
        style={{ padding: "1rem", marginTop: "-20px" }}
      >
        <Comments className="sidebar__comments" currentRecord={currentRecord} />
        <h2 style={{ marginBottom: "1rem", marginTop: "5px" }}>Annotations</h2>
        <p>
          <small>
            To create annotation, mark the text and click "Add annotation"
          </small>
        </p>
      </div>
      <div style={{ padding: "1rem", marginTop: "-20px" }}>
        <FormControl fullWidth>
          <InputLabel id="annotation-type-select">
            Annotation Selection
          </InputLabel>
          <Select
            labelId="annotation-type-select"
            id="annotation-type-select"
            value={initialFilterState}
            label="Annotation Selection"
            onChange={(event: any) => {
              handleFilterChanged(event.target.value);
            }}
            defaultValue={initialFilterState}
          >
            {getAllowedFiltersByState(workflowState).map((filtername) => {
              return (
                <MenuItem value={filtername.state}>{filtername.text}</MenuItem>
              );
            })}
            {getBasicAndOutcomeFilters(highlights).map((filtername) => {
              return <MenuItem value={filtername}>{filtername}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </div>
      {SortBar(setSortBy)}
      <ul className="sidebar__highlights">
        {sortedHighlights.map((highlight: IHighlight, index: any) => (
          <li
            key={index}
            className={
              "sidebar__highlight" +
              (highlight.annotationType === AnnotationType.EMPTY.type ||
              highlight.annotationType === AnnotationType.EXTRACTED_EMPTY.type
                ? " sidebar__highlight_bg_empty"
                : "")
            }
            onClick={() => {
              if (highlight.annotationType !== AnnotationType.EMPTY.type) {
                scrollToByHash(highlight);
              }
            }}
          >
            {createAnnotationComponentByType(
              duplicateIndices.has(highlight.index) ? "#ff0000" : "#000000",
              highlight,
              removeAnnotation,
              disableAnnotation,
              removeSameButThis,
              acceptAnnotationByAnnotator,
              duplicateAnnotation
            )}
          </li>
        ))}
      </ul>
      {!isIcdOrRegistryFilterSelectinActive && highlights.length > 0 ? (
        <div style={{ padding: "1rem" }}>
          <button onClick={deleteAnnotations}>Delete All Annotations</button>
        </div>
      ) : null}
      {isIcdOrRegistryFilterSelectinActive ? (
        <div style={{ padding: "1rem" }}>
          <button onClick={restoreDisabledAnnotations}>
            Restore all disabled annotations
          </button>
        </div>
      ) : null}
    </div>
  );
}

/**
 * finds duplicate variables and creates an index set
 */
function getDuplicateVariableIndices(highlights: Array<IHighlight>) {
  const variables = new Map<string, Number>();
  const duplicateIndices = new Set<Number>();
  highlights.map((highlight, index) => {
    if (variables.has(highlight.comment.text)) {
      duplicateIndices.add(highlight.index);
      const duplicateIndexOther: any = variables.get(highlight.comment.text);
      if (duplicateIndexOther !== undefined) {
        duplicateIndices.add(duplicateIndexOther);
      }
    }
    variables.set(highlight.comment.text, highlight.index);
    return null;
  });
  return duplicateIndices;
}

const Comments = ({ className, currentRecord }: any) => {
  if (
    !(
      currentRecord.comment ||
      currentRecord.annotatorComment ||
      currentRecord.reviewComment
    )
  ) {
    return null;
  }
  return (
    <div className={className}>
      <h3>Comments</h3>

      {currentRecord.comment && (
        <Accordion elevation={0} className={className}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="caption">General Comment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="caption"
              style={{ whiteSpace: "break-spaces" }}
            >
              {currentRecord.comment}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      {currentRecord.annotatorComment && (
        <Accordion elevation={0} className={className}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="caption">Annotator Comment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="caption"
              style={{ whiteSpace: "break-spaces" }}
            >
              {currentRecord.annotatorComment}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      {currentRecord.reviewComment && (
        <Accordion elevation={0} className={className}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography variant="caption">Reviewer Comment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="caption"
              style={{ whiteSpace: "break-spaces" }}
            >
              {currentRecord.reviewComment}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};
