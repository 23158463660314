export const successSnackbarOptions = {
  variant: "success",
  autoHideDuration: 2000,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export const warningSnackbarOptions = {
  variant: "warning",
  autoHideDuration: 2500,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

// const action = (props, key) => (
//   <React.Fragment>
//     <React.Button
//       onClick={() => {
//         console.log("ok");
//       }}
//     >
//       'Ok'
//     </React.Button>
//     <React.Button
//       onClick={() => {
//         console.log("cancel");
//         // props.closeSnackbar(key);
//       }}
//     >
//       'Cancel'
//     </React.Button>
//   </React.Fragment>
// );

// export const confirmSnackbarOptions = {
//   variant: "warning",
//   persist: true,
//   // action,
// };
