import {
  AppBar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import api from "../../../api";
import { WorkflowStates } from "../../../model/WorkflowStates";
import utils from "../../../utils/utils";
import { stateName2Route } from "../../routes";
import { successSnackbarOptions } from "../notification/notifications";
import Spinner from "../notification/Spinner";
import { VirtualizedTable } from "./MuiVirtualizedTable";
import { createRow, header } from "./virtualTableProperties";
import { useSnackbar } from "notistack";

const getRows = (papers) => {
  let rows = [];
  for (let i = 0; i < papers.length; i += 1) {
    let row = createRow(papers[i], i);
    rows.push(row);
  }

  return rows;
};

const fetchExcluded = (setPapersExcluded, setLoadingExcluded) => {
  setLoadingExcluded(true);
  api
    .fetchRecordsExcluded()
    .then((data) => {
      const rows = getRows(data);
      setPapersExcluded(rows);
      setLoadingExcluded(false);
    })
    .catch((e) => {
      console.log("error fetchRecordsAll excluded: " + e);
    });
};

const fetchIncluded = (setPapers) => {
  api
    .fetchRecordsIncluded()
    .then((data) => {
      const rows = getRows(data);
      setPapers(rows);
    })
    .catch((e) => {
      console.log("error fetchRecordsAll included: " + e);
    });
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appbar: {
    height: 40,
    paddingBottom: theme.spacing(3),
  },
}));

export default function PapersTableVirtual() {
  const classes = useStyles();
  let initCurrentSelection = localStorage.getItem("allPapersStateSelected")
    ? localStorage.getItem("allPapersStateSelected")
    : 0;

  const [papers, setPapers] = React.useState([]);
  const [papersExcluded, setPapersExcluded] = React.useState([]);
  const [paperName, setPaperName] = React.useState("");
  const [stateSelectedIdx, setStateSelectedIdx] =
    React.useState(initCurrentSelection);
  const [addExcluded, setAddExcluded] = React.useState(false);
  const [showDuplicated, setShowDuplicated] = React.useState(false);
  const [loadingExcluded, setLoadingExcluded] = React.useState(false);
  const [topic, setTopic] = React.useState("");
  const [area, setArea] = React.useState("");

  const numFoundElement = React.useRef(papers.length);

  const { enqueueSnackbar } = useSnackbar();

  let navigate = useNavigate();

  React.useEffect(() => {
    fetchIncluded(setPapers);
    return () => {
      setPapers([]);
      setPaperName("");
      setTopic("");
    };
  }, []);

  const handleChangePaperName = (e) => {
    setPaperName(e.target.value);
  };

  const handleChangeTopic = (e) => {
    setTopic(e.target.value);
  };

  const handleChangeArea = (e) => {
    setArea(e.target.value);
  };

  const handleChangeStateSelected = (e) => {
    localStorage.setItem("allPapersStateSelected", e.target.value);
    setStateSelectedIdx(e.target.value);
  };

  const handleChangeCheckbox = (e) => {
    setAddExcluded(e.target.checked);

    if (papersExcluded.length === 0) {
      fetchExcluded(setPapersExcluded, setLoadingExcluded);
    }
  };

  const handleDuplicatedCheckbox = (e) => {
    setShowDuplicated(e.target.checked);
  };

  const states = ["All"].concat(
    Object.values(WorkflowStates).map((item) => item.stateName)
  );

  const filterByName = (item) => {
    return paperName === ""
      ? item
      : item.paperName.toLowerCase().match(paperName.toLowerCase());
  };

  const filterByState = (item) => {
    return states[stateSelectedIdx] === "All"
      ? item
      : item.stateName === states[stateSelectedIdx];
  };

  const filterByTopic = (item) => {
    return topic === ""
      ? item
      : item.topic.topic.toLowerCase().includes(topic.toLowerCase());
  };

  const filterByArea = (item) => {
    return area === ""
      ? item
      : item.area.toLowerCase().includes(area.toLowerCase());
  };

  const cleanFilters = () => {
    setPaperName("");
    setTopic("");
    setArea("");
  };

  let rows = filteredRows(
    papers,
    addExcluded,
    papersExcluded,
    filterByState,
    filterByName,
    filterByTopic,
    filterByArea,
    showDuplicated
  );

  numFoundElement.current = rows.length;

  if (papers.length === 0) {
    return <Spinner mx={100} my={20} disableShrink />;
  }

  const csvHeaders = [
    "Name",
    "State",
    "Area",
    "Topic",
    "Annotator",
    "Reviewer",
  ];

  const handleGoto = (data) => {
    navigate(stateName2Route[data.stateName] + "/" + data.areaId + "/" + data.topicId);
  };

  const handleMove = (data, selection) => {
    let fromState = findState(data.stateName);
    let idx = papersExcluded.findIndex(({ idPaper }) => idPaper === data.id);
    let copy = [...papersExcluded];
    copy[idx].stateName = selection;

    api
      .moveToState(data.id, fromState, selection)
      .then((data) => {
        if (data.status === 200) {
          setPapersExcluded(copy);
          enqueueSnackbar("Record successfully moved", successSnackbarOptions);
        }
      })
      .catch((e) => console.log("move to state error: " + e));
  };

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.appbar}>
          <Typography className={classes.title}>
            Number of papers {numFoundElement.current}
          </Typography>
          <Button
            className={classes.menuButton}
            disableRipple
            size="small"
            style={{ color: "white" }}
          >
            <CSVLink
              style={{ textDecoration: "none", color: "white" }}
              // className="btn btn-primary"
              filename="papers_report.csv"
              headers={csvHeaders}
              data={makeCsvData(rows)}
            >
              CSV Report
            </CSVLink>
          </Button>
        </Toolbar>
      </AppBar>
      <Box mb={2} display="flex" flexDirection="column" alignItems="flex-start">
        <Box display="flex" flexDirection="row" alignItems="flex-end">
          <Box width={180} ml={2} mb={0.5}>
            {searchByName(paperName, handleChangePaperName)}
          </Box>
          <Box width={120} ml={7}>
            {selectState(states, stateSelectedIdx, handleChangeStateSelected)}
          </Box>
          <Box width={150} ml={4} mb={0.5}>
            {searchByArea(area, handleChangeArea)}
          </Box>
          <Box width={150} ml={5} mb={0.5}>
            {searchByTopic(topic, handleChangeTopic)}
          </Box>

          <Box ml={5} mb={0.5}>
            <Button
              style={{ color: "#253f5b" }}
              disableRipple
              onClick={cleanFilters}
            >
              <Typography>Clear filters</Typography>
            </Button>
          </Box>

          <Box ml={5}>
            {excludedCheckBox(
              addExcluded,
              handleChangeCheckbox,
              loadingExcluded
            )}
          </Box>
          <Box ml={5}>
            {duplicatedCheckBox(showDuplicated, handleDuplicatedCheckbox)}
          </Box>
        </Box>
      </Box>
      <Paper style={{ height: 640, width: "100%" }}>
        <VirtualizedTable
          rowCount={rows.length}
          rowGetter={({ index }) => rows[index]}
          columns={header}
          goto={handleGoto}
          handleMove={handleMove}
        />
      </Paper>
    </React.Fragment>
  );
}

const duplicated = (items) => {
  let uniq = items
    .map((item) => {
      return {
        count: 1,
        name: item.paperName,
      };
    })
    .reduce((a, b) => {
      a[b.name] = (a[b.name] || 0) + b.count;
      return a;
    }, {});

  let namesDuplicated = Object.keys(uniq).filter((a) => uniq[a] > 1);
  let duplicated = [];

  items.forEach((item) => {
    if (namesDuplicated.includes(item.paperName)) {
      duplicated.push(item);
    }
  });

  return duplicated;
};

const makeCsvData = (rows) => {
  let r = rows.map((item) => {
    let annotator = item.annotator
      ? item.annotator.replaceAll('"', "'").replaceAll("\n", " ")
      : "";
    let reviewer = item.reviewer
      ? item.reviewer.replaceAll('"', "'").replaceAll("\n", " ")
      : "";

    return [
      item.paperName.trim(),
      item.stateName,
      item.area,
      item.topic.topic,
      annotator,
      reviewer,
    ];
  });
  // console.log(r);
  return r;
};

const filteredRows = (
  papers,
  addExcluded,
  papersExcluded,
  filterByState,
  filterByName,
  filterByTopic,
  filterByArea,
  showDuplicated
) => {
  if (papers.length === 0) {
    return [];
  }
  let rows = addExcluded ? papers.concat(papersExcluded) : papers;
  if (showDuplicated) {
    rows = duplicated(rows);
  }
  utils.sortRecords(rows, (item) => item.paperName);
  rows = rows
    .filter(filterByState)
    .filter(filterByName)
    .filter(filterByTopic)
    .filter(filterByArea);

  return rows;
};

const selectState = (states, stateSelectedIdx, handleChangeStateSelected) => {
  let items = [];

  if (states.length > 0) {
    items = states.map((item, idx) => {
      return (
        <MenuItem key={item.toString()} value={idx}>
          {item}
        </MenuItem>
      );
    });
  } else {
    items.push(
      <MenuItem key={0} value={0}>
        No items
      </MenuItem>
    );
  }

  return (
    <FormControl style={{ width: 120 }} margin="dense">
      <Tooltip
        placement="right"
        disableTouchListener={true}
        // disableFocusListener={true}
        title={
          stateSelectedIdx < states.length
            ? states[stateSelectedIdx]
            : states[0]
        }
      >
        <InputLabel id="in_select_state">State</InputLabel>
      </Tooltip>
      <Select
        labelId="select_state"
        id="select_state"
        value={stateSelectedIdx < states.length ? stateSelectedIdx : 0}
        onChange={handleChangeStateSelected}
        margin="dense"
      >
        {items}
      </Select>
    </FormControl>
  );
};

const searchByName = (paperName, handleChangePaperName) => {
  return (
    <Input
      margin="dense"
      placeholder="Paper name"
      type="text"
      value={paperName}
      onChange={handleChangePaperName}
    ></Input>
  );
};

const searchByTopic = (topic, handleChangeTopic) => {
  return (
    <Input
      margin="dense"
      placeholder="Topic"
      type="text"
      value={topic}
      onChange={handleChangeTopic}
    ></Input>
  );
};

const searchByArea = (area, handleChangeArea) => {
  return (
    <Input
      margin="dense"
      placeholder="Area"
      type="text"
      value={area}
      onChange={handleChangeArea}
    ></Input>
  );
};

let spinnerSmall = <Spinner size={0} color="secondary" disableShrink />;

const excludedCheckBox = (
  addExcluded,
  handleChangeCheckbox,
  loadingExcluded
) => {
  return (
    <FormControlLabel
      style={{ color: "#253f5b" }}
      control={
        <Checkbox
          checked={addExcluded}
          onChange={handleChangeCheckbox}
          name="add_excluded"
          disableRipple
        />
      }
      label={
        loadingExcluded ? spinnerSmall : <Typography>Add excluded</Typography>
      }
    />
  );
};

const duplicatedCheckBox = (showDuplicated, handleDuplicatedCheckbox) => {
  return (
    <Tooltip
      placement="right"
      disableTouchListener={true}
      // disableFocusListener={true}
      title="Approximation algorithm. It might contain wrong duplicated."
    >
      <FormControlLabel
        style={{ color: "#253f5b" }}
        control={
          <Checkbox
            checked={showDuplicated}
            onChange={handleDuplicatedCheckbox}
            color="primary"
            name="duplicated"
            disableRipple
          />
        }
        label="Duplicated (Approx. algorithm)"
      />
    </Tooltip>
  );
};

const findState = (stateName) => {
  for (let state in WorkflowStates) {
    if (WorkflowStates[state].stateName === stateName) {
      return WorkflowStates[state].state;
    }
  }
};
