import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

export default function Spinner({
  mx,
  my,
  size,
  color,
  variant,
  text = "Loading...",
}) {
  // let showCaption = text ? text : "Loading...";
  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        mx={mx}
        my={my}
      >
        <Typography variant="caption" color="textSecondary">
          {text}
        </Typography>
        <br />
        <CircularProgress size={size} color={color} variant={variant} />
      </Box>
    </div>
  );
}
