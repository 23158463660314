import React, { useState, useEffect, useCallback } from "react";
import AreaSelect from "../subcomponents/selectors/AreaSelect";
import { getPaperName } from "../../utils/utils";
import TableWithActions from "../subcomponents/tables/TableWithActions";
import api from "../../api";
import ViewTableLayout from "./layouts/ViewTableLayout";
import { RecordActionsProperties } from "../../model/ViewProperties";
import { useSnackbar } from "notistack";
import { successSnackbarOptions } from "../subcomponents/notification/notifications";

function ViewTable({ state }) {
  const [currentAreaId, setCurrentAreaId] = useState("empty");
  const [recordList, setRecordList] = useState([]);
  const { showForward } = RecordActionsProperties[state];
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (currentAreaId !== "empty") {
      api
        .fetchRecords(state, currentAreaId, "fromArea")
        .then((recList) => {
          setRecordList(recList);
        })
        .catch((e) => console.log("processing error: " + e));
    }

    return () => {
      setRecordList([]);
    };
  }, [state, currentAreaId]);

  const handleCurrentAreaId = useCallback((areaId) => {
    setCurrentAreaId(areaId);
  }, []);

  const updateRecord = (record) => {
    const idx = recordList.findIndex(({ id }) => id === record.id);
    let listCopy = [...recordList];
    listCopy[idx] = record;
    setRecordList(listCopy);
    console.log("updated");
  };

  const handleForwardBackward = (idx, action) => {
    if (action === api.Action.FORWARD) {
      api
        .processing(recordList[idx])
        .then((data) => {
          updateRecord(data);
          enqueueSnackbar("Record successfully moved", successSnackbarOptions);
        })
        .catch((e) => console.log("processing error: " + e));
    } else if (action === api.Action.BACKWARD) {
      api
        .moveRecord(recordList[idx], action)
        .then((data) => {
          updateRecord(data);
          enqueueSnackbar("Record successfully moved", successSnackbarOptions);
        })
        .catch((e) => console.log("move backward error: " + e));
    } else {
      console.log("No valid action");
    }
  };

  const rows = createRows(recordList);

  return (
    <ViewTableLayout
      select={
        <AreaSelect
          state={state}
          handleCurrentAreaId={handleCurrentAreaId}
          //   refresh
        />
      }
      table={
        <TableWithActions
          rows={rows}
          handleForwardBackward={handleForwardBackward}
          state={state}
          showForward={showForward}
        />
      }
    />
  );
}

function createRow(record) {
  return {
    name: getPaperName(record),
    pmid: record.pubmedId,
    state: record.workflowState,
  };
}

function createRows(recordList) {
  let rows = [];
  if (recordList.length > 0) {
    rows = recordList.map((item, idx) => {
      return createRow(item);
    });
  }
  return rows;
}

export default ViewTable;
