import React from "react";


import {
    Button,
    CircularProgress,
} from "@mui/material";

import PubSub from "pubsub-js";
import swal from "sweetalert";
import { alertConfirm, alertSuccess } from "../../subcomponents/notification/notificationDialogs";
import api from "../../../api";
import { PubSubBooleanType, PubSubMessage } from "../../../utils/communication/PubSubTypes";
import { isAnnotationConvertToUiButtonEnabled } from "../../../utils/annotation/uiUtils";

interface AnnotationConvertToUiButtonProps {
    currentRecord: any;
    state: string;
    updateRecord: Function;
}

export function AnnotationConvertToUiButton({
    currentRecord,
    state,
    updateRecord,
}: AnnotationConvertToUiButtonProps) {
    const [processingConversion, setProcessingConversion] = React.useState(false);
    const disabled = !isAnnotationConvertToUiButtonEnabled(state, currentRecord);

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    const handleConvertToUi = (record: any) => {
        setProcessingConversion(true);

        sleep(2000).then(() => {
            setProcessingConversion(false);
            PubSub.publish(PubSubMessage.DISABLE_ACTION_EVENT,
                new PubSubBooleanType(PubSubMessage.DISABLE_ACTION_EVENT, false));
        });
        api
            .convertPaperToAnnotatinUi(currentRecord)
            .then((response: any) => {
                setProcessingConversion(false);
                PubSub.publish(PubSubMessage.DISABLE_ACTION_EVENT,
                    new PubSubBooleanType(PubSubMessage.DISABLE_ACTION_EVENT, false));
                updateRecord(response.data);
                alertSuccess("Conversion successful", "The conversion was successful.");
            })
            .catch((e) => {
                console.log("Paper conversion error: " + e);
                setProcessingConversion(false);
                PubSub.publish(PubSubMessage.DISABLE_ACTION_EVENT,
                    new PubSubBooleanType(PubSubMessage.DISABLE_ACTION_EVENT, false));
                swal({
                    title: "Conversion error",
                    text: "An error occured during the conversion, please report this as bug.",
                    icon: "error",
                    dangerMode: false,
                    closeOnClickOutside: false,
                });
            });
    };

    const onConvertToUi = (e: any) => {
        PubSub.publish(PubSubMessage.DISABLE_ACTION_EVENT,
            new PubSubBooleanType(PubSubMessage.DISABLE_ACTION_EVENT, true));

        let msg = "Convert PDF to Annotation UI? The paper will be disabled during the conversion.";

        alertConfirm("Convert PDF", msg, handleConvertToUi, currentRecord);
    };

    return (
        <div>
            <Button
                variant="contained"
                size="small"
                disableRipple
                color="secondary"
                disabled={disabled}
                onClick={onConvertToUi}
            >
                {processingConversion ? (
                    <CircularProgress
                        size="1.2rem"
                        style={{
                            borderColor: "white",
                            color: "white",
                            marginRight: "0.4rem",
                        }}
                    />
                ) : null}
                Convert to UI
            </Button>
        </div>
    );
}
