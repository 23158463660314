export const AnnotationBaseContentType = {
  CONDITION: {
    type: "CONDITION",
    typeName: "condition",
    chapter: "3",
    listPosition: 10000,
  },
  DESIGN: {
    type: "DESIGN",
    typeName: "design",
    chapter: "1",
    listPosition: 1,
  },
  SUBJECTS: {
    type: "SUBJECTS",
    typeName: "subjects",
    chapter: "3",
    listPosition: 5000,
  },
  GROUP_NAME: {
    type: "GROUP_NAME",
    typeName: "group",
    chapter: "2",
    listPosition: 20000,
  },
  GROUP_N: {
    type: "GROUP_N",
    typeName: "N",
    chapter: "2",
    listPosition: 20000,
  },
  CUSTOM: {
    type: "CUSTOM",
    stateName: "custom",
    chapter: "",
    listPosition: 100000,
  },
};

export function byType(inType) {
  for (let type in AnnotationBaseContentType) {
    if (AnnotationBaseContentType[type].type === inType) {
      return AnnotationBaseContentType[type];
    }
  }
  return null;
}
