/**
 * Contains the utils for the UI itself
 */
import { Viewport } from "../../libs/react-pdf-highlighter";
import { WorkflowStates } from "../../model/WorkflowStates";

/**
 * dependent on the workflow state the extracted annotations will be used in the annotation UI
 */
export function UseExtractedAnnotationsInAnnotationUi(
  workflowState: string
): boolean {
  if (workflowState === WorkflowStates.EXTRACTION_VALIDATION_DATA.state) {
    return true;
  }
  return false;
}

export function getInitialFilterStateOnAnnotationUiOpen(
  workflowState: string
) {}

/**
 * Enables the UI Open button
 */
export function isAnnotationUiOpenButtonEnabled(
  state: string,
  currentRecord: any
): boolean {
  let disabled: boolean = false;
  if (getFileForAnntationUi(state, currentRecord) === null) {
    disabled = true;
  } else if (state === WorkflowStates.ELIGIBILITY_ASSESSMENT_MAPPING.state) {
    disabled = false;
  } else if (state === WorkflowStates.EXTRACTION_VALIDATION_DATA.state) {
    disabled = false;
  } else if (state === WorkflowStates.RELEASE_VALIDATION.state) {
    disabled = false;
  } else if (state === WorkflowStates.RELEASE_VALIDATION_REVIEW.state) {
    disabled = false;
  } else if (state === WorkflowStates.RELEASED.state) {
    disabled = false;
  } else if (!currentRecord.annotationForUIExtracted) {
    disabled = true;
  } else if (currentRecord.userAnnotatedPdfWithoutAnnotationUI) {
    disabled = true;
  }

  return disabled;
}

/**
 * Enables the conversion button so that annotations
 */
export function isAnnotationConvertToUiButtonEnabled(
  state: string,
  currentRecord: any
): boolean {
  // Enabled when there is an uploaded annotated file
  if(currentRecord.filenameUploadedAnnotated !== null) {
    return true;
  }
  // or the paper has not been converted.
  else if(!currentRecord.annotationForUIExtracted) {
    return true;
  }
  return false;
}

export function getFileForAnntationUi(state: string, currentRecord: any) {
  var file;

  if (
    state !== WorkflowStates.TO_BE_DISCUSSED.state &&
    state !== WorkflowStates.EXTRACTION_VALIDATION_DATA.state
  ) {
    file =
      currentRecord.filenameUploadedAnnotated !== null &&
      state !== WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.state
        ? "annotatedFileView"
        : "emptyFileView";
  } else if (state === WorkflowStates.EXTRACTION_VALIDATION_DATA.state) {
    file = "extractedFileView";
  } else {
    if (
      currentRecord.filenameUploadedAnnotated != null &&
      currentRecord.previousWorkflowState !==
        WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.state
    ) {
      file = "annotatedFileView";
    } else if (currentRecord.filenameUploaded != null) {
      file = "emptyFileView";
    } else {
      console.log("no pdf");
    }
  }
  return file;
}

export function pdfPageCoordinates(viewport: Viewport, pageNumber: number) {
  return {
    x1: viewport.viewBox[0],
    y1: viewport.viewBox[1],
    x2: viewport.viewBox[2],
    y2: viewport.viewBox[3],
    pageNumber: pageNumber,
    width: viewport.viewBox[2] - viewport.viewBox[0],
    height: viewport.viewBox[3] - viewport.viewBox[1],
  };
}

export function getOpenAnnotationUiButtonNameDependentOnWorkflowState(
  workflowState: string
) {
  if (
    workflowState === WorkflowStates.ANALYSIS.state ||
    workflowState === WorkflowStates.ANALYSIS_REVIEW.state
  ) {
    return "Annotation";
  } else if (
    workflowState === WorkflowStates.EXTRACTION_VALIDATION_DATA.state ||
    workflowState === WorkflowStates.ELIGIBILITY_ASSESSMENT_MAPPING.state
  ) {
    return "Validate Data";
  } else if (
    workflowState === WorkflowStates.RELEASE_VALIDATION.state ||
    workflowState === WorkflowStates.RELEASE_VALIDATION_REVIEW.state ||
    workflowState === WorkflowStates.RELEASED.state
  ) {
    return "Show Annotations";
  } else {
    return "Validate";
  }
}
