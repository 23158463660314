import type { IHighlight } from "../../../libs/react-pdf-highlighter";

export enum SortBy {
  PAGE,
  CHAPTER,
}

export function updateIdMathingHighlight(
  highlight: IHighlight,
  highlightId: string,
  position: Object,
  content: Object
) {
  const {
    randomId,
    position: originalPosition,
    content: originalContent,
    ...rest
  } = highlight;
  return randomId === highlightId
    ? {
        randomId,
        position: { ...originalPosition, ...position },
        content: { ...originalContent, ...content },
        ...rest,
      }
    : highlight;
}

export function sortHighlights(
  highlights: Array<IHighlight>,
  sortBy: SortBy
): Array<IHighlight> {
  if (sortBy === SortBy.PAGE) {
    return highlights.sort((highlight1, highlight2) => {
      if (highlight1.position.pageNumber > highlight2.position.pageNumber) {
        return 1;
      }

      if (highlight1.position.pageNumber < highlight2.position.pageNumber) {
        return -1;
      }

      if (
        highlight1.position.boundingRect.y1 <
        highlight2.position.boundingRect.y1
      ) {
        return -1;
      }
      return 0;
    });
  }
  return highlights.sort((highlight1, highlight2) => {
    if (highlight1.chapter > highlight2.chapter) {
      return 1;
    }

    if (highlight1.chapter < highlight2.chapter) {
      return -1;
    }

    if (
      highlight1.position.boundingRect.y1 < highlight2.position.boundingRect.y1
    ) {
      return -1;
    }

    return 0;
  });
}

export function findAvailableIndex(highlights: Array<IHighlight>) {
  let newIndex: number = -1;
  let count: number = 1;
  while (newIndex === -1) {
    let found: boolean = false;
    for (let i = 0; i < highlights.length; i++) {
      if (highlights[i].index === count) {
        found = true;
      }
    }
    if (!found) {
      newIndex = count;
    }
    count++;
  }
  return newIndex;
}
