import {
  Box,
  Button,
  InputLabel,
  Typography,
} from "@mui/material";
import React from "react";
import { exportSinglePaper } from "../../../api/exportApi";
import { Theme } from '@mui/material/styles';

interface ExportActionsProps {
  currentRecord: any;
}

export const ExportActions: React.FC<ExportActionsProps> = ({
  currentRecord,
}: ExportActionsProps) => {
  const onExport = (e: any) => {
    exportSinglePaper(currentRecord.id)
      .then((response) => {
        // ugly solution but downloading and setting the state of a paper
        // does not work better
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "")
        );
        document.body.appendChild(link);
        link.click();
        // updateRecord(data);
      })
      .catch((e) => console.log("download error: " + e));
  };

  const exportButton = ExportButton(onExport);

  return (
    <Box mb={0} display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" flexDirection="row" justifyContent="center">
        {<Box>{exportButton}</Box>}
      </Box>
    </Box>
  );
};

function ExportButton(onExport: any) {
  return (
    <InputLabel htmlFor="upload">
      <Button
        component="span"
        variant="contained"
        disableRipple
        sx={{ backgroundColor: (theme: Theme) => theme.palette.primary.main }}
        size="medium"
        onClick={onExport}
      >
        {
          <Typography variant="body2" display="block" align="center">
            Export
          </Typography>
        }
      </Button>
      <Typography
        variant="caption"
        display="block"
        // align="center"
        color="textSecondary"
      >
        {"Export Publication"}
      </Typography>
    </InputLabel>
  );
}
