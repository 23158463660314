import React from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  OutcomeSectionContent,
  PublicationContent,
} from "../../../../../model/publicationdata/PublicationData";
import { Theme } from '@mui/material/styles';

interface OutcomeNameContentComponentProps {
  section: OutcomeSectionContent;
  caption: string;
  publicationContent: PublicationContent;
  disableTextFields: boolean;
  updateContentWithSection: Function;
}

export function OutcomeSectionContentVariableComponent({
  section,
  caption,
  publicationContent,
  disableTextFields,
  updateContentWithSection,
}: OutcomeNameContentComponentProps) {
  const [editedContent, setEditedContent] = React.useState(publicationContent);

  const handleChange = (e: any) => {
    let newContent = editedContent;
    newContent.content = e.target.value;
    setEditedContent((editedContent) => {
      return { ...editedContent, ...newContent };
    });
  };

  const saveChanges = (e: any) => {
    const newContent = editedContent;
    if (
      section.name !== null &&
      section.name.variableName !== null &&
      section.name.variableName === newContent.variableName
    ) {
      section.name = newContent;
    } else if (
      section.timeFrame !== null &&
      section.timeFrame.variableName !== null &&
      section.timeFrame.variableName === newContent.variableName
    ) {
      section.timeFrame = newContent;
    }
    updateContentWithSection(section);
  };

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        style={{ minWidth: 800 }}
      >
        <Grid item xs={3}>
          <Typography>{caption}</Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            id={"id-" + section.chapter + "-" + caption.replaceAll(" ", "-")}
            variant="outlined"
            size="small"
            fullWidth
            multiline
            margin="dense"
            value={editedContent.content}
            onChange={handleChange}
            disabled={disableTextFields}
          />
          {/* later:
              error={textFieldError} */}
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            disableRipple
            onClick={saveChanges}
            sx={{ backgroundColor: (theme: Theme) => theme.palette.secondary.main }}
            disabled={disableTextFields}
            size="small"
          >
            Save
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Typography>
            {publicationContent.content !== publicationContent.contentOriginal
              ? "Orignal: " + publicationContent.contentOriginal
              : null}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OutcomeSectionContentVariableComponent;
