import React, { useState, useEffect } from "react";
import api from "../../../api";
import { Box } from "@mui/material";
import utils, { createSelectArea } from "../../../utils/utils";
// import { WorkflowStates } from "../../../model/WorkflowStates";

const fetchAreas = (
  state,
  setAreas,
  handleCurrentAreaId,
  setSelectedAreaIdx
) => {
  api
    .fetchAreas(state)
    .then((data) => {
      if (data.listAreas.length > 0) {
        let sortedAreas = data.listAreas;
        utils.sortRecords(sortedAreas, (area) => area.name);
        setAreas(sortedAreas);
        let idxArea = data.listAreas.findIndex(
          ({ id }) => id === data.selectedId
        );
        if (idxArea !== -1) {
          handleCurrentAreaId(data.selectedId);
        } else {
          handleCurrentAreaId(data.listAreas[0].id);
          idxArea = 0;
        }

        setSelectedAreaIdx(idxArea);
      }
    })
    .catch((e) => console.log("fetching areas error", e));
};

function AreaSelect({ state, handleCurrentAreaId, handleRefreshRecordList }) {
  const [areas, setAreas] = useState([]);
  const [selectedAreaIdx, setSelectedAreaIdx] = useState(0);

  // console.log("###############################  called AreaSelect");

  useEffect(() => {
    fetchAreas(state, setAreas, handleCurrentAreaId, setSelectedAreaIdx);
    return () => {
      setAreas([]);
      setSelectedAreaIdx(0);
    };
  }, [state, handleCurrentAreaId]);

  const handleArea = (e) => {
    setSelectedAreaIdx(e.target.value);
    handleCurrentAreaId(areas[e.target.value].id);
    api.setAreaForUser(state, areas[e.target.value], areas[e.target.value].id);
  };

  const SelectArea = createSelectArea(
    areas,
    selectedAreaIdx,
    handleArea,
    "Area"
  );

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="center"
      >
        <Box flexGrow={0}>{SelectArea}</Box>
        {/* <Box mt={2}>{downloadButton}</Box> */}
      </Box>
    </div>
  );
}

export default AreaSelect;
