import { UserConfirmationByState } from "../../model/publicationdata/PublicationData";
import { WorkflowStates } from "../../model/WorkflowStates";

/**
 * get the confirmation state by the desired State
 */
export const getConfStateByWorkflowState = (
  confirmationByState: UserConfirmationByState,
  workflowState: string
): string => {
  if (workflowState === WorkflowStates.RELEASE_VALIDATION.state) {
    return confirmationByState.confirmationRELEASE_VALIDATION;
  }
  return confirmationByState.confirmationRELEASE_VALIDATION_REVIEW;
};

export const setConfStateByWorkflowState = (
  confirmationByState: UserConfirmationByState,
  workflowState: string,
  value: string
): void => {
  if (workflowState === WorkflowStates.RELEASE_VALIDATION.state) {
    confirmationByState.confirmationRELEASE_VALIDATION = value;
  } else {
    confirmationByState.confirmationRELEASE_VALIDATION_REVIEW = value;
  }
};
