export const buttonColor = {
  forward: "primary",
  backward: "secondary",
  forwardTable: "primary",
  backwardTable: "secondary",
  exclusion: "primary",
  discuss: "secondary",
  upload: "primary",
  update: "secondary",
  create: "primary",
  download: "primary",
  generatePdf: "primary",
  createRec: "secondary",
  deleteRec: "secondary",
  uploadRew: "primary",
  downloadAll: "secondary",
  save: "secondary",
  refresh: "secondary",
  excludeAll: "secondary",
  primary: "primary",
  secondary: "secondary",
};
