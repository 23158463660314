import { Scaled } from "../../libs/react-pdf-highlighter";

export const emptyRectangle = (): Scaled => {
  return {
    x1: 0,
    y1: 1,
    x2: 0,
    y2: 1,
    width: 1,
    height: 1,
    pageNumber: 1,
  };
};
