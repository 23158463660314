
export class PubSubBooleanType {
    message: string;
    value: boolean;

    public constructor(name: string, value: boolean) {
        this.message = name;
        this.value = value;
    }
}

export class PubSubMessage {
    /**
     * Event to disable buttons and other stuff that should not be allowed to be used on some processing.
     */
    static DISABLE_ACTION_EVENT:string = "DisableActionEvent";
}
