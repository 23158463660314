import axios from "axios";
import { LOCATION } from "../api";

const LOCATION_EXPORT = LOCATION + "publications/exports";

export async function exportAll() {
  return axios({
    url: LOCATION_EXPORT,
    timeout: 50000,
    method: "GET",
    responseType: "blob", // important
  });
}

export async function exportSinglePaper(paperId: string) {
  return axios({
    url: LOCATION_EXPORT + "/" + paperId,
    timeout: 50000,
    method: "GET",
    responseType: "blob", // important
  });
}

const exportedExportApiFunctions = { exportAll, exportSinglePaper };
export default exportedExportApiFunctions;
