import { Button, DialogActions } from "@mui/material";

interface SaveCancelDialogActionsProps {
  handleClose: (close: boolean) => void;
  saveDisabled: boolean;
}

export function SaveCancelDialogActions({
  handleClose,
  saveDisabled,
}: SaveCancelDialogActionsProps) {
  return (
    <DialogActions>
      <Button
        disabled={saveDisabled}
        variant="contained"
        onClick={() => handleClose(false)}
        color="primary"
      >
        Save
      </Button>
      <Button
        variant="outlined"
        onClick={() => handleClose(true)}
        color="secondary"
      >
        Cancel
      </Button>
    </DialogActions>
  );
}

export default SaveCancelDialogActions;
