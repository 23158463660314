import React from "react";
import {
  Box,
  Button,
  Input,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import EditHighlightedKeywords from "./subcomponents/buttons/EditHighlightedKeywords";
import api from "../api";
import { useSnackbar } from "notistack";
import swal from "sweetalert";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SWTMenu() {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordsMatch, setPasswordsMatch] = React.useState(true);

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordsMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(newPassword === e.target.value);
  };

  let location = useLocation();

  const handleOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAdmin = () => {
    if (!location.pathname.startsWith("/administration")) {
      localStorage.setItem("lastLocation", location.pathname);
    }
    setAnchorEl(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      "oldPassword": oldPassword,
      "newPassword": newPassword,
      "confirmPassword": confirmPassword
    };

    if (passwordsMatch) {
      api.changePassword(body)
        .then((data) => {
          if (data.status === 200) {
            enqueueSnackbar("Password updated successfully");
            handleModalClose();
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
          }
        })
        .catch((e) => {
          let msg = e.response.headers.msg_header !== undefined ? e.response.headers.msg_header.toString() : e;
          swal("Error", msg, "warning", {
            closeOnClickOutside: false,
          }).then((ok) => {
            if (ok) { }
          });
        });
    }
  };

  return (
    <div>
      <Button
        disableRipple
        style={{ backgroundColor: "transparent" }}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <EditHighlightedKeywords />
        </MenuItem>

        <MenuItem component={Link} onClick={handleClose} to={"/"}>
          <Typography>SWT Workflow</Typography>
        </MenuItem>


        <MenuItem
          component={Link}
          onClick={handleCloseAdmin}
          to='/administration/project-management/creation'
        >
          <Typography>Administration</Typography>
        </MenuItem>

        <MenuItem onClick={handleOpen}>
          <Typography>Change Password</Typography>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <Logout location={location} />
        </MenuItem>
      </Menu>

      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Password
          </Typography>
          <div id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <div>
                <TextField
                  label="Old Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="password"
                  id="oldPassword"
                  value={oldPassword}
                  onChange={handleOldPasswordChange}
                  required
                />
              </div>
              <div>
                <TextField
                  label="New Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  required
                />
              </div>
              <div>
                <TextField
                  label="Confirm New Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
              </div>
              {!passwordsMatch && <p style={{ color: 'red' }}>Passwords do not match</p>}
              <div className="MuiTypography-alignCenter">
                <Button size="large" type="submit" variant="contained" disabled={!passwordsMatch}>Update Password</Button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const Logout = ({ location }) => {
  const onLogout = () => {
    if (!location.pathname.startsWith("/administration")) {
      localStorage.setItem("lastLocation", location.pathname);
    }
  };

  return (
    <form action="/logout" method="post">
      <Button
        disableRipple
        variant="text"
        style={{ backgroundColor: "transparent" }}
      >
        <Input
          type="submit"
          value="Logout"
          disableUnderline
          onClick={onLogout}
        />
      </Button>
    </form>
  );
};