// static properties
import { WorkflowStates } from "./WorkflowStates";

// showPdf,
// showPdfActions,
// showRecordActions,
// showExcludeAll
// showSourceSelectionButtons,
// showImportReview,
// showPP

export const ViewProperties = {
  IMPORTED: {
    showRecordActions: true,
    showSourceSelectionButtons: true,
    showImportReview: true,
    showPP: true,
  },
  AUTOMATED_REFERENCE_LOOKUP: {
    showRecordActions: true,
  },
  AUTO_PAPER_SELECTION_REVIEW: {
    showExclusionSelect: true,
    showRecordActions: true,
    showExcludeAll: true,
  },
  SELECTION_ASSESSMENT: {
    showExclusionSelect: true,
    showRecordActions: true,
    hasResponsibility: true,
    showDuplicate: true,
  },
  SELECTION_ASSESSMENT_REVIEW: {
    showExclusionSelect: true,
    showRecordActions: true,
    showExcludeAll: true,
    hasResponsibility: true,
  },
  INCOMING: {
    showPdfActions: true,
    showRecordActions: true,
  },
  INCOMING_NOT_FOUND: {
    showPdfActions: true,
    showRecordActions: true,
  },

  ELIG_ASSESS_AUTO_PREANNOTATION_DESIGN: {
    showRecordActions: true,
  },
  ELIGIBILITY_ASSESSMENT_MAPPING: {
    showRecordActions: true,
    showAnnotate: true,
    showRegistryValidationForm: true,
  },
  ELIGIBILITY_MANUAL_ASSESSMENT: {
    showPdf: true,
    showRecordActions: true,
  },

  ANALYSIS_PREANNOTATION: {
    showRecordActions: true,
  },
  ANALYSIS: {
    showPdf: true,
    showPdfActions: true,
    showRecordActions: true,
    showDownload: true,
    showPP: true,
    hasResponsibility: true,
    showDuplicate: true,
    showAnnotate: true,
    showValidatePaperAnnotations: true,
    showUpdate: true,
  },
  ANALYSIS_REVIEW: {
    showPdf: true,
    showPdfActions: true,
    showRecordActions: true,
    showDownload: true,
    showPP: true,
    hasResponsibility: true,
    showAnnotate: true,
    showValidatePaperAnnotations: true,
    showUpdate: true,
  },

  AUTO_EXTRACTION: {
    showRecordActions: true,
  },

  EXTRACTION_VALIDATION_VARIABLES: {},
  EXTRACTION_VALIDATION_DATA: {
    showCollapsablePdf: true,
    showRecordActions: true,
    showAnnotate: true,
    showAcronymValidationForm: true,
  },

  RELEASE_VALIDATION: {
    showValidatePaperAnnotations: true,
  },

  RELEASED: {
    showExportPublicationButton: true,
    showRecordActions: true,
    showCollapsablePdf: true,
    showAnnotate: true,
  },

  TO_BE_DISCUSSED: {
    showDeleteButton: true,
    showPdf: true,
    showRecordActions: true,
    showAnnotate: true,
  },

  LONG_TERM_TO_BE_DISCUSSED: {
    showDeleteButton: true,
    showPdf: true,
    showRecordActions: true,
    showAnnotate: true,
  },
};

// showExclusionSelect,
// showBackward,
// showForward,
// showToExclude,
// showPaperDiscussion,

export const RecordActionsProperties = {
  IMPORTED: {
    showForward: true,
  },
  AUTOMATED_REFERENCE_LOOKUP: {
    showBackward: true,
    showForward: true,
  },

  AUTO_PAPER_SELECTION_REVIEW: {
    showExclusionSelect: true,
    showToExclude: true,
    showBackward: true,
    showForward: true,
    showPaperDiscussion: true,
  },

  SELECTION_ASSESSMENT: {
    showExclusionSelect: true,
    showBackward: true,
    showForward: true,
    showPaperDiscussion: true,
  },
  SELECTION_ASSESSMENT_REVIEW: {
    showExclusionSelect: true,
    showToExclude: true,
    showBackward: true,
    showForward: true,
    showPaperDiscussion: true,
  },
  INCOMING: {
    showBackward: true,
    showForward: true,
    showPaperDiscussion: true,
  },
  INCOMING_NOT_FOUND: {
    showExclusionSelect: false,
    showBackward: true,
  },
  ELIG_ASSESS_AUTO_PREANNOTATION_DESIGN: {
    showBackward: true,
    showForward: true,
  },
  ELIGIBILITY_ASSESSMENT_MAPPING: {
    showBackward: true,
    showForward: true,
  },
  ELIGIBILITY_MANUAL_ASSESSMENT: {
    showExclusionSelect: true,
    showToExclude: true,
    showBackward: true,
    showForward: true,
    showPaperDiscussion: true,
  },

  ANALYSIS_PREANNOTATION: {
    showBackward: true,
    showForward: true,
  },
  ANALYSIS: {
    showBackward: true,
    showPaperDiscussion: true,
  },
  ANALYSIS_REVIEW: {
    showForward: true,
    showBackward: true,
    showPaperDiscussion: true,
  },

  AUTO_EXTRACTION: {
    showBackward: true,
    showForward: true,
  },

  EXTRACTION_VALIDATION_VARIABLES: {
    showBackward: true,
    showForward: true,
  },
  EXTRACTION_VALIDATION_DATA: {
    showBackward: true,
    showForward: true,
  },

  AUTO_RELEASE_VALIDATION: {
    showBackward: true,
    showForward: true,
  },
  RELEASE_VALIDATION: {
    showBackward: true,
    showForward: true,
    showPaperDiscussion: true,
  },
  RELEASE_VALIDATION_REVIEW: {
    showBackward: true,
    showForward: true,
    showPaperDiscussion: true,
  },
  RELEASED: {
    showBackward: true,
  },

  TO_BE_DISCUSSED: {
    showExclusionSelect: true,
    showToExclude: true,
    showForward: true,
    showPaperDiscussion: true,
  },
  LONG_TERM_TO_BE_DISCUSSED: {
    showExclusionSelect: true,
    showToExclude: true,
    showBackward: true,
    // showPaperDiscussion: true,
  },
};

// showDownload,
// refreshAfterUpload,
// showGeneratePdf,

export const PdfActionsProperties = {
  IMPORTED: {},
  AUTOMATED_REFERENCE_LOOKUP: {},
  SELECTION_ASSESSMENT: {},
  SELECTION_ASSESSMENT_REVIEW: {},
  INCOMING: {},
  INCOMING_NOT_FOUND: {
    showGeneratePdf: true,
  },
  ELIG_ASSESS_AUTO_PREANNOTATION_DESIGN: {},
  ELIGIBILITY_MANUAL_ASSESSMENT: {},
  ELIGIBILITY_ASSESSMENT_MAPPING: {},

  ANALYSIS_PREANNOTATION: {},
  ANALYSIS: {
    showDownload: true,
    refreshAfterUpload: true,
  },
  ANALYSIS_REVIEW: {
    showDownload: true,
  },

  AUTO_EXTRACTION: {},
  EXTRACTION_VALIDATION_VARIABLES: {},
  EXTRACTION_VALIDATION_DATA: {},
  // RELEASED: {},

  TO_BE_DISCUSSED: {},
};

export const AnnotationToolProperties = {
  ANALYSIS: {
    showForwardButton: true,
  },
  ANALYSIS_REVIEW: {
    showForwardButton: true,
  },

  TO_BE_DISCUSSED: { showForwardButton: true },
};

//fields =>  [field to show, disabled?, hide (if empty)]

export function getRecordFieldsToShow(state) {
  let fields = [];

  if (state === WorkflowStates.IMPORTED.state) {
    const disabled = false;
    fields = [
      ["title", disabled],
      ["authorsText", disabled],
      ["referenceText", disabled],
      ["year", disabled],
    ];
  } else if (
    [
      WorkflowStates.AUTO_PAPER_SELECTION_REVIEW.state,
      WorkflowStates.SELECTION_ASSESSMENT.state,
      WorkflowStates.SELECTION_ASSESSMENT_REVIEW.state,
    ].includes(state)
  ) {
    const disabled = false;

    fields = [
      ["comment", disabled],
      ["title", disabled],
      ["abstractText", disabled],
      ["authorsText", disabled],
      ["referenceText", disabled],
      ["year", disabled],
      ["pubmedId", disabled],
      ["doi", disabled],
      ["url", disabled],
    ];
  } else if (state === WorkflowStates.TO_BE_DISCUSSED.state) {
    const disabled = false;

    fields = [
      ["comment", disabled],
      ["annotatorComment", true, "hide"],
      ["reviewComment", true, "hide"],
      ["title", disabled],
      ["abstractText", disabled],
      ["authorsText", disabled],
      ["referenceText", disabled],
      ["year", disabled],
      ["pubmedId", disabled],
      ["doi", disabled],
      ["url", disabled],
    ];
  } else if (state === WorkflowStates.LONG_TERM_TO_BE_DISCUSSED.state) {
    fields = [
      ["comment", false],
      ["annotatorComment", true, "hide"],
      ["reviewComment", true, "hide"],
      ["title", true],
      ["url", false],
    ];
  } else if (
    [
      WorkflowStates.INCOMING.state,
      WorkflowStates.INCOMING_NOT_FOUND.state,
    ].includes(state)
  ) {
    const disabled = true;
    fields = [
      ["comment", false],
      ["title", disabled],
      ["filenameGenerated", disabled],
      ["authorsText", disabled],
      ["year", disabled],
      ["referenceText", disabled],
      ["pubmedId", disabled],
      ["doi", disabled],
      ["url", disabled],
      ["abstractText", disabled],
    ];
  } else if (state === WorkflowStates.ELIGIBILITY_ASSESSMENT_MAPPING.state) {
    fields = [
      ["title", false],
      ["authorsText", true],
      ["pubmedId", false],
      ["doi", false],
    ];
  } else if (state === WorkflowStates.ELIGIBILITY_MANUAL_ASSESSMENT.state) {
    fields = [
      ["comment", false],
      ["title", true],
      ["referenceText", true],
      ["authorsText", true],
    ];
  } else if (state === WorkflowStates.ANALYSIS.state) {
    fields = [
      ["comment", false],
      ["annotatorComment", false],
      ["reviewComment", true, "hide"],
    ];
  } else if (state === WorkflowStates.ANALYSIS_REVIEW.state) {
    fields = [
      ["comment", false],
      ["annotatorComment", true, "hide"],
      ["reviewComment", false],
    ];
  } else if (state === WorkflowStates.EXTRACTION_VALIDATION_DATA.state) {
    fields = [
      ["title", false],
      ["authorsText", true],
      ["pubmedId", false],
      ["doi", false],
    ];
  } else if (state === WorkflowStates.RELEASED.state) {
    const disabled = true;
    fields = [
      ["commentFinalValidation", true, "hide"],
      ["comment", false],
      ["annotatorComment", true, "hide"],
      ["reviewComment", true, "hide"],
      [("title", disabled)],
      ["filenameGenerated", disabled],
      ["authorsText", disabled],
      ["year", disabled],
      ["referenceText", disabled],
      ["pubmedId", disabled],
      ["doi", disabled],
      ["url", disabled],
      ["abstractText", disabled],
    ];
  }

  return fields;
}

const modified = {
  title: "Title",
  year: "Year",
  authorsText: "Authors",
  referenceText: "Reference",
  abstractText: "Abstract",
  pubmedId: "PMID",
  comment: "General Comment",
  annotatorComment: "Annotator Comment",
  reviewComment: "Reviewer Comment",
  commentFinalValidation: "Validation Comment",
};

export const getFieldName = (field) => {
  if (modified[field]) {
    return modified[field];
  } else return field;
};
