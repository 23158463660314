import { Collapse, ListItem, ListItemText } from "@mui/material";
import { ExpandLess } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React from "react";
import { getFieldName } from "../../../../model/ViewProperties";
import RecordField from "../../../subcomponents/record/RecordField";

export const CollapsableField = ({ currentRecord, field }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (field === "title") {
      setOpen(true);
    }
    return () => {
      setOpen(false);
    };
  }, [currentRecord, field]);

  return (
    <div>
      <ListItem disableRipple disableGutters button onClick={handleClick}>
        <ListItemText primary={getFieldName(field)} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {/* <ListItem disableRipple> */}
        <RecordField
          index={0}
          key={0 + currentRecord.id}
          value={currentRecord[field] ? currentRecord[field].toString() : ""}
          field={field}
          disabled={true}
        />
        {/* </ListItem> */}
      </Collapse>
    </div>
  );
};
