import { Box, Button, Input, InputLabel, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import swal from "sweetalert";
import api from "../../../api";
import { PdfActionsProperties } from "../../../model/ViewProperties";
import { ActionButton } from "../buttons/ActionButton";
import { buttonColor } from "../buttons/buttonProperties";
import { successSnackbarOptions } from "../notification/notifications";
// TODO: add isPaperProcessingAllowed

export function PdfActions({
  state,
  currentRecord,
  disableActions,
  updateRecord,
  handleRefreshPage,
  handleUpdatePdfViewer,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { showDownload, refreshAfterUpload, showGeneratePdf } =
    PdfActionsProperties[state];

  const disabled = state !== currentRecord.workflowState || disableActions;

  const onPdfUpload = (e) => {
    api
      .uploadPdf(currentRecord, e.target.files[0])
      .then((data) => {
        updateRecord(data);
        enqueueSnackbar("Pdf successfully uploaded", successSnackbarOptions);
        handleUpdatePdfViewer();
        // update PdfViewer
      })
      .then(() => {
        if (refreshAfterUpload) handleRefreshPage();
      })
      .catch((e) => console.log("move forward error: " + e));
  };

  const onPdfDownload = (e) => {
    api
      .downloadPaper(getFileUrl(currentRecord))
      .then((response) => {
        // ugly solution but downloading and setting the state of a paper
        // does not work better
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", getFilename(currentRecord));
        document.body.appendChild(link);
        link.click();
        // updateRecord(data);
      })
      .catch((e) => console.log("download error: " + e));
  };

  const handleGeneratePdf = () => {
    if (!currentRecord.abstractText) {
      swal(
        "No abstract",
        "To generate a pdf from abstract, abstract must not be empty",
        "warning",
        { closeOnClickOutside: false }
      );
      return;
    }
    api
      .generatePdf(currentRecord)
      .then((data) => {
        updateRecord(data);
        enqueueSnackbar("Pdf successfully generated", successSnackbarOptions);
      })
      .catch((e) => console.log("move forward error: " + e));
  };

  const getFileUrl = (currentRecord) => {
    if (currentRecord.filenameUploadedAnnotated !== null) {
      return "/paper/annotatedFile/" + currentRecord.id;
    } else return "/paper/emptyFile/" + currentRecord.id;
  };

  const getFilename = (currentRecord) => {
    if (currentRecord.filenameUploadedAnnotated !== null) {
      return currentRecord.filenameUploadedAnnotated;
    } else return currentRecord.filenameUploaded;
  };

  const generatePdfButton = showGeneratePdf
    ? (<ActionButton
      text="Generate"
      captionText="Generate Pdf from Abstract"
      disabled={disabled}
      handleClick={handleGeneratePdf}
      color={buttonColor.generatePdf}
    />)
    : null;

  const uploadButton = UploadButton(onPdfUpload, disabled);

  const downloadButton = DownloadButton(
    onPdfDownload,
    getFileUrl(currentRecord),
    disabled
  );

  return (
    <Box mb={0} display="flex" flexDirection="column" justifyContent="center">
      <Box display="flex" flexDirection="row" justifyContent="center">
        {<Box mr={2}>{uploadButton}</Box>}
        {<Box>{showDownload && downloadButton}</Box>}
        {<Box>{generatePdfButton}</Box>}
      </Box>
    </Box>
  );
}

function UploadButton(onPdfUpload, disabled) {
  return (
    <InputLabel htmlFor="upload">
      <Input
        style={{ display: "none" }}
        id="upload"
        name="upload"
        type="file"
        value="" // hack to trigger onChange, even when uploading same file
        onChange={onPdfUpload}
        disabled={disabled}
      />

      <Button
        component="span"
        variant="contained"
        disableRipple
        color={buttonColor.upload}
        size="medium"
        disabled={disabled}
      >
        {
          <Typography variant="body2" display="block" align="center">
            Upload
          </Typography>
        }
      </Button>
      <Typography
        variant="caption"
        display="block"
        // align="center"
        color="textSecondary"
      >
        {"Upload paper"}
      </Typography>
    </InputLabel>
  );
}

function DownloadButton(onPdfDownload, href, disabled) {
  return (
    <InputLabel htmlFor="download">
      <Box
        display="flex"
        flexDirection="column"
        // justifyContent="center"
        alignContent="flex-start"
        alignItems="center"
      >
        <Button
          variant="contained"
          disableRipple
          color={buttonColor.download}
          size="medium"
          onClick={onPdfDownload}
          disabled={disabled}
        >
          {
            <Typography variant="body2" display="block" align="center">
              Download
            </Typography>
          }
        </Button>
        <Typography
          variant="caption"
          // display="block"
          // align="center"
          color="textSecondary"
        >
          {"Download paper"}
        </Typography>
      </Box>
    </InputLabel>
  );
}
