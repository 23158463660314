import {
  AnnotationBaseContentType,
  byType,
} from "../../model/annotation/AnnotationBaseContentType";
import {
  AnnotationOutcomeContentType,
  byOutcomeContentType,
} from "../../model/annotation/AnnotationOutcomeContentType";

import { AnnotationWriterType } from "../../model/annotation/AnnotationWriterType";
import { OutcomeAnnotation } from "../../model/annotation/outcome/OutcomeAnnotation";
import { RangeType } from "../../model/annotation/outcome/RangeType";
import { TimeType } from "../../model/annotation/outcome/TimeType";
import { WorkflowStates } from "../../model/WorkflowStates";

export function createAnnotationText(
  isCustom: boolean,
  variable: string,
  groupId: string,
  count: string,
  text: string
): string[] {
  if (isCustom) {
    return [text, ""];
  }

  const type: any = byType(variable);
  let annotationText = type.typeName;

  if (
    type === AnnotationBaseContentType.GROUP_N ||
    type === AnnotationBaseContentType.GROUP_NAME
  ) {
    if (groupId != null && groupId !== "") {
      annotationText += "_" + groupId;
    }
  }

  if (count !== null && count !== "" && count !== "0") {
    annotationText += "_" + count;
  }

  return [annotationText, type.chapter];
}

export function createOutcomeAnnotationText(
  outcomeAnnotation: OutcomeAnnotation
): string[] {
  if (outcomeAnnotation.isCustom || outcomeAnnotation.variable === "") {
    return [outcomeAnnotation.variable, ""];
  }

  let chapter: string = "4";
  let annotationText: string = "";
  let outcomeContentType = byOutcomeContentType(outcomeAnnotation.variable);

  let section = "";
  if (outcomeAnnotation.section1 === "") {
    section = "1";
  } else if (
    outcomeAnnotation.section1 !== "" &&
    outcomeAnnotation.section2 !== "" &&
    outcomeAnnotation.section3 !== ""
  ) {
    section =
      outcomeAnnotation.section1 +
      "." +
      outcomeAnnotation.section2 +
      "." +
      outcomeAnnotation.section3;
  } else if (
    outcomeAnnotation.section1 !== "" &&
    outcomeAnnotation.section2 !== ""
  ) {
    section = outcomeAnnotation.section1 + "." + outcomeAnnotation.section2;
  } else if (outcomeAnnotation.section1 !== "") {
    section = outcomeAnnotation.section1;
  }

  let outcomeGrade;
  if (
    outcomeAnnotation.resultType === AnnotationOutcomeContentType.PRIMARY.type
  ) {
    outcomeGrade = AnnotationOutcomeContentType.PRIMARY.annotationText;
    chapter += ".1";
  } else if (
    outcomeAnnotation.resultType === AnnotationOutcomeContentType.SECONDARY.type
  ) {
    outcomeGrade = AnnotationOutcomeContentType.SECONDARY.annotationText;
    chapter += ".2";
  } else if (
    outcomeAnnotation.resultType === AnnotationOutcomeContentType.TERTIARY.type
  ) {
    outcomeGrade = AnnotationOutcomeContentType.TERTIARY.annotationText;
    chapter += ".3";
  } else if (
    outcomeAnnotation.resultType === AnnotationOutcomeContentType.CONTROL.type
  ) {
    chapter = "6";
    outcomeGrade = AnnotationOutcomeContentType.CONTROL.annotationText;
  } else if (
    outcomeAnnotation.resultType === AnnotationOutcomeContentType.ADVERSE.type
  ) {
    chapter = "7";
    outcomeGrade = AnnotationOutcomeContentType.ADVERSE.annotationText;
  }

  chapter += "." + section;

  if (
    outcomeAnnotation.variable ===
    AnnotationOutcomeContentType.OUTCOME_NAME.type
  ) {
    if (
      outcomeAnnotation.resultType === AnnotationOutcomeContentType.CONTROL.type
    ) {
      annotationText = outcomeGrade + section;
    } else if (
      outcomeAnnotation.resultType === AnnotationOutcomeContentType.ADVERSE.type
    ) {
      annotationText = outcomeGrade + section;
    } else {
      annotationText =
        AnnotationOutcomeContentType.OUTCOME_NAME.annotationText +
        "." +
        outcomeGrade +
        section;
    }
  } else if (outcomeContentType.hasRange) {
    annotationText = outcomeGrade + section + ".";
    if (outcomeAnnotation.rangeType === RangeType.UP) {
      annotationText += "up";
    } else if (outcomeAnnotation.rangeType === RangeType.LOW) {
      annotationText += "low";
    }
    annotationText += outcomeContentType.annotationText;
  } else {
    annotationText =
      outcomeGrade + section + "." + outcomeContentType.annotationText;
  }

  annotationText = annotationText.replaceAll(
    "$",
    outcomeAnnotation.resultNumber
  );

  if (
    isOutcomeGroupResult(outcomeContentType.type) ||
    isTimeDependentResult(outcomeAnnotation)
  ) {
    if (outcomeAnnotation.resultNumber !== "") {
      annotationText += outcomeAnnotation.resultNumber;
    }
  }

  if (isTimeDependentResult(outcomeAnnotation)) {
    if (outcomeAnnotation.isAbsoluteTimestamp) {
      annotationText += ".abs";
    }
    if (outcomeAnnotation.timeCount !== "") {
      annotationText += ".T" + outcomeAnnotation.timeCount;
    } else if (outcomeAnnotation.timeType === TimeType.END) {
      annotationText += ".Tend";
    } else if (outcomeAnnotation.timeType === TimeType.START) {
      annotationText += ".T0";
    }
  }

  if (isOutcomeGroupResult(outcomeContentType.type)) {
    if (outcomeAnnotation.groupId1 !== "") {
      annotationText = annotationText + "_" + outcomeAnnotation.groupId1;
    }
    if (outcomeAnnotation.groupId2 !== "") {
      annotationText += outcomeAnnotation.groupId2;
    }
    if (outcomeAnnotation.groupIdCustomAddon !== "") {
      annotationText += outcomeAnnotation.groupIdCustomAddon;
    }
  }

  if (
    outcomeContentType.couldBeMultipleEntities &&
    outcomeAnnotation.count !== ""
  ) {
    annotationText += "_" + outcomeAnnotation.count;
  }

  return [annotationText, chapter];
}

export function getAnnotationWriterTypeByState(workflowState: string) {
  if (workflowState === WorkflowStates.ANALYSIS_REVIEW.state) {
    return AnnotationWriterType.REVIEWER;
  }
  return AnnotationWriterType.ANNOTATOR;
}

export function isTimeDependentResult(
  outcomeAnnotation: OutcomeAnnotation
): boolean {
  const outcomeType = outcomeAnnotation.variable;
  return (
    outcomeType === AnnotationOutcomeContentType.RESULT.type ||
    outcomeType === AnnotationOutcomeContentType.SD.type ||
    outcomeType === AnnotationOutcomeContentType.IQR.type ||
    outcomeType === AnnotationOutcomeContentType.CI95.type ||
    outcomeType === AnnotationOutcomeContentType.PVALUE.type ||
    (outcomeType === AnnotationOutcomeContentType.TIME.type &&
      outcomeAnnotation.isAbsoluteTimestamp)
  );
}

export function isOutcomeGroupResult(outcomeType: string): boolean {
  return (
    outcomeType === AnnotationOutcomeContentType.RESULT.type ||
    outcomeType === AnnotationOutcomeContentType.SD.type ||
    outcomeType === AnnotationOutcomeContentType.IQR.type ||
    outcomeType === AnnotationOutcomeContentType.CI95.type ||
    outcomeType === AnnotationOutcomeContentType.PVALUE.type
  );
}

export function isError(outcomeType: string): boolean {
  return (
    outcomeType === AnnotationOutcomeContentType.SD.type ||
    outcomeType === AnnotationOutcomeContentType.IQR.type ||
    outcomeType === AnnotationOutcomeContentType.CI95.type ||
    outcomeType === AnnotationOutcomeContentType.PVALUE.type
  );
}
