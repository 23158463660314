import { Input } from "@mui/material";
// import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";
// import { WorkflowStates } from "../../model/WorkflowStates";
import utils from "../../../utils/utils";

const useStyles = makeStyles({
  table: {
    width: "100%",
    maxHeight: 600,
  },
  table_cell: {
    // background: "#eaf2f8",
    // paddingTop: 5
  },

  // tableRow: {
  //   "&$hover:hover": {
  //     backgroundColor: "blue"
  //   }
  // },
  // tableCell: {
  //   "$hover:hover &": {
  //     color: "pink"
  //   }
  // },
});

export function RecordTableList({
  recordList,
  handleCurrentRecordIdx,
  resetIndex,
  disableActions,
}) {
  const classes = useStyles();
  const firstRecordId = React.useRef(recordList[0].id);
  const [selectedId, setSelectedId] = useState(recordList[0].id);
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    setSelectedId(firstRecordId.current);
    setTextValue("");
  }, [resetIndex]);

  useEffect(() => {
    firstRecordId.current = recordList[0].id;
  }, [recordList]);

  const handleClick = (e, row) => {
    if (disableActions && row.id !== selectedId) {
      swal({
        title: "Unsaved Changes",
        text: "Do you want to leave without saving?.",
        icon: "warning",
        buttons: ["Cancel", "Yes, leave"],
      }).then((ok) => {
        if (ok) {
          setSelectedId(row.id);
          handleCurrentRecordIdx(row.id);
        } else {
          return false;
        }
      });
    } else {
      setSelectedId(row.id);
      handleCurrentRecordIdx(row.id);
    }
  };

  const handleChange = (e) => {
    setTextValue(e.target.value);
  };

  const filterItems = (item) => {
    return textValue === ""
      ? item
      : item.name.toLowerCase().match(textValue.toLowerCase());
    // item.name.toLowerCase().startsWith(textValue.toLowerCase());
  };

  if (recordList) {
    var tableItems = createTableItems(recordList);
  }

  return (
    <TableContainer className={classes.table}>
      {/* {"Select Paper"} */}
      <Table
        stickyHeader
        className={classes.table}
        size="small"
        aria-label="a dense table"
        // padding="none"
      >
        <TableHead>
          <TableRow>
            <TableCell padding="none">
              <Input
                placeholder="Search Paper:"
                type="text"
                value={textValue}
                onChange={handleChange}
              ></Input>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableItems && tableItems}</TableBody>
      </Table>
    </TableContainer>
  );

  function createTableItems(recordList) {
    let tableItems;

    if (recordList.length > 0) {
      var recordNameList = recordList.map((item, idx) => {
        return {
          id: item.id,
          // name: utils.getPaperName(item, currentState.current),
          name: utils.getPaperName(item),
        };
      });
    }

    if (recordNameList && recordList.length > 0) {
      tableItems = recordNameList.filter(filterItems).map((row, idx) => {
        return (
          <TableRow
            selected={row.id === selectedId}
            key={idx}
            onClick={(e) => handleClick(e, row)}
            disabled={true}
          >
            <TableCell
              // component="th"
              scope="row"
              disabled={true}
              // padding="none"
              // className={classes.table_cell}
            >
              {row.name}
            </TableCell>
          </TableRow>
        );
      });
    } else {
      tableItems = [];
      tableItems.push(
        <TableRow key={0}>
          <TableCell component="th" scope="row">
            No table items
          </TableCell>
        </TableRow>
      );
    }

    return tableItems;
  }
}

export default RecordTableList;
