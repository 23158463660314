/**
 * Application for PDF annotation
 */
import React, { Component } from "react";

import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  T_PDFJS_Viewer,
  UserConfirmationStateHighlight,
  T_EventBus,
  Viewport,
} from "../../libs/react-pdf-highlighter";

import type {
  IHighlight,
  NewHighlight,
  EditHighlightOperation,
} from "../../libs/react-pdf-highlighter";

import { Spinner } from "./Spinner";
import { Sidebar } from "./Sidebar";

import "./style/PdfAnnotationApp.css";
import { alertConfirm } from "../subcomponents/notification/notificationDialogs";
import annotationApi from "../../api/annotationApi";
import { Annotation } from "../../model/annotation/Annotation";
import { convertPdfHighlightingToRsAnnotation } from "../../utils/annotation/annotationApiConverter";
import { getAnnotationWriterTypeByState } from "../../utils/annotation/utils";
import { calculateRandomId } from "../../utils/utils";
import {
  isHighlightVisibleInSidebar,
  getDefaultFilterByState,
} from "./utils/filterUtils";
import {
  updateIdMathingHighlight,
  findAvailableIndex,
} from "./utils/highlightUtils";
import {
  loadAllAnnotations,
  acceptAnnotation,
  markIcd10orRegistryAnnotationAsDeleted,
  restoreDisabledAnnotations,
  removeSameAnnotationExceptGiven,
  createAnnotation,
} from "../../utils/annotation/apiUtils";
import { WorkflowStates } from "../../model/WorkflowStates";
import { isAddingAnnotationsAllowed } from "../../model/workflow/annotation/visibility";
import {
  pdfPageCoordinates,
  UseExtractedAnnotationsInAnnotationUi,
} from "../../utils/annotation/uiUtils";
import { OutcomeAnnotation } from "../../model/annotation/outcome/OutcomeAnnotation";
import { ToolsMenuCallType } from "../../model/annotation/view/ToolsMenuCallType";
import { EmptyAnnotationDialog } from "./dialogs/EmptyAnnotationDialog";
import {
  byAnnotationType,
  AnnotationType,
} from "../../model/annotation/AnnotationType";

interface State {
  url: string;
  highlights: Array<IHighlight>;
  filterState: string;
  lastOutcomeAnnotation: OutcomeAnnotation | null;
  emptyAnnotationDialgoOpen: boolean;
}

const HighlightPopup = ({
  comment,
}: {
  comment: { text: string; count: string };
}) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.count} {comment.text}
    </div>
  ) : null;

class PdfAnnotationApp extends Component<
  {
    workflowState: any;
    currentRecord: any;
    eventBusInitialized(eventBus: T_EventBus): void;
  },
  State
> {
  state = {
    url: "",
    highlights: new Array<IHighlight>(),
    filterState: getDefaultFilterByState(this.props.workflowState),
    lastOutcomeAnnotation: null,
    emptyAnnotationDialgoOpen: false,
  };
  // Needed for data conversion from frontend to backend
  pdfViewer: T_PDFJS_Viewer | null;
  scale: number;

  constructor(props: any) {
    super(props);
    this.pdfViewer = null;
    this.scale = 100;
  }

  toolBarCall(callType: ToolsMenuCallType) {
    if (callType === ToolsMenuCallType.EMPTY_ANNOTATION) {
      this.setState({ emptyAnnotationDialgoOpen: true });
    }
  }

  getPdfUrl = () => {
    let filename;
    if (
      this.props.workflowState === WorkflowStates.ANALYSIS.state ||
      this.props.workflowState === WorkflowStates.ANALYSIS_REVIEW.state
    ) {
      filename = "emptyFileView/";
    } else {
      filename = "extractedFileView/";
    }
    const url =
      "/paper/" +
      filename +
      this.props.currentRecord.id +
      "#pagemode=none&view=FitH";

    this.setState({ url: url });
  };

  deleteAnnotationsWithConfirmationDialog = () => {
    let msg = "Do you really want to delete all annotations?";
    alertConfirm("Delete all annotations", msg, this.deleteAnnotations);
  };

  deleteAnnotations = () => {
    annotationApi
      .deleteAllAnnotationsForPaper(this.props.currentRecord.id)
      .then((status) => {
        this.setState({
          highlights: new Array<IHighlight>(),
        });
      });
  };

  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = (highlight?: IHighlight) => {
    if (highlight != null && highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  componentDidMount() {
    window.addEventListener("hashchange", () => {}, false);

    this.getPdfUrl();
    // TBD: load annotations
  }

  loadAnnotations() {
    const useExtractedAnnotations: boolean =
      UseExtractedAnnotationsInAnnotationUi(this.props.workflowState);
    loadAllAnnotations(
      this.props.currentRecord.id,
      this.pdfViewer,
      useExtractedAnnotations
    ).then((highlights: any) => {
      if (highlights != null) {
        this.setState({
          highlights: highlights,
        });
      }
    });
  }

  getHighlightById(randomId: string) {
    const { highlights } = this.state;

    return highlights.find((highlight) => highlight.randomId === randomId);
  }

  addHighlight(highlight: NewHighlight) {
    const { highlights } = this.state;

    const index = findAvailableIndex(highlights);
    const annotationWriterType = getAnnotationWriterTypeByState(
      this.props.workflowState
    );

    createAnnotation(
      highlight,
      this.props.currentRecord.id,
      annotationWriterType,
      index
    ).then((createdHighlight: any) => {
      if (createdHighlight != null) {
        this.setState({
          highlights: [
            ...highlights,
            {
              ...createdHighlight,
              index: index,
              annotationWriterType: annotationWriterType,
            },
          ],
        });
      }
    });
  }

  deleteSelectedAnnotation = (highlight: IHighlight) => {
    const { highlights } = this.state;

    const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
      highlight,
      highlight.index,
      highlight.annotationWriterType
    );
    annotationApi
      .deleteAnnotation(
        rsAnnotation,
        this.props.currentRecord.id,
        byAnnotationType(highlight.annotationType).url
      )
      .then((status) => {
        highlights.splice(highlights.indexOf(highlight), 1);
        this.setState({
          highlights: [...highlights],
        });
      });
  };

  disableSelectedAnnotation = (highlight: IHighlight) => {
    const { highlights } = this.state;

    markIcd10orRegistryAnnotationAsDeleted(
      highlight as UserConfirmationStateHighlight,
      this.props.currentRecord.id
    ).then((status) => {
      this.setState({
        highlights: [...highlights],
      });
    });
  };

  acceptSelectedAnnotation = (highlight: IHighlight) => {
    const { highlights } = this.state;
    const localViewport = this.pdfViewer!.getPageView(
      highlight.position.pageNumber - 1
    ).viewport as Viewport;
    highlight.position.pdfPageCoordinates = pdfPageCoordinates(
      localViewport,
      highlight.position.pageNumber
    );
    acceptAnnotation(highlight, this.props.currentRecord.id).then((status) => {
      this.setState({
        highlights: [...highlights],
      });
    });
  };

  duplicateOrEditAnnotation = (edithighlight: EditHighlightOperation) => {
    const { highlights } = this.state;
    const localViewport = this.pdfViewer!.getPageView(
      edithighlight.highlightBase.position.pageNumber - 1
    ).viewport as Viewport;
    edithighlight.highlightBase.position.pdfPageCoordinates =
      pdfPageCoordinates(
        localViewport,
        edithighlight.highlightBase.position.pageNumber
      );
    if (edithighlight.duplicate) {
      console.log("Duplicating highlight", edithighlight.highlightBase);
      const index = findAvailableIndex(highlights);
      const annotationWriterType = getAnnotationWriterTypeByState(
        this.props.workflowState
      );
      const highlightDuplicate: NewHighlight = {
        position: edithighlight.highlightBase.position,
        comment: edithighlight.comment,
        content: edithighlight.highlightBase.content,
        chapter: edithighlight.chapter,
        randomId: calculateRandomId(20),
        duplicateOfWithRandomId: edithighlight.highlightBase.randomId,
        annotationType: edithighlight.highlightBase.annotationType,
      };
      const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
        highlightDuplicate,
        index,
        annotationWriterType
      );
      annotationApi.createNewAnnotation(
        rsAnnotation,
        this.props.currentRecord.id,
        byAnnotationType(highlightDuplicate.annotationType).url
      );
      this.setState({
        highlights: [
          ...highlights,
          {
            ...highlightDuplicate,
            index: index,
            annotationWriterType: annotationWriterType,
          },
        ],
      });
    } else {
      const annotationWriterType = getAnnotationWriterTypeByState(
        this.props.workflowState
      );
      const highlightToUpdate: NewHighlight = edithighlight.highlightBase;
      highlightToUpdate.chapter = edithighlight.chapter;
      highlightToUpdate.comment = edithighlight.comment;
      const rsAnnotation: Annotation = convertPdfHighlightingToRsAnnotation(
        highlightToUpdate,
        edithighlight.highlightBase.index,
        annotationWriterType
      );
      annotationApi
        .updateAnnotation(
          rsAnnotation,
          this.props.currentRecord.id,
          byAnnotationType(highlightToUpdate.annotationType).url
        )
        .then((status) => {
          this.setState({
            highlights: [...highlights],
          });
        });
    }
  };

  updateHighlight(highlightId: string, position: Object, content: Object) {
    this.setState({
      highlights: this.state.highlights.map((h) => {
        return updateIdMathingHighlight(h, highlightId, position, content);
      }),
    });
  }

  removeSameButThis = (hightlight: IHighlight) => {
    const { highlights } = this.state;
    removeSameAnnotationExceptGiven(
      hightlight,
      highlights,
      this.props.currentRecord.id
    ).then((status) => {
      this.setState({
        highlights: [...highlights],
      });
    });
  };

  restoreDisabledAnnotations = () => {
    const { highlights } = this.state;
    // if (this.pdfViewer !== null)
    //   if (this.scale === 100) {
    //     this.scale = 80;
    //     this.pdfViewer.increaseScale(1);
    //     // this.pdfViewer.pagesRotation = 90;
    //   } else {
    //     this.scale = 100;
    //     // this.pdfViewer.pagesRotation = 0;
    //     this.pdfViewer.decreaseScale(1);
    //   }
    restoreDisabledAnnotations(highlights, this.props.currentRecord.id).then(
      (status) => {
        this.setState({
          highlights: [...highlights],
        });
      }
    );
  };

  render() {
    const { url, highlights } = this.state;
    const filteredHighlights = highlights.filter((highlight) => {
      return isHighlightVisibleInSidebar(highlight, this.state.filterState);
    });
    const filteredHighlightsForPdfHighlighter = filteredHighlights.filter(
      (highlight) => {
        return highlight.annotationType !== AnnotationType.EMPTY.type;
      }
    );
    return (
      <div
        className="PdfAnnotationApp"
        style={{ display: "flex", height: "100vh" }}
      >
        <Sidebar
          highlights={highlights}
          filteredHighlights={filteredHighlights}
          deleteAnnotations={this.deleteAnnotationsWithConfirmationDialog}
          disableAnnotation={this.disableSelectedAnnotation}
          scrollToByHash={this.scrollToHighlightFromHash}
          removeAnnotation={this.deleteSelectedAnnotation}
          removeSameButThis={this.removeSameButThis}
          restoreDisabledAnnotations={this.restoreDisabledAnnotations}
          acceptAnnotationByAnnotator={this.acceptSelectedAnnotation}
          duplicateAnnotation={this.duplicateOrEditAnnotation}
          currentRecord={this.props.currentRecord}
          handleFilterChanged={(state: string) => {
            this.setState({ filterState: state });
          }}
          initialFilterState={this.state.filterState}
          workflowState={this.props.workflowState}
        />
        <div
          id="pdfContainer"
          className="pdf-content pdfViewer"
          style={{
            height: "100vh",
            width: "75vw",
            position: "relative",
          }}
        >
          <PdfLoader url={url} beforeLoad={<Spinner />}>
            {(pdfDocument) => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                enableAreaSelection={(event) => event.altKey}
                onScrollChange={() => {}}
                pagesRotation={0}
                onViewerCreated={(
                  viewer: T_PDFJS_Viewer,
                  eventBus: T_EventBus
                ) => {
                  this.pdfViewer = viewer;
                  this.loadAnnotations();
                  this.props.eventBusInitialized(eventBus);
                }}
                // pdfScaleValue="page-width"
                scrollRef={(scrollTo) => {
                  this.scrollViewerTo = scrollTo;
                  this.scrollToHighlightFromHash();
                }}
                onSelectionFinished={(
                  position,
                  content,
                  hideTipAndSelection,
                  transformSelection
                ) => {
                  if (isAddingAnnotationsAllowed(this.props.workflowState)) {
                    return (
                      <Tip
                        onOpen={transformSelection}
                        onConfirm={(
                          comment,
                          chapter,
                          annotationType,
                          outcomeAnnotation
                        ) => {
                          this.addHighlight({
                            content,
                            position,
                            comment,
                            chapter,
                            randomId: calculateRandomId(20),
                            duplicateOfWithRandomId: "",
                            annotationType,
                          });

                          hideTipAndSelection();
                          this.setState({
                            lastOutcomeAnnotation: outcomeAnnotation,
                          });
                        }}
                        filterState={this.state.filterState}
                        lastOutcomeAnnotation={this.state.lastOutcomeAnnotation}
                      />
                    );
                  } else {
                    return null;
                  }
                }}
                highlightTransform={(
                  highlight,
                  index,
                  setTip,
                  hideTip,
                  viewportToScaled,
                  screenshot,
                  isScrolledTo
                ) => {
                  const component = (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                      annotationWriterType={highlight.annotationWriterType}
                      isDuplicate={
                        highlight.duplicateOfWithRandomId !== null &&
                        highlight.duplicateOfWithRandomId !== ""
                      }
                    />
                  );

                  return (
                    <Popup
                      popupContent={<HighlightPopup {...highlight} />}
                      onMouseOver={(popupContent) =>
                        setTip(highlight, (highlight) => popupContent)
                      }
                      onMouseOut={hideTip}
                      key={index}
                      children={component}
                    />
                  );
                }}
                highlights={filteredHighlightsForPdfHighlighter}
              />
            )}
          </PdfLoader>
          {this.state.emptyAnnotationDialgoOpen ? (
            <EmptyAnnotationDialog
              addHighlight={(highlight) => this.addHighlight(highlight)}
              dialogClosed={() =>
                this.setState({ emptyAnnotationDialgoOpen: false })
              }
              open={true}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default PdfAnnotationApp;
