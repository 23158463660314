import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";
import api from "../../../api";
import { UiPropertiesContext } from "../../Context";
import { ActionButton } from "../buttons/ActionButton";

var deepEqual = require("deep-equal");

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

export const UiPropertiesTable = () => {
  const { uiProperties, setUiProperties } =
    React.useContext(UiPropertiesContext);

  const { id, name, editable, ...keywordsProperties } = uiProperties;
  const [editedProperties, setEditedProperties] = React.useState(() =>
    jsonCopy(keywordsProperties)
  );

  React.useEffect(() => {
    const { id, name, editable, ...keywordsProperties } = uiProperties;
    setEditedProperties(jsonCopy(keywordsProperties));
  }, [uiProperties]);

  const handleDelete = (name, keyword) => {
    let copy = editedProperties;
    copy[name].keywords = remove(editedProperties[name].keywords, keyword);

    setEditedProperties((editedProperties) => {
      return { ...editedProperties, ...copy };
    });
  };

  const handleAdd = (name, newKeyword) => {
    let copy = editedProperties;
    copy[name].keywords = editedProperties[name].keywords.concat(newKeyword);

    setEditedProperties((editedProperties) => {
      return { ...editedProperties, ...copy };
    });
  };

  const handleEditColor = (name, newColor) => {
    let copy = editedProperties;
    copy[name].color = newColor;

    setEditedProperties((editedProperties) => {
      return { ...editedProperties, ...copy };
    });
  };

  const handleUpdateUiProperties = (newProperties) => {
    setUiProperties(newProperties);
    api.saveUiProperties(newProperties);
  };

  const onSaveClick = () => {
    let newUiProperties = {
      id,
      name,
      editable,
      ...editedProperties,
    };
    handleUpdateUiProperties(newUiProperties);
  };

  const onCancelClick = () => {
    setEditedProperties(jsonCopy(keywordsProperties));
  };

  const onRestoreClick = () => {
    api
      .getUiProperties("default")
      .then((response) => {
        if (response.status === 200) {
          const { id, name, editable, ...keywordsProperties } = response.data;
          setEditedProperties(keywordsProperties);
        }
      })
      .catch((e) => console.log("getUiProperties restoring default error", e));
  };

  let propertiesEdited = !deepEqual(keywordsProperties, editedProperties, true);

  return (
    <React.Fragment>
      <TableContainer style={{ maxHeight: 600 }}>
        <Table size="small" stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{ borderBottom: "none" }} />

              <TableCell style={{ borderBottom: "none" }}>
                <Box ml={7}>Keywords</Box>
              </TableCell>
              <TableCell style={{ borderBottom: "none" }}>
                <Box ml={-3}>Add keyword</Box>
              </TableCell>
              <TableCell style={{ borderBottom: "none" }}>
                <Box ml={-3}>Change Color</Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(editedProperties).map((key, idx) => (
              <Row
                key={key}
                row={editedProperties[key]}
                handleDelete={handleDelete}
                handleAdd={handleAdd}
                handleEditColor={handleEditColor}
                uiProperties={uiProperties}
                editedProperties={editedProperties}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box flexGrow={1} display="flex">
          {restoreDefault(propertiesEdited, onRestoreClick)}
        </Box>
        <Box mr={1}>{cancelButton(!propertiesEdited, onCancelClick)}</Box>
        <Box>{saveButton(!propertiesEdited, onSaveClick)}</Box>
      </Box>
    </React.Fragment>
  );
};

const Row = ({
  row,
  handleDelete,
  handleAdd,
  handleEditColor,
  uiProperties,
  editedProperties,
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const onClickDelete = (row, keyword) => {
    handleDelete(row.name, keyword);
  };

  const nameColor = deepEqual(
    editedProperties[row.name],
    uiProperties[row.name],
    true
  )
    ? "black"
    : "DarkOrange";

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Box style={{ color: nameColor }} ml={7}>
            {keywordNames[row.name]}
          </Box>
        </TableCell>
        <TableCell>
          <AddIconButton row={row} handleAdd={handleAdd} />
        </TableCell>
        <TableCell>
          <EditColorButton row={row} handleEditColor={handleEditColor} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "none" }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              <TableBody>
                {keywordRows(row, onClickDelete, uiProperties)}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const keywordRows = (row, onClickDelete, uiProperties) => {
  const handleDelete = (k) => {
    onClickDelete(row, k);
  };

  let text = "No keywords defined";
  if (!row.keywordsEditable) {
    text = "Design variable keywords are not editable";
  }

  if (row.keywords === null || row.keywords.length === 0) {
    return (
      <TableRow key={row.name}>
        <TableCell
          style={{ borderBottom: "none" }}
          component="th"
          scope="row"
          align="center"
        >
          <Typography variant="body1">{text}</Typography>
        </TableCell>
      </TableRow>
    );
  }
  return row.keywords.map((k) => {
    const color = uiProperties[row.name].keywords.includes(k)
      ? "secondary"
      : "primary";
    return (
      <TableRow key={k}>
        <TableCell style={{ borderBottom: "none" }} align="center">
          <Box ml={-22}>
            <Chip
              size="small"
              label={k}
              color={color}
              onDelete={() => handleDelete(k)}
            />
          </Box>
        </TableCell>
      </TableRow>
    );
  });
};

function jsonCopy(src) {
  return JSON.parse(JSON.stringify(src));
}

function remove(arr, value) {
  return arr.filter(function (ele) {
    return ele !== value;
  });
}

const saveButton = (disable, onSaveClick) => {
  (<ActionButton
    text="Save"
    captionText={null}
    disabled={disable}
    handleClick={onSaveClick}
    color="primary"
    size = "small"
  />);
};

const restoreDefault = (disable, onRestoreClick) => {
  (<ActionButton
    text="Restore Default"
    captionText={null}
    disabled={disable}
    handleClick={onRestoreClick}
    color="secondary"
    size = "small"
  />);
};

const cancelButton = (disable, onCancelClick) => {
  (<ActionButton
    text="Cancel"
    captionText={null}
    disabled={disable}
    handleClick={onCancelClick}
    color="secondary"
    size = "small"
  />);
};

const EditColorButton = ({ row, handleEditColor }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onAddClick = () => {
    setOpen(true);
  };

  const handleSaveClick = (name, currentValue) => {
    setOpen(false);
    handleEditColor(name, currentValue);
  };

  let color = row.color;

  return (
    <React.Fragment>
      <IconButton
        size="small"
        disableRipple
        onClick={onAddClick}
        style={{
          color: "black",
          background: color,
        }}
      >
        <EditIcon />
      </IconButton>

      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="body1">
            Modify color for {keywordNames[row.name]}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AddNewTextField
            row={row}
            handleSave={handleSaveClick}
            placeholder={color}
            typeField="color"
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const AddIconButton = ({ row, handleAdd }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onAddClick = () => {
    setOpen(true);
  };

  const handleSaveClick = (name, currentValue) => {
    setOpen(false);
    handleAdd(name, currentValue);
  };

  const typeField = row.name.toLowerCase().includes("regex")
    ? "regex"
    : "keyword";

  return (
    <React.Fragment>
      <IconButton
        size="small"
        disableRipple
        onClick={onAddClick}
        color="primary"
        disabled={row.name === "designVariableKeywords"}
      >
        <AddCircleIcon />
      </IconButton>

      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="body1">
            Add new keyword for {keywordNames[row.name]}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AddNewTextField
            row={row}
            handleSave={handleSaveClick}
            placeholder="add new keyword"
            typeField={typeField}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const AddNewTextField = ({ row, handleSave, placeholder, typeField }) => {
  const { uiProperties } = React.useContext(UiPropertiesContext);
  const { id, name, editable, ...keywordsProperties } = uiProperties;
  const [currentValue, setCurrentValue] = React.useState("");

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
  };

  const onSaveClick = () => {
    handleSave(row.name, currentValue);
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      onSaveClick();
    }
  };

  const getError = () => {
    if (typeField === "keyword") {
      return validNewKeyword(currentValue, keywordsProperties);
    } else if (typeField === "color") {
      return validateColor(currentValue);
    } else if (typeField === "regex") {
      return validateRegex(currentValue);
    }
    return "";
  };

  return (
    <React.Fragment>
      <TextField
        onKeyUp={onKeyUp}
        autoFocus
        value={currentValue}
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
        error={getError() !== ""}
        helperText={getError()}
      />
      <IconButton
        size="small"
        disableRipple
        onClick={onSaveClick}
        color="primary"
        disabled={currentValue === "" || getError() !== ""}
      >
        <SaveIcon />
      </IconButton>
    </React.Fragment>
  );
};

const validNewKeyword = (newKeyword, keywordsProperties) => {
  for (const kwn of Object.keys(keywordNames)) {
    if (keywordsProperties[kwn].keywords.includes(newKeyword)) {
      return "Keyword already exists in " + keywordNames[kwn];
    }
  }

  return "";
};

const validateColor = (color) => {
  if (!CSS.supports("color", color)) {
    return "Not valid color";
  }

  return "";
};

const validateRegex = (regex) => {
  try {
    new RegExp(regex);
  } catch (e) {
    return "Invalid regular expression.";
  }

  return "";
};

const keywordNames = {
  includedKeywords: "Included",
  excludedKeywords: "Excluded",
  neutralKeywords: "Neutral",
  includedKeywordsRegex: "Included Regex",
  excludedKeywordsRegex: "Excluded Regex",
  neutralKeywordsRegex: "Neutral Regex",
  sectionKeywords: "Section",
  designVariableKeywords: "Design Variable",
};
